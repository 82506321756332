import React from "react";
import { useNavigate } from "react-router-dom";

import Arrow from "foundation/svgComponents/Arrow";

import { Recipe } from "cooking/models/types";
import { RecipeWrapper } from "cooking/models/cooking.model.helpers";

type RecipeRowProps = {
    recipe: Recipe;
    onClick?: (recipe: Recipe) => void;
    rowIcon?: React.ReactNode;
};

const RecipeRow: React.FC<RecipeRowProps> = ({
    recipe,
    onClick,
    rowIcon = <Arrow direction="right" />,
}) => {
    const navigate = useNavigate();
    const recipeWrapper = new RecipeWrapper(recipe);

    const rowContent = (
        <div className="flex items-center w-full">
            <div className="flex flex-col text-theme-text w-full">
                <div className="flex my-1">
                    <span className="font-semibold text-[20px] text-left mr-2">
                        {recipe.name}
                    </span>
                    {recipe.tags.map((tag, index) => (
                        <div
                            key={index}
                            className="border-solid border-theme-border border-2 rounded-full mr-1 px-2 h-fit"
                        >
                            {tag.content}
                        </div>
                    ))}
                </div>
                <div className="flex">
                    <RecipeRowDatum
                        label="Desire to make"
                        value={recipeWrapper.desireToMakeDisplay}
                    />
                    <RecipeRowDatum
                        value={recipeWrapper.ratingDisplay}
                        icon={recipeWrapper.ratingIcon}
                    />
                    <RecipeRowDatum
                        value={recipeWrapper.difficultyDisplay}
                        icon={recipeWrapper.difficultyIcon}
                    />
                    <RecipeRowDatum
                        value={recipe.time}
                        icon={recipeWrapper.timeIcon}
                    />
                </div>
            </div>
            <span className="ml-auto mr-0">{rowIcon}</span>
        </div>
    );

    return (
        <li className="w-100 h-[80px] block border-solid border-b border-theme-border last:border-b-0 hover:bg-theme-background-accent px-2 py-2">
            <button
                className="w-full h-full flex items-center"
                onClick={
                    onClick
                        ? () => onClick(recipe)
                        : () => navigate(`/cooking/recipe/view/${recipe.id}`)
                }
            >
                {rowContent}
            </button>
        </li>
    );
};

type RecipeRowDatumProps = {
    label?: string;
    value: string;
    icon?: React.ReactNode;
};

const RecipeRowDatum: React.FC<RecipeRowDatumProps> = ({
    label,
    value,
    icon,
}) => {
    if (!value || value === "?") return <></>;

    return (
        <div className="mr-3 flex items-center">
            {icon && <span className="mr-1">{icon}</span>}
            {label && `${label}: `}
            {value}
        </div>
    );
};

export default RecipeRow;
