import React from "react";

type ViewRecipeDetailProps = {
    name: string;
    value: any;
    display?: boolean;
};

const ViewRecipeDetail: React.FC<ViewRecipeDetailProps> = ({
    name,
    value,
    display = true,
}) => {
    if (!display) {
        return <></>;
    }

    return (
        <div className="w-fit min-w-[122px] rounded-[8px] text-center p-[5px] border-[2px] border-solid border-theme-border grow">
            <div className="font-[500] border-b-[2px] border-solid border-theme-border">
                {name}
            </div>
            <div>{value}</div>
        </div>
    );
};

export default ViewRecipeDetail;
