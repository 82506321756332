// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transparent-button,
.refresh-button,
.x-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  box-shadow: none !important; }
`, "",{"version":3,"sources":["webpack://./src/foundation/styles/Buttons.scss"],"names":[],"mappings":"AAAA;;;EAGI,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;EACZ,UAAU;EACV,2BAA2B,EAAA","sourcesContent":[".transparent-button,\n.refresh-button,\n.x-button {\n    display: flex;\n    align-items: center;\n    background-color: transparent;\n    border: none;\n    padding: 0;\n    box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
