import React from "react";

import { AppsEnum } from "foundation/apps";
import appStore from "foundation/stores/AppStore";

import AddTransactionBox from "./AddTransactionBox";
import TransactionHistory from "./TransactionHistory";
import MonthlyNetIncome from "./MonthlyNetIncome";
import PeopleFilter from "./PeopleFilter";
import DiscreteModeToggle from "./DiscreteModeToggle";

const MoneyHome: React.FC = () => {
    appStore.set.currentApp(AppsEnum.Money);

    // TODO filter by month
    return (
        <section className="flex flex-col gap-[30px]">
            <h1>💲 Money 💲</h1>

            <PeopleFilter />

            <DiscreteModeToggle />

            <MonthlyNetIncome />

            <TransactionHistory />

            <AddTransactionBox />
        </section>
    );
};

export default MoneyHome;
