import React from "react";
import { Link } from "react-router-dom";
import "cooking/styles/recipeCard.scss";

import { Recipe } from "cooking/models/types";

type RecipeCardProps = {
    recipe: Recipe;
    showButtons?: boolean;
    onClick?: (recipe: Recipe) => void;
};

const RecipeCard: React.FC<RecipeCardProps> = ({
    recipe,
    showButtons = true,
    onClick,
}) => {
    return (
        <div
            className={`recipe-card ${onClick ? "clickable" : ""} ${
                !showButtons ? "no-buttons" : ""
            }`}
            onClick={() => onClick?.(recipe)}
        >
            <div className="recipe-card-primary">{recipe.name}</div>
            {showButtons && (
                <div className="recipe-card-buttons">
                    <Link to={`recipe/view/${recipe.id}`}>
                        <button className="btn btn-primary">View</button>
                    </Link>
                    <Link to={`recipe/edit/${recipe.id}`}>
                        <button className="btn btn-primary">Edit</button>
                    </Link>
                    <Link to={`recipe/make/${recipe.id}`}>
                        <button className="btn btn-primary">Make</button>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default RecipeCard;
