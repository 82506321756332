import React from "react";
import "foundation/styles/Buttons.scss";

import Plus from "foundation/svgComponents/Plus";

type PlusButtonProps = {
    size?: string;
    className?: string;
    onClick?: () => void;
    title?: string;
    id?: string;
    type?: "button" | "submit" | "reset";
    text?: string;
};

const PlusButton: React.FC<PlusButtonProps> = ({
    size = "10px",
    className = "",
    onClick,
    title,
    id,
    type = "button",
    text,
}) => {
    return (
        <button
            id={id}
            className={`flex gap-[10px] items-center justify-center ${className}`}
            onClick={onClick}
            title={title}
            aria-label={title}
            type={type}
        >
            <Plus size={size} />
            {text && <span style={{ fontSize: size }}>{text}</span>}
        </button>
    );
};

export default PlusButton;
