import React, { useState, useEffect } from "react";
import { Tag } from "cooking/models/types";
import "foundation/styles/Tags.scss";

type TagFilterProps = {
    /** the tag options (e.g. "Kyle", "Sierra", "Both") */
    tags: Tag[];

    /** setState for which tags are actively filtering {something} */
    handleTagFilter: (tags: Tag[]) => void;

    className?: string;
};

/*
    // tagFilters = active filters
    const [tagFilters, setTagFilters] = useState<Tag[]>([]);

    <TagFilter
        tags={tagOptions_static}
        handleTagFilter={setTagFilters}
    />
 * */

const TagFilter: React.FC<TagFilterProps> = ({
    tags,
    handleTagFilter,
    className = "",
}) => {
    const [activeTags, setActiveTags] = useState([]);
    useEffect(() => {
        handleTagFilter(activeTags);
    }, [activeTags, handleTagFilter]);

    if (!tags || tags.length === 0) return <></>;

    const toggleActive = (tag: Tag) => {
        if (activeTags.find((t) => t.id === tag.id)) {
            setActiveTags(activeTags.filter((t) => t.id !== tag.id));
        } else {
            setActiveTags([...activeTags, tag]);
        }
    };

    return (
        <div className={className}>
            {tags.map((tag) => (
                <button
                    key={tag.id}
                    className={`tag-filter ${activeTags.find((t) => t.id === tag.id) ? "active" : ""
                        }`}
                    onClick={() => toggleActive(tag)}
                >
                    {tag.content}
                </button>
            ))}
        </div>
    );
};

export default TagFilter;
