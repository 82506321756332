import React from "react";
import "foundation/styles/Buttons.scss";

import X from "foundation/svgComponents/X";

type XButtonProps = {
    size: string;
    onClick?: () => void;
};

const XButton: React.FC<XButtonProps> = ({ size = "10px", onClick }) => {
    return (
        <button className="x-button" onClick={onClick}>
            <X size={size} />
        </button>
    );
};

export default XButton;
