import React from "react";
import { useImmer } from "use-immer";
import useModal from "foundation/modals/useModal";

import Checkbox from "foundation/components/forms/Checkbox";
import EditTags from "foundation/components/forms/EditTags";

import { Ingredient, KitchenIngredient, Tag } from "cooking/models/types";
import { curriedStateSlice } from "helpers/state.helpers";
import {
    getEmptyIngredient,
    getEmptyKitchenIngredient,
} from "cooking/helpers/ingredient.helpers";
import TextInput from "foundation/components/forms/TextInput";

type KiDetailsModalProps = {
    kitchenIngredient: KitchenIngredient | undefined;
    ingredient: Ingredient | undefined;
    allExistingTags?: Tag[];
    handleSave: (ki: KitchenIngredient) => void;
    handleIngredientOnlySave?: (ingredient: Ingredient) => void;
    handleRemove: (ki: KitchenIngredient) => void;
};

// TODO add edit name
const KitchenIngredientDetailsModal: React.FC<KiDetailsModalProps> = ({
    kitchenIngredient: _kitchenIngredient,
    ingredient: _ingredient,
    allExistingTags,
    handleSave,
    handleIngredientOnlySave,
    handleRemove,
}) => {
    const { closeModal } = useModal();

    const noIngredient = !_ingredient;

    const noKitchenIngredient = !_kitchenIngredient;
    const [kitchenIngredient, setKitchenIngredient] = useImmer(
        _kitchenIngredient ??
        getEmptyKitchenIngredient(_ingredient ?? getEmptyIngredient())
    );
    const updateSlice = curriedStateSlice(setKitchenIngredient);
    const updateIngredientSlice = curriedStateSlice(updateSlice("ingredient"));

    const ingredient = kitchenIngredient.ingredient;
    const tagStrings =
        kitchenIngredient.ingredient?.tags.map((tag) => tag.content) ?? [];

    return (
        <div className="flex flex-col gap-[20px] w-[300px]">
            {!noIngredient && <h1>{kitchenIngredient.ingredient.name}</h1>}
            {noIngredient && (
                <TextInput
                    placeholder="Ingredient Name"
                    value={ingredient.name}
                    onChange={(n) => updateIngredientSlice("name", n)}
                    autoFocus
                    extraInputClasses="text-[2.5rem]"
                />
            )}

            <div className="flex flex-col gap-[10px] min-w-[150px] w-full">
                <Checkbox
                    text="In Stock"
                    value={kitchenIngredient.inStock}
                    boxEnd
                    inputClassName="!ml-auto border-b-[1px] border-solid"
                    labelClassName="text-[18px]"
                    onClick={() =>
                        updateSlice("inStock", !kitchenIngredient.inStock)
                    }
                />
                <Checkbox
                    text="Keep Stocked"
                    value={false}
                    boxEnd
                    inputClassName="!ml-auto"
                    labelClassName="text-[18px]"
                    onClick={() =>
                        console.log("TODO toggle Keep Stocked (+ add field)")
                    }
                />
                <Checkbox
                    text="Low?"
                    value={false}
                    boxEnd
                    inputClassName="!ml-auto"
                    labelClassName="text-[18px] text-theme-button-danger"
                    onClick={() => console.log("TODO toggle Low (+ add field)")}
                />

                <EditTags
                    tags={tagStrings}
                    allAvailableTags={allExistingTags}
                    setTags={(newTags) =>
                        updateIngredientSlice(
                            "tags",
                            newTags.map((tagString) => {
                                if (tagStrings.includes(tagString)) {
                                    // If we already had this tag, preserve its data (i.e. its id)
                                    const oldTag =
                                        kitchenIngredient.ingredient.tags.find(
                                            (tag) => tag.content === tagString
                                        );
                                    if (oldTag) {
                                        return oldTag;
                                    }
                                }
                                return { content: tagString };
                            })
                        )
                    }
                />
            </div>

            <div className="flex gap-[15px]">
                <button onClick={closeModal} className="btn btn-secondary">
                    Cancel
                </button>

                {!noKitchenIngredient && (
                    <button
                        onClick={() => {
                            handleRemove(kitchenIngredient);
                            closeModal();
                        }}
                        className="btn btn-danger"
                    >
                        Untrack
                    </button>
                )}

                <div className="flex gap-[10px] ml-auto">
                    {!_kitchenIngredient && (
                        <button
                            onClick={() => {
                                handleIngredientOnlySave?.(
                                    kitchenIngredient.ingredient
                                );
                                closeModal();
                            }}
                            className="btn btn-primary"
                        >
                            Save
                        </button>
                    )}
                    <button
                        onClick={() => {
                            handleSave(kitchenIngredient);
                            closeModal();
                        }}
                        className="btn btn-primary"
                    >
                        {_kitchenIngredient ? "Save" : "Track"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default KitchenIngredientDetailsModal;
