import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type LibraryIconProps = {
    className?: string;
    color?: string;
};

const LibraryIcon: React.FC<LibraryIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <>
            <svg
                fill={color}
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 64 80"
                x="0px"
                y="0px"
            >
                <path d="M6,44a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1,2,2,0,0,1,.67-1.48,1,1,0,0,0,.33-.74V26.22a1,1,0,0,0-.33-.74A2,2,0,0,1,12,24a1,1,0,0,0-1-1H7a1,1,0,0,0-1,1,2,2,0,0,1-.67,1.48,1,1,0,0,0-.33.74V41.78a1,1,0,0,0,.33.74A2,2,0,0,1,6,44ZM7,26.63A4,4,0,0,0,7.87,25h2.26A4,4,0,0,0,11,26.63V41.37A4,4,0,0,0,10.13,43H7.87A4,4,0,0,0,7,41.37Z" />
                <path d="M20,50h4a1,1,0,0,0,1-1V19a1,1,0,0,0-1-1H20a1,1,0,0,0-1,1V49A1,1,0,0,0,20,50Zm1-30h2V48H21Z" />
                <path d="M40,47a1,1,0,0,0,1-1V36a1,1,0,0,0-2,0V46A1,1,0,0,0,40,47Z" />
                <path d="M53,25.37h0l-1.45-3.73a1,1,0,0,0-.52-.56,1,1,0,0,0-.77,0l-5.59,2.17a1,1,0,0,0-.57,1.3L45,27H36V17a1,1,0,0,0-1-1H29V10a1,1,0,0,0-1-1H14a1,1,0,0,0-1,1v3H2a1,1,0,0,0-1,1V54a1,1,0,0,0,1,1H45a1,1,0,0,0,1-1V29.51l9.66,24.85a1,1,0,0,0,.53.56,1.14,1.14,0,0,0,.4.08.94.94,0,0,0,.37-.07l5.59-2.17a1,1,0,0,0,.57-1.3ZM15,53H3V15H15Zm12,0H17V14a1,1,0,0,0-1-1H15V11H27Zm7,0H29V51h5Zm0-4H29V47h5Zm0-4H29V23h5Zm0-24H29V18h5ZM44,53H36V51h8Zm0-4H36V33h8Zm0-18H36V29h8Zm2.29-6.26L50,23.29l.73,1.87L47,26.61Zm1.45,3.73L51.47,27,58,43.8l-3.73,1.45Zm8,20.51L55,47.11l3.73-1.45.72,1.87Zm1.45,3.73-.72-1.87,3.73-1.45.72,1.87Z" />
            </svg>
        </>
    );
};

export default LibraryIcon;
