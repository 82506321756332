import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type TaskTrackerIconProps = {
    className?: string;
    color?: string;
};

const TaskTrackerIcon: React.FC<TaskTrackerIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 47 58.75"
                enableBackground="new 0 0 47 47"
                fill={color}
                className={className}
            >
                <path d="M21.943,9.811h19.37c0.828,0,1.5-0.671,1.5-1.5s-0.672-1.5-1.5-1.5h-19.37c-0.829,0-1.5,0.671-1.5,1.5  S21.115,9.811,21.943,9.811z" />
                <path d="M17.563,1.046H5.688c-0.829,0-1.5,0.671-1.5,1.5v11.529c0,0.829,0.671,1.5,1.5,1.5h11.875c0.829,0,1.5-0.671,1.5-1.5V2.546  C19.063,1.717,18.391,1.046,17.563,1.046z M14.797,6.649l-2.058,3.888c-0.001,0-0.001,0.001-0.001,0.001l-0.016,0.031  c-0.007,0.014-0.019,0.024-0.026,0.037c-0.037,0.063-0.073,0.128-0.12,0.188c-0.019,0.023-0.042,0.04-0.061,0.061  c-0.034,0.038-0.069,0.072-0.106,0.106c-0.059,0.054-0.12,0.103-0.185,0.146c-0.039,0.026-0.077,0.052-0.119,0.074  c-0.074,0.04-0.151,0.071-0.23,0.097c-0.039,0.014-0.075,0.03-0.115,0.04c-0.114,0.029-0.23,0.046-0.347,0.047  c-0.007,0-0.013,0.002-0.019,0.002c-0.144,0-0.287-0.028-0.429-0.071c-0.043-0.013-0.082-0.03-0.123-0.047  c-0.05-0.02-0.101-0.032-0.149-0.057c-0.07-0.037-0.132-0.083-0.194-0.129c-0.01-0.007-0.021-0.01-0.031-0.017L8.854,9.778  C8.202,9.267,8.089,8.324,8.603,7.673c0.512-0.651,1.454-0.764,2.106-0.253l0.202,0.159l1.235-2.333  c0.387-0.732,1.294-1.014,2.027-0.624C14.905,5.009,15.185,5.917,14.797,6.649z" />
                <path d="M41.313,22h-19.37c-0.829,0-1.5,0.671-1.5,1.5c0,0.828,0.671,1.5,1.5,1.5h19.37c0.828,0,1.5-0.672,1.5-1.5  C42.813,22.671,42.141,22,41.313,22z" />
                <path d="M17.563,16.235H5.688c-0.829,0-1.5,0.671-1.5,1.5v11.529c0,0.828,0.671,1.5,1.5,1.5h11.875c0.829,0,1.5-0.672,1.5-1.5  V17.735C19.063,16.907,18.391,16.235,17.563,16.235z M14.796,21.839l-2.075,3.92c-0.009,0.017-0.023,0.027-0.032,0.044  c-0.035,0.063-0.07,0.123-0.115,0.182c-0.017,0.022-0.04,0.039-0.059,0.06c-0.034,0.039-0.069,0.073-0.107,0.108  c-0.059,0.054-0.121,0.103-0.186,0.146c-0.039,0.024-0.076,0.052-0.116,0.072c-0.077,0.042-0.157,0.073-0.239,0.101  c-0.035,0.012-0.069,0.027-0.105,0.036c-0.121,0.03-0.244,0.05-0.368,0.05c-0.144,0-0.287-0.027-0.429-0.07  c-0.042-0.012-0.081-0.029-0.122-0.047c-0.05-0.02-0.102-0.031-0.15-0.057c-0.072-0.037-0.135-0.084-0.198-0.132  c-0.008-0.006-0.019-0.008-0.027-0.015l-0.028-0.021l-1.586-1.246c-0.652-0.513-0.765-1.456-0.252-2.107  c0.512-0.65,1.454-0.765,2.106-0.252l0.202,0.159l1.235-2.333c0.387-0.732,1.294-1.013,2.027-0.624  C14.904,20.199,15.184,21.107,14.796,21.839z" />
                <path d="M41.313,37.189h-19.37c-0.829,0-1.5,0.672-1.5,1.5s0.671,1.5,1.5,1.5h19.37c0.828,0,1.5-0.672,1.5-1.5  S42.141,37.189,41.313,37.189z" />
                <path d="M17.563,31.425H5.688c-0.829,0-1.5,0.673-1.5,1.5v11.529c0,0.827,0.671,1.5,1.5,1.5h11.875c0.829,0,1.5-0.673,1.5-1.5  V32.925C19.063,32.098,18.391,31.425,17.563,31.425z" />
            </svg>
        </>
    );
};

export default TaskTrackerIcon;
