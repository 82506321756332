import React, { useState } from "react";
import "foundation/styles/Tags.scss";

import PlusButton from "foundation/components/PlusButton";
import ExpandingTextInput from "foundation/components/forms/ExpandingTextInput";
import { Tag } from "cooking/models/types";

type EditTagsProps = {
    tags: string[];
    setTags: (newTags: string[]) => void;
    className?: string;
    label?: string;
    allAvailableTags?: Tag[];
};

const EditTags: React.FC<EditTagsProps> = ({
    tags = [],
    setTags,
    label,
    className = "",
    allAvailableTags = [],
}) => {
    const [addTagText, setAddTagText] = useState("");

    const deleteTag = (indexToDelete: number) => {
        const newTags = tags.filter((tag, index) => index !== indexToDelete);
        setTags(newTags);
    };

    const addTag = (newTag: string) => {
        const newTags = [...tags, newTag];
        setAddTagText("");
        setTags(newTags);
    };

    const unselectedAvailableTags = allAvailableTags.filter(
        (t) => !tags.includes(t.content)
    );

    tags.sort();
    unselectedAvailableTags.sort((a, b) => a.content.localeCompare(b.content));

    return (
        <div
            className={`flex items-center flex-wrap gap-[5px] ${className ?? ""
                }`}
        >
            {label ?? "Tags:"}
            {tags.map((tag, i) => (
                <button
                    key={`${tag}-${i}`}
                    onClick={() => deleteTag(i)}
                    className="tag"
                >
                    {tag}
                </button>
            ))}

            {unselectedAvailableTags?.map((tag, i) => (
                <button
                    key={`${tag.content}-${i}`}
                    onClick={() => addTag(tag.content)}
                    className="tag inactive"
                >
                    {tag.content}
                </button>
            ))}

            <span className="tag">
                <ExpandingTextInput
                    text={addTagText}
                    setText={setAddTagText}
                    className="add-tag-input"
                    placeholder="Add Tag..."
                    onEnter={addTag}
                />
                <PlusButton size="10px" onClick={() => addTag(addTagText)} />
            </span>
        </div>
    );
};

export default EditTags;
