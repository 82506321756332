import React, { useRef, useState, useEffect } from "react";
import { useApi } from "common/hooks/useApi";

import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/ReactContexify.css";

import PlusButton from "foundation/components/PlusButton";
import TextInput from "foundation/components/forms/TextInput";

import { Notebook } from "rememberall/models/types";

const NotebookSideNav: React.FC = () => {
    const [isAddNotebook, setIsAddNotebook] = useState(false);
    const [newNotebookName, setNewNotebookName] = useState("");
    const [activeNotebookId, setActiveNotebookId] = useState(undefined);

    const {
        data: notebooks,
        createItem: createNotebook,
        deleteById: deleteNotebook,
    } = useApi<Notebook>("notebook");

    console.log("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥");
    console.log("notebooks:", notebooks);

    const notebookListRef = useRef<HTMLUListElement>();
    const notebookInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        // focus input ref after clicking plus button
        if (setIsAddNotebook && notebookInputRef.current) {
            notebookInputRef.current.focus();
        }
    }, [isAddNotebook]);

    const { show } = useContextMenu({
        id: "notebook",
    });

    const handleContextMenu = (event, id: number) => {
        show({
            event,
            props: {
                key: "value",
                notebookId: id,
            },
        });
    };

    const handleDeleteClick = ({ event, props, triggerEvent, data }) => {
        deleteNotebook(props.notebookId);
    };

    const listClassName =
        "border-solid border-r-[1px] border-theme-border pt-2 px-0 my-0 flex flex-col gap-[2px] min-w-[90px]";

    return (
        <aside className="flex bg-theme-background-accent w-fit pb-0 h-[calc(100vh-58px)]">
            <ul className={listClassName} ref={notebookListRef}>
                {notebooks?.map((notebook) => {
                    const { title, id } = notebook;
                    const isActive = id === activeNotebookId;
                    return (
                        <button
                            className={`text-left px-[10px] ${isActive ? "bg-theme-background" : ""
                                }`}
                            onClick={() => setActiveNotebookId(id)}
                            onContextMenu={(e) => {
                                handleContextMenu(e, id);
                            }}
                        >
                            {title}
                        </button>
                    );
                })}
                {/* TODO theme */}
                <Menu id="notebook">
                    <Item id="delete" onClick={handleDeleteClick}>
                        Delete
                    </Item>
                </Menu>
                {isAddNotebook && (
                    <TextInput
                        className="w-full mt-[6px] mx-[5px]"
                        extraInputClasses="rounded-md px-[4px] py-[2px]"
                        defaultInputClasses=""
                        value={newNotebookName}
                        onChange={setNewNotebookName}
                        innerRef={notebookInputRef}
                        style={{
                            maxWidth: notebookListRef.current.clientWidth - 20,
                            minWidth: 80,
                        }}
                        onEnter={() => {
                            const newNotebook = { title: newNotebookName };

                            createNotebook(newNotebook);

                            setNewNotebookName("");
                            setIsAddNotebook(false);
                        }}
                    />
                )}
                {!isAddNotebook && (
                    <PlusButton
                        className="w-full mt-1"
                        size="16px"
                        onClick={() => setIsAddNotebook(true)}
                    />
                )}
            </ul>

            <ul className={listClassName}>
                <button>page 1</button>
                <button>page 2</button>
            </ul>
        </aside>
    );
};

export default NotebookSideNav;
