import { toTwoDecimals } from "helpers/number.helpers";
import { PlayerGameStats } from "fantasyFootball/models/types";

// TODO make one for stats[]
// TODO take in fantasy points leagues settings to convert
export const getPointsForGame = (stats: PlayerGameStats) => {
    let points = 0;

    // offensive
    points += stats.passYards * 0.04;
    points += stats.rushYards * 0.1;
    points += stats.recYards * 0.1;
    points += stats.passTds * 4;
    points += stats.rushTds * 6;
    points += stats.recTds * 6;

    points -= stats.fumblesLost * -2;
    points -= stats.interceptionsThrown * -2; // Playing for keeps value, Yahoo value = -1

    // IDP
    points += stats.soloTackles * 1;
    points += stats.tackleAssist * 0.5;
    points += stats.sacks * 2;
    points += stats.interceptions * 3;
    points += stats.forcedFumbles * 2;
    points += stats.fumblesRecovered * 2;
    points += stats.defensiveTds * 6;
    points += stats.safeties * 2;
    points += stats.passesDefensed * 1;
    points += stats.blockedKicks * 2;
    points += stats.extraPointsReturned * 2;

    // kicking
    points += stats.fg0To19 * 3;
    points += stats.fg20To29 * 3;
    points += stats.fg30To39 * 3;
    points += stats.fg40To49 * 3.5; // yahoo default 4
    points += stats.fg50Plus * 4; // yahoo default 5
    points += stats.extraPointsMade * 1;

    return toTwoDecimals(points);
};

export const getAveragePpg = (stats: PlayerGameStats[]) => {
    if (!stats || stats.length === 0) return 0;

    const totalPoints: number = stats.reduce((runningTotal, gameStats) => {
        return (runningTotal += getPointsForGame(gameStats));
    }, 0);

    return toTwoDecimals(totalPoints / stats.length);
};

export const sortStatsIntoBuckets = (
    stats: PlayerGameStats[],
    position: string
) => {
    // takes in stats and position and returns { amazing: 3, great: 1, good: 0, fine: 1, bad: 8, dud: 1}

    const buckets = [
        {
            title: "Amazing",
            bucketRanges: {
                QB: {
                    minRange: 35,
                    maxRange: 1000,
                },
                RB: {
                    minRange: 25,
                    maxRange: 1000,
                },
                WR: {
                    minRange: 20,
                    maxRange: 1000,
                },
                TE: {
                    minRange: 20,
                    maxRange: 1000,
                },
            },
        },
        {
            title: "Great",
            bucketRanges: {
                QB: {
                    minRange: 28,
                    maxRange: 35,
                },
                RB: {
                    minRange: 19,
                    maxRange: 25,
                },
                WR: {
                    minRange: 15,
                    maxRange: 20,
                },
                TE: {
                    minRange: 15,
                    maxRange: 20,
                },
            },
        },
        {
            title: "Good",
            bucketRanges: {
                QB: {
                    minRange: 20,
                    maxRange: 28,
                },
                RB: {
                    minRange: 13,
                    maxRange: 19,
                },
                WR: {
                    minRange: 10,
                    maxRange: 15,
                },
                TE: {
                    minRange: 10,
                    maxRange: 15,
                },
            },
        },
        {
            title: "Fine",
            bucketRanges: {
                QB: {
                    minRange: 15,
                    maxRange: 20,
                },
                RB: {
                    minRange: 8,
                    maxRange: 13,
                },
                WR: {
                    minRange: 7,
                    maxRange: 10,
                },
                TE: {
                    minRange: 6,
                    maxRange: 10,
                },
            },
        },
        {
            title: "Bad",
            bucketRanges: {
                QB: {
                    minRange: 9,
                    maxRange: 15,
                },
                RB: {
                    minRange: 5,
                    maxRange: 8,
                },
                WR: {
                    minRange: 4,
                    maxRange: 7,
                },
                TE: {
                    minRange: 4,
                    maxRange: 6,
                },
            },
        },
        {
            title: "Dud",
            bucketRanges: {
                QB: {
                    minRange: -10,
                    maxRange: 9,
                },
                RB: {
                    minRange: -10,
                    maxRange: 5,
                },
                WR: {
                    minRange: -10,
                    maxRange: 4,
                },
                TE: {
                    minRange: -10,
                    maxRange: 4,
                },
            },
        },
    ];

    const bucketsForPlayer = {
        Amazing: 0,
        Great: 0,
        Good: 0,
        Fine: 0,
        Bad: 0,
        Dud: 0,
    };

    stats.forEach((gameStats) => {
        const fantasyPoints = getPointsForGame(gameStats);
        const bucket = buckets.find((b) => {
            const range = b.bucketRanges[position];
            if (!range) return false;

            return (
                fantasyPoints >= range.minRange &&
                fantasyPoints < range.maxRange
            );
        });

        if (!bucket) return; // safety net since ranges aren't fully implemented yet

        bucketsForPlayer[bucket.title] += 1;
    });

    return bucketsForPlayer;
};
