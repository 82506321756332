import React from "react";
import { Choices } from "./types";

type ButtonGroupChoiceToggleProps = {
    /** The name of the field + name of selection in request.POST */
    name: string;

    /** The value that's currently selected */
    selectedValue: string;

    /** The options for the toggle. Array of objects {'value', 'text', 'active'} */
    choices: Choices;

    /** The function to call when the value changes */
    handleChange: (newStatus: string) => void;

    className?: string;
    labelClassName?: string;
};

const ButtonGroupChoiceToggle: React.FC<ButtonGroupChoiceToggleProps> = ({
    name,
    selectedValue,
    choices,
    handleChange,
    className = "",
    labelClassName = "",
}) => {
    const VALUE_INDEX = 0;
    const DISPLAY_WORD_INDEX = 1;

    return (
        <div
            id={`${name}-buttons`}
            className={`btn-group btn-group-toggle ${className}`}
            data-toggle="buttons"
        >
            {choices.map((choice) => (
                <label
                    key={`${name}_${choice[VALUE_INDEX]}`}
                    className={`btn btn-secondary ${selectedValue === choice[VALUE_INDEX] ? "active" : ""
                        } ${labelClassName}`}
                    onClick={() => handleChange(choice[VALUE_INDEX])}
                >
                    {choice[DISPLAY_WORD_INDEX]}
                    <input
                        type="radio"
                        className="opacity-0 pointer-events-none fixed"
                        name={name}
                        value={choice[VALUE_INDEX]}
                        id={`id_${name}_${choice[VALUE_INDEX]}`}
                        defaultChecked={selectedValue === choice[VALUE_INDEX]}
                    />
                </label>
            ))}
        </div>
    );
};

export default ButtonGroupChoiceToggle;
