import {
    DependencyList,
    MutableRefObject,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import _ from "lodash";

type UseOnScreen = (
    ref: MutableRefObject<Element> | RefObject<Element> | Element | null,
    args: {
        offset?: number | string;
        throttleTime?: number;
        options?: IntersectionObserverInit;
        onChange?: (entry: IntersectionObserverEntry) => void;
        observeImmediately?: boolean;
        setVariable?: boolean;
    }
) => {
    onScreen: boolean;
    observe: () => void;
    unobserve: () => void;
};

/**
 * React hook for determining if an element is on-screen
 *
 * Use like so:
 *
 * const elementRef = useRef();
 * const { onScreen } = useOnScreen({ ref: elementRef });
 *
 * return (
 *     <div>
 *         <h1>{`It's ${onScreen ? '' : 'not '}on the screen!`}</h1>
 *         <div ref={elementRef}</div>
 *     </div>
 * )
 */
const useOnScreen: UseOnScreen = (
    ref,
    {
        offset = 0,
        throttleTime = 100,
        options = {},
        onChange = () => { },
        observeImmediately = true,
        setVariable = true,
    }
) => {
    const [onScreen, setOnScreen] = useState(false);
    const throttledSetOnScreen = useCallback(
        _.throttle(setOnScreen, throttleTime),
        []
    );

    const observer = useRef<IntersectionObserver>();

    /**
     * To allow this hook to take in refs from React.useRef, React.createRef, and
     * React.useState, we have to check if the incoming Ref is a MutableRefObject, or just a
     * straight ref to the Element. We check for this by looking for the property current on
     * the incoming object. Refs set with useRef and createRef will always have this property,
     * but will initially set it to undefined, meaning we must use Object.hasOwnProperty to
     * check for its existence. Refs set with useState will not contain the current property,
     * so if the incoming ref object does not contain a current property, then it must be a
     * ref returned by useState
     */
    const getElement = () => ref && ("current" in ref ? ref.current : ref);

    /**
     * To prevent React from calling the IntersectionObserver constructor on every re-render, we
     * initialize observer to undefined, then access it via this method, which creates a new
     * IntersectionObserver _only_ if one does not already exist in observer.current. This is both a
     * small performance win, and a nice way to guarantee the existence of the observer
     */
    const getObserver = () => {
        if (observer.current === undefined) {
            const rootMargin =
                typeof offset === "number" ? `${offset}px` : offset;

            observer.current = new IntersectionObserver(
                ([entry]) => {
                    if (setVariable) throttledSetOnScreen(entry.isIntersecting);
                    onChange(entry);
                },
                { rootMargin, ...options }
            );
        }

        return observer.current;
    };

    const observe = (element?: Element | null) => {
        try {
            const el = element ?? getElement();
            if (el) getObserver().observe(el);
        } catch (e) {
            console.error(e);
        }
    };

    const unobserve = (element?: Element | null) => {
        try {
            const el = element ?? getElement();
            if (el) getObserver().unobserve(el);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        const element = getElement();

        getObserver().disconnect();

        if (element && observeImmediately) observe(element);

        return () => unobserve(element);
    }, [ref, ref && "current" in ref && ref.current, offset]);

    return { onScreen, observe, unobserve };
};

export default useOnScreen;

// alternate implementation
/* usage: 
 // Ref for the element that we want to detect whether on screen
  const ref: any = useRef<HTMLDivElement>();
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.
  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, "-300px");

    return (
    <div>
      <div style={{ height: "100vh" }}>
        <h1>Scroll down to next section 👇</h1>
      </div>
      <div
        ref={ref}
        style={{
          height: "100vh",
          backgroundColor: onScreen ? "#23cebd" : "#efefef",
        }}
      >
        {onScreen ? (
          <div>
            <h1>Hey I'm on the screen</h1>
            <img src="https://i.giphy.com/media/ASd0Ukj0y3qMM/giphy.gif" />
          </div>
        ) : (
          <h1>Scroll down 300px from the top of this section 👇</h1>
        )}
      </div>
    </div>
  );

  */

/* function useOnScreen<T extends Element>(
    ref: MutableRefObject<T>,
    {
        offset = 0,
        dependencies = [],
    }: { offset?: number | string; dependencies?: DependencyList }
): boolean {
    const rootMargin = typeof offset === "number" ? `${offset}px` : offset;

    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState<boolean>(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin,
            }
        );
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, dependencies); // Empty array ensures that effect is only run on mount and unmount
    return isIntersecting;
}

export default useOnScreen; */
