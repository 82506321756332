export const parseIngredientText = (ingredientText: string) => {
    const regex = /(?<quantity>[\d/]+)\s+(?<unit>[\w.]+)\s+(?<name>[\w\s()]+)/;
    const match = ingredientText.match(regex);

    if (!match?.groups) return {};

    return {
        unit: match.groups.unit,
        quantity: match.groups.quantity,
        name: match.groups.name,
    };
};
