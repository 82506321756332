// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-with-icon {
  align-items: center; }
  .select-with-icon button {
    margin-left: 10px; }

.no-lastpass [data-lastpass-icon-root] {
  display: none !important; }
`, "",{"version":3,"sources":["webpack://./src/foundation/styles/Inputs.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB,EAAA;EADvB;IAIQ,iBAAiB,EAAA;;AAIzB;EAEQ,wBAAwB,EAAA","sourcesContent":[".select-with-icon {\n    align-items: center;\n\n    button {\n        margin-left: 10px;\n    }\n}\n\n.no-lastpass {\n    [data-lastpass-icon-root] {\n        display: none !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
