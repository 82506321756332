import React, { useState } from "react";
import useModal from "foundation/modals/useModal";
import { useNavigate } from "react-router-dom";
import { callApiMethod } from "helpers/api.helpers";

import Plus from "foundation/svgComponents/Plus";
import PlusButton from "foundation/components/PlusButton";
import SearchableRecipes from "./SearchableRecipes";
import Loading, { LoadingType } from "common/components/Loading";

import { Cookbook, Recipe } from "cooking/models/types";

type AddRecipeToCookbookProps = {
    cookbook: Cookbook;
    onSuccess?: (recipe: Recipe) => void;
};

const AddRecipeToCookbook: React.FC<AddRecipeToCookbookProps> = ({
    cookbook,
    onSuccess,
}) => {
    const navigate = useNavigate();
    const { closeModal } = useModal();

    const [isLoading, setIsLoading] = useState(false);

    const handleAddRecipe = async (recipe: Recipe) => {
        setIsLoading(true);
        try {
            await callApiMethod(`cookbooks/${cookbook.id}/add_recipe/`, {
                recipeId: recipe.id,
            });
            onSuccess?.(recipe);
            closeModal();
        } catch (error) {
            console.log("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥");
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddNewRecipe = () => {
        closeModal();
        navigate(`/cooking/recipe/create?cookbookId=${cookbook.id}`);
    };

    return (
        <div className="p-[10px] flex flex-col gap-[20px] relative">
            {isLoading && (
                <div className="absolute top-0 left-0 w-full h-full backdrop-blur-[4px] flex flex-col gap-[10px] items-center justify-center z-50 text-[20px]">
                    <Loading type={LoadingType.diamon} />
                    Adding Recipe...
                </div>
            )}

            <h1 className="font-[600]">Add Recipe to {cookbook.name}</h1>

            <PlusButton
                text="Add New Recipe"
                size="20px"
                className="w-fit"
                onClick={handleAddNewRecipe}
            />

            <SearchableRecipes
                titleOverride=""
                onRecipeClick={handleAddRecipe}
                recipesToExclude={cookbook.recipes}
                rowIcon={<Plus />}
            />
        </div>
    );
};

export default AddRecipeToCookbook;
