import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type SkillsIconProps = {
    className?: string;
    color?: string;
};

const SkillsIcon: React.FC<SkillsIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            fill={color}
            className={className}
        >
            <path d="M54,2c-4.418,0-8,3.582-8,8s3.582,8,8,8s8-3.582,8-8S58.418,2,54,2z M56.472,14L54,12.633L51.528,14L52,11.106l-2-2.05  l2.764-0.422L54,6l1.236,2.633L58,9.056l-2,2.05L56.472,14z" />
            <path d="M10,18c4.418,0,8-3.582,8-8s-3.582-8-8-8s-8,3.582-8,8S5.582,18,10,18z M6,7h8v4h-3l-1,3l-2-3H6V7z" />
            <path d="M32,2c-4.418,0-8,3.582-8,8s3.582,8,8,8s8-3.582,8-8S36.418,2,32,2z M35.515,10.675L32,14l-3.515-3.325  c-0.647-0.612-0.647-1.604,0-2.216c0.647-0.612,1.696-0.612,2.343,0L32,9.567l1.172-1.108c0.647-0.612,1.696-0.612,2.343,0  C36.162,9.071,36.162,10.063,35.515,10.675z" />
            <polygon points="6,62 14,62 14,56.5 10,56.5 10,55.5 14,55.5 14,50.5 10,50.5 10,49.5 14,49.5 14,44.5 10,44.5 10,43.5 14,43.5   14,42 18,42 10,33 2,42 6,42 " />
            <polygon points="46,35 50,35 50,62 58,62 58,56.5 54,56.5 54,55.5 58,55.5 58,50.5 54,50.5 54,49.5 58,49.5 58,44.5 54,44.5   54,43.5 58,43.5 58,38.5 54,38.5 54,37.5 58,37.5 58,35 62,35 54,26 " />
            <polygon points="32,20 24,29 28,29 28,37 36,37 36,34.5 32,34.5 32,33.5 36,33.5 36,29 40,29 " />
            <path d="M25,56v6l14,0v-6c0-2.674-1.751-4.938-4.168-5.713C36.716,49.274,38,47.285,38,45c0-3.309-2.691-6-6-6s-6,2.691-6,6  c0,2.285,1.284,4.274,3.168,5.287C26.751,51.062,25,53.326,25,56z M32,59l-2-4l1.608-4.02C31.738,50.989,31.868,51,32,51  s0.262-0.011,0.392-0.02L34,55L32,59z M28,45c0-0.349,0.059-0.681,0.143-1.004C29.287,43.364,30.599,43,32,43  s2.713,0.364,3.857,0.996C35.941,44.319,36,44.651,36,45c0,2.206-1.794,4-4,4S28,47.206,28,45z" />
        </svg>
    );
};

export default SkillsIcon;
