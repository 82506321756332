import React from "react";
import { Navbar, Nav } from "react-bootstrap"; // https://react-bootstrap.github.io/components/navbar/
// import { Link } from "react-router-dom";

import "foundation/styles/NavBar.scss";

import appStore from "foundation/stores/AppStore";

import pathIcon from "foundation/img/path.svg";
import { AppsEnum } from "foundation/apps";

const NavBar: React.FC = () => {
    const currentApp = appStore.useTracked.currentApp();

    let appSpecificClassName = "";
    if (currentApp === AppsEnum.Rememberall) {
        appSpecificClassName = "no-bottom-margin";
    }

    return (
        <Navbar
            className={`mb-4 max-w-[100vw] overflow-x-auto !pr-[15px] ${appSpecificClassName}`}
        >
            <Navbar.Brand href="/" className="shrink-0">
                <img src={pathIcon} className="nav-icon" alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto items-center">
                    <Nav.Link
                        href="/cooking"
                        active={currentApp === AppsEnum.Cooking}
                    >
                        Cooking
                    </Nav.Link>
                    <Nav.Link
                        href="/rememberall"
                        active={currentApp === AppsEnum.Rememberall}
                    >
                        Rememberall
                    </Nav.Link>
                    <Nav.Link
                        href="/fantasyFootball"
                        active={currentApp === AppsEnum.FantasyFootball}
                    >
                        Fantasy Football
                    </Nav.Link>
                    <Nav.Link
                        href="/money"
                        active={currentApp === AppsEnum.Money}
                    >
                        Money
                    </Nav.Link>
                    <Nav.Link
                        href="/skills"
                        active={currentApp === AppsEnum.Skills}
                    >
                        Skills
                    </Nav.Link>
                    <Nav.Link
                        href="/settings"
                        active={currentApp === AppsEnum.Settings}
                    >
                        Settings
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

/*
const NavBar: React.FC = () => {
    const currentApp = appStore.use.currentApp();

    return (
        <nav className="flex gap-4 py-[10px] items-center">
            <Link to="/">
                <img src={pathIcon} className="nav-icon" alt="logo" />
            </Link>

            <div className="flex gap-3 items-center h-full">
                <Link
                    to="/cooking"
                    className={`${currentApp === AppsEnum.Cooking ? "bg-black h-full" : ""
                        }`}
                >
                    Cooking
                </Link>
                <Link to="/rememberall">Rememberall</Link>
                <Link to="/fantasyFootball">Fantasy Football</Link>
                <Link to="/settings">Settings</Link>
            </div>
        </nav>
    );
};
*/

export default NavBar;
