import devNoteSidebarStore from "devNotes/stores/DevNoteSidebarStore";
import TextInput from "foundation/components/forms/TextInput";
import React from "react";

const NotesSidebar: React.FC = () => {
    const isOpen = devNoteSidebarStore.useTracked.isOpen();
    const noteText = devNoteSidebarStore.useTracked.noteText();

    if (!isOpen) return undefined;

    return (
        <aside className="w-[30%] h-[100vh] flex flex-col gap-[30px] p-[30px] border-solid border-l-[2px] border-theme-border">
            <h1 className="text-center m-0 text-shadow-sm">Dev Note</h1>

            <TextInput
                type="textarea"
                value={noteText}
                onChange={(val) => devNoteSidebarStore.set.noteText(val)}
            />
        </aside>
    );
};

export default NotesSidebar;
