// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  padding-right: 1rem;
  padding-left: 1rem !important;
  background-color: var(--nav-color); }
  nav .nav-link {
    color: var(--nav-text-color) !important;
    opacity: 0.6; }
    nav .nav-link.active {
      opacity: 1; }

.nav-icon {
  height: 32px;
  width: 32px; }

.no-bottom-margin {
  margin-bottom: 0 !important; }
`, "",{"version":3,"sources":["webpack://./src/foundation/styles/NavBar.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,6BAA6B;EAE7B,kCAAkC,EAAA;EAJtC;IAOQ,uCAAuC;IACvC,YAAY,EAAA;IARpB;MAWY,UAAU,EAAA;;AAKtB;EACI,YAAY;EACZ,WAAW,EAAA;;AAGf;EACI,2BAA2B,EAAA","sourcesContent":["nav {\n    padding-right: 1rem;\n    padding-left: 1rem !important;\n\n    background-color: var(--nav-color);\n\n    .nav-link {\n        color: var(--nav-text-color) !important;\n        opacity: 0.6;\n\n        &.active {\n            opacity: 1;\n        }\n    }\n}\n\n.nav-icon {\n    height: 32px;\n    width: 32px;\n}\n\n.no-bottom-margin {\n    margin-bottom: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
