import React from "react";

import useTransactions from "money/hooks/useTransactions";

import Trash from "foundation/svgComponents/Trash";

import { Transaction } from "money/models/types";

type TransactionColumnProps = {
    title: string;
    transactions: Transaction[];
    className?: string;
    isExpense?: boolean;
};

const TransactionColumn: React.FC<TransactionColumnProps> = ({
    title,
    transactions,
    className = "",
    isExpense,
}) => {
    const { deleteTransaction } = useTransactions(false);

    const textColorClassName = isExpense
        ? "text-theme-button-warning"
        : "text-theme-button-primary";

    return (
        <div className={className}>
            <h1>{title}</h1>
            {transactions.map((transaction, index) => (
                <div
                    key={index}
                    className="text-[20px] flex gap-[4px] w-full items-center"
                >
                    <span className={textColorClassName}>
                        ${transaction.amount}
                    </span>{" "}
                    {transaction.name}
                    <button
                        className="ml-auto"
                        onClick={() => deleteTransaction(transaction)}
                    >
                        <Trash />
                    </button>
                </div>
            ))}
        </div>
    );
};

export default TransactionColumn;
