import React, { useState } from "react";
import "foundation/styles/Tags.scss";

import XButton from "foundation/components/XButton";
import PlusButton from "foundation/components/PlusButton";
import ExpandingTextInput from "foundation/components/forms/ExpandingTextInput";

type ActiveTag = {
    content: string;
    isActive: boolean;
};

type EditActiveTagsProps = {
    tags: ActiveTag[];
    setTags: (newTags: ActiveTag[]) => void;
    className?: string;
    label?: string;
};

// TODO combine with EditTags. DRY it up.
const EditActiveTags: React.FC<EditActiveTagsProps> = ({
    tags = [],
    setTags,
    label,
    className = "",
}) => {
    const [addTagText, setAddTagText] = useState<string>("");

    const addTag = (newTagString: string) => {
        const newTag = {
            content: newTagString,
            isActive: true,
        };
        const newTags = [...tags, newTag];
        setAddTagText("");
        setTags(newTags);
    };

    const deleteTag = (indexToDelete: number) => {
        const newTags = tags.filter((tag, index) => index !== indexToDelete);
        setTags(newTags);
    };

    const toggleTagActive = (indexToToggle: number) => {
        const newTags = [...tags];
        newTags[indexToToggle].isActive = !newTags[indexToToggle].isActive;
        setTags(newTags);
    };

    return (
        <div className={`flex items-center ${className ?? ""}`}>
            {label ?? "Tags:"}
            {tags.map((tag, i) => (
                <div
                    role="button"
                    key={`${tag.content}-${i}`}
                    className={`tag ${tag.isActive ? "active" : "inactive"}`}
                    onClick={() => toggleTagActive(i)}
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.code === "Space" || e.code === "Enter") {
                            toggleTagActive(i);
                        }
                    }}
                >
                    {tag.content}
                    <XButton size="10px" onClick={() => deleteTag(i)} />
                </div>
            ))}
            <span className="tag inactive">
                <ExpandingTextInput
                    text={addTagText}
                    setText={setAddTagText}
                    className="add-tag-input"
                    placeholder="Add Tag..."
                    onEnter={addTag}
                />
                <PlusButton size="10px" onClick={() => addTag(addTagText)} />
            </span>
        </div>
    );
};

export default EditActiveTags;
