import React, { useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { arrayMoveImmutable } from "array-move";

import { Link } from "react-router-dom";
import { useGetApi } from "common/hooks/useGetApi";

import "fantasyFootball/styles/draftPrep.scss";

import Tabs from "foundation/components/Tabs";
import PlayerTable from "fantasyFootball/components/PlayerTable";

import {
    FantasyPositions,
    getEligibleFantasyPositions,
} from "fantasyFootball/helpers/position.helpers";

import { Player, DraftNote } from "fantasyFootball/models/types";

const DraftPrep: React.FC = () => {
    const { data: players, setData: setPlayers } =
        useGetApi<Player[]>("player");
    const { data: draftNotes, setData: setDraftNotes } =
        useGetApi<DraftNote[]>("draft_note");
    const [positionFilter, setPositionFilter] = useState(undefined);

    const positionFilterTabs = [
        { name: "All", onClick: () => setPositionFilter(undefined) },
        ...Object.keys(FantasyPositions).map((pos) => {
            return {
                name: pos,
                onClick: () => setPositionFilter(pos),
            };
        }),
    ];

    const unrankedPlayers: Player[] = [];
    const rankedPlayers: Player[] = [];

    if (players && draftNotes) {
        const filteredPlayers = positionFilter
            ? players.filter((p) => {
                  return (
                      p.position === positionFilter ||
                      getEligibleFantasyPositions(p.position).includes(
                          positionFilter
                      )
                  );
              })
            : players;

        filteredPlayers.forEach((player) => {
            if (
                draftNotes.find((note) => note.player === player.id.toString())
            ) {
                rankedPlayers.push(player);
            } else {
                unrankedPlayers.push(player);
            }
        });
    }

    const handleDragReorder = (dragResult: DropResult) => {
        console.log("dragResult:", dragResult);

        const source = dragResult.source.droppableId;
        const destination = dragResult.destination.droppableId;

        if (source === destination) {
            if (destination === "unranked-players") {
                setPlayers(
                    arrayMoveImmutable(
                        players,
                        dragResult.source.index,
                        dragResult.destination.index
                    )
                );
            }
        } else {
            if (destination === "ranked-players") {
                // simulate draft note creation
                const draftNote: DraftNote = {
                    player: dragResult.draggableId,
                    note: "",
                };

                setDraftNotes([...draftNotes, draftNote]);
            }
        }
    };

    return (
        <section>
            <h1>Draft Prep!</h1>
            {players && players.length === 0 && (
                <div>
                    <div>No player data</div>

                    <Link to="managePlayers">
                        <button className="btn btn-primary">Go Here</button>
                    </Link>
                </div>
            )}

            <Tabs tabs={positionFilterTabs} />
            <DragDropContext onDragEnd={handleDragReorder}>
                <section className="player-ranking-section">
                    {players && players.length > 0 && (
                        <section className="unranked-players-section">
                            <h2>Unranked Players</h2>
                            <PlayerTable
                                tableId="unranked-players"
                                players={unrankedPlayers}
                            />
                        </section>
                    )}

                    <section className="ranked-players-section">
                        {positionFilter && <h2>{positionFilter} Ranks</h2>}
                        {!positionFilter && <h2>Ranked Players</h2>}
                        <PlayerTable
                            tableId="ranked-players"
                            players={rankedPlayers}
                        />
                    </section>
                </section>
            </DragDropContext>
        </section>
    );
};

export default DraftPrep;
