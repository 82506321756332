export enum AppsEnum {
    Home,
    Cooking,
    Rememberall,
    FantasyFootball,
    Settings,
    Quotes,
    Money,
    Skills,
}
