import React from "react";
import ViewRecipeStep from "./ViewRecipeStep";
import { ComplexStep } from "cooking/models/types";
import { getOrderedSteps } from "cooking/helpers/recipe.helpers";

type ViewRecipeStepsProps = {
    steps: ComplexStep[];
};

const ViewRecipeSteps: React.FC<ViewRecipeStepsProps> = ({ steps }) => {
    const stepsExist = steps && steps.length > 0;

    const orderedSteps = getOrderedSteps(steps);

    return (
        <section className="max-w-[800px]">
            <h2 className="underline mb-[20px] text-center text-[28px]">
                Steps
            </h2>
            {stepsExist && (
                <div className="flex flex-col gap-[20px]">
                    {orderedSteps.map((step) => (
                        <ViewRecipeStep key={step.id} step={step} />
                    ))}
                </div>
            )}
            {!stepsExist && <p className="text-center">No Steps!</p>}
        </section>
    );
};

export default ViewRecipeSteps;
