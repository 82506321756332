import React from "react";
import Checkbox from "foundation/components/forms/Checkbox";
import discreteStore from "money/stores/DiscreteStore";

const DiscreteModeToggle: React.FC = () => {
    const discreteModeOn = discreteStore.useTracked.discreteModeOn();
    return (
        <div>
            <Checkbox
                text="Discrete Mode"
                value={discreteModeOn}
                onClick={() =>
                    discreteStore.set.discreteModeOn(!discreteModeOn)
                }
            />
        </div>
    );
};

export default DiscreteModeToggle;
