import React, { useState, useMemo, useCallback } from "react";
import Xarrow from "react-xarrows";
import uuid from "react-uuid";
import { cloneDeep } from "lodash";

import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";
import useMousePosition from "common/hooks/useMousePosition";
import useCreateRecipe from "cooking/hooks/useCreateRecipe";

import ComplexStepBox from "cooking/components/ComplexStepBox";
import PlusButton from "foundation/components/PlusButton";
import Trash from "foundation/svgComponents/Trash";
import ArrowWithLine from "foundation/svgComponents/ArrowWithLine";
import ArrowWithStrikethrough from "foundation/svgComponents/ArrowWithStrikethrough";

import { ComplexStep } from "cooking/models/types";

// Extracted into a constant outside the component
export const EMPTY_STEP: ComplexStep = {
    id: -1,
    type: "single",
    ingredients: [],
    order: 1,
};

// Types to improve readability
type StepNode = {
    type: "step";
    step: ComplexStep;
    node: React.ReactNode;
};

type ArrowConnection = {
    startId: string;
    endId: string;
};

type ComplexStepsProps = {
    steps: ComplexStep[];
    setSteps: (steps: ComplexStep[]) => void;
};

const ComplexSteps: React.FC<ComplexStepsProps> = ({
    steps: initialSteps,
    setSteps,
}) => {
    // Ensure we have at least one step
    const steps = useMemo(() => {
        return (initialSteps?.length ?? 0) === 0
            ? [{ ...EMPTY_STEP }]
            : initialSteps;
    }, [initialSteps]);

    const [addDepStep, setAddDepStep] = useState<ComplexStep | undefined>();
    const isDependencySelectionMode = !!addDepStep;

    const { isIngredientLinkActive } = useCreateRecipe(false);
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    const {
        mousePosition: { x: mouseX, y: mouseY },
        activate: activateMouseTracking,
        deactivate: deactivateMouseTracking,
    } = useMousePosition(true);

    // Generate a stable unique ID for arrows
    const arrowUuid = useMemo(() => uuid(), []);

    // Helper function to consistently generate arrow IDs
    const getArrowId = useCallback(
        (stepId: number, topBottom: "top" | "bottom") => {
            return `${stepId.toString()}_${topBottom}_${arrowUuid}`;
        },
        [arrowUuid]
    );

    // Update a specific field of a step
    const updateStepField = useCallback(
        (stepId: number, field: string, newValue: any) => {
            // Get the current steps
            const currentSteps = [...steps];

            // Find the step to update
            const stepIndex = currentSteps.findIndex((s) => s.id === stepId);
            if (stepIndex === -1) return;

            const step = currentSteps[stepIndex];
            if (step[field] === newValue) return;

            // Update the step
            currentSteps[stepIndex] = { ...step, [field]: newValue };

            // Pass the new array directly to setSteps
            setSteps(currentSteps);
        },
        [steps, setSteps]
    );

    // Add a new step to the recipe
    const addNewStep = useCallback(
        (level: number, dependsOnId?: number) => {
            const currentSteps = [...steps];

            const newStepId =
                currentSteps.reduce(
                    (minId, step) => Math.min(minId, step.id),
                    -1
                ) - 1;

            const order =
                currentSteps.reduce(
                    (minOrder, step) => Math.max(minOrder, step.order),
                    -1
                ) + 1;

            const newStep: ComplexStep = {
                ...EMPTY_STEP,
                id: newStepId,
                order,
            };

            const updatedSteps = [...currentSteps];

            if (dependsOnId) {
                newStep.dependsOn = [dependsOnId];

                const dependsOnStepIndex = updatedSteps.findIndex(
                    (s) => s.id === dependsOnId
                );
                if (dependsOnStepIndex >= 0) {
                    const dependsOnStep = updatedSteps[dependsOnStepIndex];
                    const dependencies = dependsOnStep.dependencies || [];
                    updatedSteps[dependsOnStepIndex] = {
                        ...dependsOnStep,
                        dependencies: [...dependencies, newStepId],
                    };
                }
            }
            setSteps([...updatedSteps, newStep]);
        },
        [steps, setSteps]
    );

    // Delete a step and update dependencies
    const deleteStep = useCallback(
        (step: ComplexStep) => {
            const currentSteps = [...steps];
            const { id, dependsOn, dependencies } = step;
            const updatedSteps = cloneDeep(currentSteps).filter(
                (s) => s.id !== id
            );

            // Remove this step from the dependencies array of its parent
            dependsOn?.forEach((parentStepId) => {
                const parentStepIndex = updatedSteps.findIndex(
                    (s) => s.id === parentStepId
                );
                if (parentStepIndex >= 0) {
                    const parentStep = updatedSteps[parentStepIndex];
                    const updatedDependencies =
                        parentStep.dependencies?.filter((d) => d !== id) || [];
                    updatedSteps[parentStepIndex] = {
                        ...parentStep,
                        dependencies: updatedDependencies,
                    };
                }
            });

            // Remove this step from the dependsOn array of its children
            dependencies?.forEach((childStepId) => {
                const childStepIndex = updatedSteps.findIndex(
                    (s) => s.id === childStepId
                );
                if (childStepIndex >= 0) {
                    const childStep = updatedSteps[childStepIndex];
                    const updatedDependsOn =
                        childStep.dependsOn?.filter((d) => d !== id) || [];
                    updatedSteps[childStepIndex] = {
                        ...childStep,
                        dependsOn: updatedDependsOn,
                    };
                }
            });
            setSteps(updatedSteps);
        },
        [steps, setSteps]
    );

    // Start adding a dependency
    const handleAddDependencyFirstClick = useCallback(
        (step: ComplexStep) => {
            activateMouseTracking();
            setAddDepStep(step);
        },
        [activateMouseTracking]
    );

    // Complete adding a dependency
    const handleAddDependencySecondClick = useCallback(
        (dependencyStep: ComplexStep) => {
            if (!addDepStep) return;

            // Prevent circular dependencies
            if (addDepStep.id === dependencyStep.id) {
                deactivateMouseTracking();
                setAddDepStep(undefined);
                return;
            }

            // Check if this dependency already exists
            const existingDeps = addDepStep.dependencies || [];
            if (existingDeps.includes(dependencyStep.id)) {
                deactivateMouseTracking();
                setAddDepStep(undefined);
                return;
            }

            // First, create an updated version of the step with the new dependency
            const updatedSteps = [...steps];

            // Find and update the source step
            const sourceIndex = updatedSteps.findIndex(
                (s) => s.id === addDepStep.id
            );
            if (sourceIndex >= 0) {
                const sourceStep = updatedSteps[sourceIndex];
                updatedSteps[sourceIndex] = {
                    ...sourceStep,
                    dependencies: [
                        ...(sourceStep.dependencies || []),
                        dependencyStep.id,
                    ],
                };
            }

            // Find and update the target step
            const targetIndex = updatedSteps.findIndex(
                (s) => s.id === dependencyStep.id
            );
            if (targetIndex >= 0) {
                const targetStep = updatedSteps[targetIndex];
                updatedSteps[targetIndex] = {
                    ...targetStep,
                    dependsOn: [...(targetStep.dependsOn || []), addDepStep.id],
                };
            }

            // Update the graph
            setSteps(updatedSteps);

            // Clean up
            deactivateMouseTracking();
            setAddDepStep(undefined);
        },
        [addDepStep, steps, deactivateMouseTracking, setSteps]
    );

    // Remove all dependencies for a step
    const handleRemoveDependencies = useCallback(
        (step: ComplexStep) => {
            updateStepField(step.id, "dependencies", []);
        },
        [updateStepField]
    );

    // Calculate the dependency graph structure
    const { nodesByLevel, arrows } = useMemo(() => {
        // Create a map of step ID to step for quicker lookups
        const stepsMap = new Map(steps.map((step) => [step.id, step]));

        // Find root steps (those without dependsOn)
        const rootSteps = steps.filter(
            (step) => (step.dependsOn?.length ?? 0) === 0
        );

        const result = {
            nodesByLevel: [] as StepNode[][],
            arrows: [] as ArrowConnection[],
        };

        // If there are no steps, return empty results
        if (steps.length === 0) return result;

        // Initialize with empty arrays for each potential level
        // This ensures we have space for all potential levels
        for (let i = 0; i < steps.length; i++) {
            result.nodesByLevel[i] = [];
        }

        // Keep track of which steps have been placed
        const placedSteps = new Set<number>();

        // Place root steps at level 0
        rootSteps.forEach((step) => {
            result.nodesByLevel[0].push({
                type: "step",
                step,
                node: (
                    <ComplexStepBox
                        key={step.id}
                        id={`${step.id}_${arrowUuid}`}
                        step={step}
                        updateStepField={(field, newValue) =>
                            updateStepField(step.id, field, newValue)
                        }
                    />
                ),
            });
            placedSteps.add(step.id);
        });

        // Helper function to get maximum depth of a step
        const getMaxDepth = (stepId: number): number => {
            const step = stepsMap.get(stepId);
            if (!step) return 0;

            // If this step has no dependencies, it's at depth 0
            if (!step.dependsOn || step.dependsOn.length === 0) return 0;

            // Otherwise, it's 1 + the max depth of any parent
            return (
                1 +
                Math.max(
                    ...step.dependsOn.map((parentId) => getMaxDepth(parentId))
                )
            );
        };

        // Calculate appropriate level (depth) for each non-root step
        const remainingSteps = steps.filter(
            (step) => !placedSteps.has(step.id)
        );

        remainingSteps.forEach((step) => {
            // If this step has no parents, it should be at level 0 (root)
            // This could happen if dependsOn is an empty array instead of undefined
            if (!step.dependsOn || step.dependsOn.length === 0) {
                result.nodesByLevel[0].push({
                    type: "step",
                    step,
                    node: (
                        <ComplexStepBox
                            key={step.id}
                            id={`${step.id}_${arrowUuid}`}
                            step={step}
                            updateStepField={(field, newValue) =>
                                updateStepField(step.id, field, newValue)
                            }
                        />
                    ),
                });
            } else {
                // Calculate the appropriate level based on dependencies
                const depth = getMaxDepth(step.id);

                result.nodesByLevel[depth].push({
                    type: "step",
                    step,
                    node: (
                        <ComplexStepBox
                            key={step.id}
                            id={`${step.id}_${arrowUuid}`}
                            step={step}
                            updateStepField={(field, newValue) =>
                                updateStepField(step.id, field, newValue)
                            }
                        />
                    ),
                });
            }

            placedSteps.add(step.id);
        });

        // Create arrows for all dependencies
        steps.forEach((step) => {
            if (step.dependencies && step.dependencies.length > 0) {
                step.dependencies.forEach((depId) => {
                    result.arrows.push({
                        startId: getArrowId(step.id, "bottom"),
                        endId: getArrowId(depId, "top"),
                    });
                });
            }
        });

        // Remove empty levels
        result.nodesByLevel = result.nodesByLevel.filter(
            (level) => level.length > 0
        );

        return result;
    }, [steps, arrowUuid, getArrowId, updateStepField]);

    // Render a step node with its action buttons
    const renderStepNode = useCallback(
        (node: StepNode, level: number) => {
            const { step } = node;

            // Determine if this step can have dependencies added
            const connectedSteps =
                1 + // self
                (step.dependsOn?.length ?? 0) +
                (step.dependencies?.length ?? 0);

            const canAddDependency =
                steps.length > 1 && connectedSteps < steps.length;
            const hasDependencies = step.dependencies?.length > 0;

            return (
                <div
                    className="flex flex-col items-center z-10 relative h-fit"
                    key={step.id}
                    data-testid={`step box with buttons ${step.id}`}
                    onClick={
                        isDependencySelectionMode
                            ? () => handleAddDependencySecondClick(step)
                            : undefined
                    }
                >
                    {node.node}
                    <div
                        className={`border-theme-border border-[1px] border-t-0 border-solid rounded-b-md px-2 py-1 bg-theme-background flex items-center justify-center gap-2 ${isIngredientLinkActive
                                ? "pointer-events-none opacity-20"
                                : ""
                            }`}
                    >
                        <PlusButton
                            title="Add dependent step"
                            size="15px"
                            onClick={() => addNewStep(level + 1, step.id)}
                        />
                        {canAddDependency && (
                            <button
                                title="Add dependency"
                                type="button"
                                onClick={() =>
                                    handleAddDependencyFirstClick(step)
                                }
                            >
                                <ArrowWithLine size="15px" />
                            </button>
                        )}
                        {hasDependencies && (
                            <button
                                title="Clear dependencies"
                                type="button"
                                onClick={() => handleRemoveDependencies(step)}
                            >
                                <ArrowWithStrikethrough size="15px" />
                            </button>
                        )}
                        <button
                            title="Delete Step"
                            type="button"
                            onClick={() => deleteStep(step)}
                        >
                            <Trash size="15px" />
                        </button>
                    </div>
                    <div
                        id={getArrowId(step.id, "top")}
                        className="absolute top-0"
                    />
                    <div
                        id={getArrowId(step.id, "bottom")}
                        className="absolute bottom-0"
                    />
                </div>
            );
        },
        [
            steps.length,
            isDependencySelectionMode,
            isIngredientLinkActive,
            handleAddDependencySecondClick,
            addNewStep,
            handleAddDependencyFirstClick,
            handleRemoveDependencies,
            deleteStep,
            getArrowId,
        ]
    );

    return (
        <section className="flex gap-3 mt-0">
            <div className="flex flex-col items-center gap-12 grow">
                {nodesByLevel.map((nodes, level) => (
                    <section
                        key={level}
                        className="flex gap-4 w-full justify-evenly"
                        data-testid={`level ${level}`}
                    >
                        {nodes.map((node) => renderStepNode(node, level))}
                    </section>
                ))}
            </div>

            {/* Add independent step button */}
            <PlusButton
                className="text-[12px] h-fit w-fit flex flex-col items-center justify-center gap-2 opacity-80 border-theme-border border-[1px] border-solid rounded-md p-2"
                onClick={() => addNewStep(0)}
                size="30px"
                title="Add independent step"
            />

            {/* Render all arrows between steps */}
            {arrows.map((arrow, index) => (
                <Xarrow
                    key={index}
                    start={arrow.startId}
                    end={arrow.endId}
                    color={color}
                    strokeWidth={3}
                    path="straight"
                />
            ))}

            {/* Hidden div to track mouse position for creating dependencies */}
            <div
                id="mouse-arrow"
                className="fixed"
                style={{ top: mouseY, left: mouseX }}
            />

            {/* Arrow from selected step to mouse when adding dependency */}
            {addDepStep && (
                <Xarrow
                    start={getArrowId(addDepStep.id, "bottom")}
                    end="mouse-arrow"
                    color={color}
                    strokeWidth={3}
                />
            )}
        </section>
    );
};

export default ComplexSteps;
