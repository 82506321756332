import React, { useState } from "react";
import { cloneDeep } from "lodash";
import api from "helpers/api.helpers";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";

import appStore from "foundation/stores/AppStore";

import { AVAILABLE_THEMES } from "settings/Themes";
import { AppsEnum } from "foundation/apps";

import ActivityLinks from "foundation/components/ActivityLinks";
import SelectInput from "foundation/components/forms/SelectInput";
import RecipeCards from "cooking/components/RecipeCards";
import TextInput from "foundation/components/forms/TextInput";
import ButtonGroupChoiceToggle from "foundation/components/forms/ButtonGroupChoiceToggle";
import { Choices } from "foundation/components/forms/types";

import { getSelectedThemeKey } from "helpers/settings.helpers";
import { Activities } from "foundation/helpers/Activities";
import { generateFakeRecipe } from "foundation/helpers/testing.helpers";

const SettingsHome: React.FC = () => {
    const { currentUser, setCurrentUser } = useCurrentUser();
    const [buttonToggleValue, setButtonToggleValue] = useState("1");

    appStore.set.currentApp(AppsEnum.Settings);

    const handleThemeSelect = (theme: AVAILABLE_THEMES) => {
        const userClone = cloneDeep(currentUser);
        if (!userClone.settings) {
            userClone.settings = {};
        }
        userClone.settings.selectedTheme = theme;

        setCurrentUser(userClone);

        api.put("user/update/", userClone).catch((err) =>
            console.error("Error Setting Selected Theme:", err)
        );
    };

    const themeChoices: Choices = Object.keys(AVAILABLE_THEMES).map((key) => [
        key,
        AVAILABLE_THEMES[key],
    ]);

    const currentThemeKey = getSelectedThemeKey(currentUser);

    const r1 = generateFakeRecipe(-1);
    const r2 = generateFakeRecipe(-2);
    const r3 = generateFakeRecipe(-3);

    return (
        <>
            <section>
                <h1>Settings</h1>
                <SelectInput
                    name="theme"
                    displayLabel="Theme"
                    selectedValue={currentThemeKey}
                    choices={themeChoices}
                    onChange={handleThemeSelect}
                />
            </section>
            <section id="sample-components">
                <h2>Inputs</h2>
                <TextInput
                    name="Testing"
                    displayLabel="Testing"
                    isUncontrolledInput
                />

                <ButtonGroupChoiceToggle
                    name="test"
                    selectedValue={buttonToggleValue}
                    choices={[
                        ["1", "one"],
                        ["2", "two"],
                        ["3", "three"],
                    ]}
                    handleChange={setButtonToggleValue}
                    className="mt-3"
                />

                <br />
                <br />

                <h2>Buttons</h2>
                <button
                    className="btn btn-primary"
                    style={{ marginRight: "10px" }}
                >
                    Primary
                </button>
                <button
                    className="btn btn-warning"
                    style={{ marginRight: "10px" }}
                >
                    Warning
                </button>
                <button className="btn btn-danger">Danger</button>

                <br />
                <br />

                <h2>Recipe stuff</h2>
                <RecipeCards recipes={[r1, r2, r3]} />
                <ActivityLinks
                    sectionTitle="Sample Home"
                    activities={[
                        Activities.cooking,
                        Activities.rememberall,
                        Activities.settings,
                    ]}
                />
            </section>
        </>
    );
};

export default SettingsHome;
