import React from "react";
import { Recipe } from "cooking/models/types";
import RecipeCard from "cooking/components/RecipeCard";

type RecipeCardsProps = {
  recipes: Recipe[];
  showButtons?: boolean;
  onClick?: (recipe: Recipe) => void;
};

const RecipeCards: React.FC<RecipeCardsProps> = ({
  recipes,
  showButtons = true,
  onClick,
}) => {
  return (
    <div className="recipe-card-container">
      {recipes.map((recipe) => (
        <RecipeCard
          key={recipe.id}
          recipe={recipe}
          showButtons={showButtons}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

export default RecipeCards;
