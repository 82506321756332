import React from "react";

type BulletPointSingleProps = {
    className?: string;
    size?: string;
};

const BulletPointSingle: React.FC<BulletPointSingleProps> = ({
    className = "",
    size = "20px",
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 100 100"
            className={className}
        >
            <circle cx="8" cy="50" r="8" fill="black" />
            <line
                x1="32"
                y1="50"
                x2="90"
                y2="50"
                stroke="black"
                strokeWidth="12"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default BulletPointSingle;
