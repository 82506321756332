import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type MeasuringCupProps = {
    className?: string;
    quantity?: string;
    size?: string;
    maxScaleFactor?: number;
};

const MeasuringCup: React.FC<MeasuringCupProps> = ({
    className = "",
    quantity,
    size = "30px",
    maxScaleFactor = 1.5,
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    // Calculate scaling factor based on quantity length
    const getScaleFactor = () => {
        if (!quantity) return 1;

        if (quantity.length >= 5) return Math.min(1.5, maxScaleFactor); // For "2 1/2" or "2 to 3"
        if (quantity.length >= 3) return Math.min(1.25, maxScaleFactor); // For "2-3"
        return 1; // For single digits
    };

    const scaleFactor = getScaleFactor();

    // Parse size to get numerical value and unit
    const sizeMatch = size.match(/^(\d+(?:\.\d+)?)(.*)$/);
    const sizeValue = sizeMatch ? parseFloat(sizeMatch[1]) : 30;
    const sizeUnit = sizeMatch ? sizeMatch[2] : "px";

    // Calculate scaled width
    const scaledWidth = `${sizeValue * scaleFactor}${sizeUnit}`;

    // Font size adjustments
    const getFontSize = () => {
        if (!quantity) return 400;

        if (quantity.length >= 5) return 280; // Increase from 200 to make it more readable
        if (quantity.length >= 3) return 350; // Increase from 300
        return 400;
    };

    // Text positioning - with scaled SVG, we need to adjust
    const getXPosition = () => {
        if (!quantity) return 222;

        // Adjust position based on quantity length and scale factor
        if (quantity.length >= 5) return 180;
        if (quantity.length >= 3) return 200;
        return 222;
    };

    // Dynamic text length - make it proportional to the scaled width
    const getTextLength = () => {
        if (!quantity) return 250;

        if (quantity.length >= 5) return 330;
        if (quantity.length >= 3) return 290;
        return 250;
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={scaledWidth}
            height={size}
            version="1.1"
            viewBox="0 20 700 530"
            fill={color}
            className={className}
        >
            <path d="m685.5 37.297c-10.043-10.711-24.246-16.855-38.961-16.855h-629.22c-5.9805 0-11.543 3.0859-14.707 8.1602-3.1562 5.0938-3.4766 11.438-0.83984 16.812l57.703 117.3 51.395 370.07c1.1914 8.5781 8.5156 14.949 17.164 14.949h370.27c8.6484 0 15.977-6.3711 17.164-14.949l56.305-405.37 17.875-0.039062-13.27 212.7c-0.90234 14.258 3.8047 28.008 13.254 38.719 9.4336 10.695 22.484 17.078 36.602 17.965 29.945 2.0078 54.949-20.734 56.816-50.004l16.84-269.51c0.92578-14.734-4.3477-29.312-14.387-39.949zm-37.031 307.28c-0.62109 9.8633-8.8125 17.602-19.855 17.602-0.03125 0-0.046876 0-0.070313-0.015626-5.0234-0.3125-9.6055-2.543-12.926-6.3086-3.3203-3.7656-4.9688-8.5938-4.6445-13.602l14.422-231.17c0.30469-4.7852-1.4062-9.4727-4.6836-12.965-3.2812-3.4609-7.8398-5.4453-12.617-5.4453h-0.046875l-51.395 0.13281c-8.6328 0.023437-15.938 6.3867-17.125 14.941l-56.305 405.34h-340.11l-19.77-142.36h88.035c9.5664 0 17.328-7.7539 17.328-17.328s-7.7539-17.336-17.328-17.336l-92.84-0.003906-8.6641-62.371h87.648c9.5664 0 17.328-7.7617 17.328-17.328 0-9.5664-7.7617-17.328-17.328-17.328h-92.473l-11.645-83.844c-0.25781-1.8281-0.80078-3.6016-1.6094-5.2656l-46.645-94.82h601.39c5.1719 0 10.172 2.1484 13.727 5.957 3.5234 3.7266 5.3516 8.8438 5.0234 14.023z" />
            <text
                textLength={getTextLength()}
                fontSize={getFontSize()}
                lengthAdjust="spacingAndGlyphs"
                x={getXPosition()}
                y="400"
            >
                {quantity}
            </text>
        </svg>
    );
};

export default MeasuringCup;
