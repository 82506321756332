import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type JournalIconProps = {
    className?: string;
    color?: string;
};

const JournalIcon: React.FC<JournalIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                fill={color}
                className={className}
            >
                <path d="M76,51.5c1.3,0,2.3-1,2.3-2.3V36.4c0-1.3-1-2.3-2.3-2.3h-3.1v-3.7L72.8,29c-0.3-4.8-3.2-8.6-7.5-8.6H29.4  c-4.3,0-6.9,3.4-7.5,8.6l-0.1,1.4v42c0,4,3.3,7.2,7.2,7.2h1.4V23.2h-1.4c-2,0-3.9,0.8-5.2,2.2c1.1-2.2,3.2-4,5.6-4h35.8  c2.4,0,4.5,1.8,5.6,4c-1.3-1.4-3.1-2.2-5.2-2.2h-2v15.5l-4.5-4.5l-4.6,4.6V23.2H34.3v56.5h31.3c4,0,7.2-3.3,7.2-7.2v-0.9H76  c1.3,0,2.3-1,2.3-2.3V56.4c0-1.3-1-2.3-2.3-2.3h-3.1v-2.6H76z M72.9,35.1H76c0.7,0,1.3,0.6,1.3,1.3v12.8c0,0.7-0.6,1.3-1.3,1.3h-3.1  V35.1z M76,55.1c0.7,0,1.3,0.6,1.3,1.3v12.8c0,0.7-0.6,1.3-1.3,1.3h-3.1V55.1H76z" />
            </svg>
        </>
    );
};

export default JournalIcon;
