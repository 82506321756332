import React from "react";
import { useParams } from "react-router-dom";
import { useGetApi } from "common/hooks/useGetApi";

import ViewRecipeIngredients from "./ViewRecipeIngredients";
import ViewRecipeSteps from "./ViewRecipeSteps";
import ViewRecipeHeader from "./ViewRecipeHeader";

import { Recipe } from "cooking/models/types";

const ViewRecipe: React.FC = () => {
    const { recipeId } = useParams();

    const { data: recipe } = useGetApi<Recipe>(`recipe/${recipeId}`);

    if (!recipe) {
        return <h1>Loading...</h1>;
    }

    /* console.log("🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆");
    console.log("recipe:", recipe);
    console.log("recipe.steps:", recipe.steps); */

    return (
        <div className="flex flex-col gap-[42px] mx-auto">
            <ViewRecipeHeader recipe={recipe} />
            <ViewRecipeIngredients
                ingredients={recipe.ingredients}
                showHeader
            />
            <ViewRecipeSteps steps={recipe.steps} />
        </div>
    );
};

export default ViewRecipe;
