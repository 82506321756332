// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nfl-teams-table tr {
  vertical-align: baseline; }
  .nfl-teams-table tr td, .nfl-teams-table tr th {
    padding-right: 15px; }

.edit-button {
  margin: 20px 0; }
`, "",{"version":3,"sources":["webpack://./src/fantasyFootball/styles/manageNflTeams.scss"],"names":[],"mappings":"AAAA;EAEQ,wBAAwB,EAAA;EAFhC;IAKY,mBAAmB,EAAA;;AAK/B;EACI,cAAc,EAAA","sourcesContent":[".nfl-teams-table {\n    tr {\n        vertical-align: baseline;\n\n        td, th {\n            padding-right: 15px;\n        }\n    }\n}\n\n.edit-button {\n    margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
