import React from "react";

import { Ingredient } from "cooking/models/types";

type IngredientCardProps = {
    ingredient: Ingredient;
    editIngredient?: (ingredient: Ingredient) => void;
    deleteIngredient?: (ingredient: Ingredient) => void;
};

const IngredientCard: React.FC<IngredientCardProps> = ({
    ingredient,
    editIngredient,
    deleteIngredient,
}) => {
    return (
        <div className="flex flex-col min-h-[100px] min-w-[150px] m-[8px] border-[2px] border-solid border-theme-border rounded-[10px]">
            <div className="grow text-center p-[5px] text-[18px] font-[500]">
                {ingredient.name}
            </div>
            <div className="flex gap-[5px] justify-center border-t-[2px] border-solid border-theme-border p-[5px]">
                {editIngredient && (
                    <button
                        className="btn btn-primary"
                        onClick={() => editIngredient(ingredient)}
                    >
                        Edit
                    </button>
                )}
                {deleteIngredient && (
                    <button
                        className="btn btn-danger"
                        onClick={() => deleteIngredient(ingredient)}
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>
    );
};

export default IngredientCard;
