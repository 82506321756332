import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "helpers/api.helpers";

const isOffline = process.env.OFFLINE_MODE;

// for some reason this doesn't seem to work in offline mode
//   calling api.get in a useEffect works just fine though 🤷
export function useGetApi<T>(
    getUrl: string,
    options: {
        postprocess?: (input: T) => T;
        skip?: boolean;
        disableAllRefetch?: boolean;
        refetchOnWindowFocus?: boolean;
        refetchOnReconnect?: boolean;
        refetchOnMount?: boolean;
    } = {}
) {
    const queryClient = useQueryClient();
    const {
        postprocess,
        skip = false,
        disableAllRefetch = true,
        refetchOnWindowFocus = true,
        refetchOnReconnect = true,
        refetchOnMount = true,
    } = options;

    const queryKey = [getUrl, postprocess];
    const { data, isLoading, refetch, isRefetching } = useQuery<T>(
        queryKey,
        async () => {
            return await api
                .get(getUrl)
                .then((res) => {
                    if (!res) return null;

                    const { data: gottenData } = res;
                    return postprocess ? postprocess(gottenData) : gottenData;
                })
                .catch((err) => console.log(err));
        },
        {
            enabled: !skip && !isOffline,
            refetchOnWindowFocus: refetchOnWindowFocus && !disableAllRefetch,
            refetchOnReconnect: refetchOnReconnect && !disableAllRefetch,
            refetchOnMount: refetchOnMount && !disableAllRefetch,
        }
    );

    const setData = (data: any) => {
        queryClient.setQueryData(queryKey, data);
    };

    const appendData = (
        dataElement: T,
        preferArray: boolean = false,
        prepend: boolean = false
    ) => {
        if (Array.isArray(data)) {
            if (prepend) {
                setData([dataElement, ...data]);
            } else {
                setData([...data, dataElement]);
            }
        } else if (data === undefined && preferArray) {
            setData([dataElement]);
        } else {
            setData(dataElement);
        }
    };

    const deleteData = (dataElement: T) => {
        if (Array.isArray(data)) {
            // @ts-ignore
            setData(data?.filter((d) => d.id !== dataElement.id));
        } else {
            setData(undefined);
        }
    };

    // offline shenanigans
    const [refetchTrigger, setRefetchTrigger] = useState(false);
    const offlineRefetch = () => setRefetchTrigger(!refetchTrigger);

    const [offlineData, setOfflineData] = useState<T>();

    useEffect(() => {
        if (isOffline) {
            api.get(getUrl).then((res) => setOfflineData(res?.data));
        }
    }, [refetchTrigger, getUrl]);

    return {
        data: isOffline ? offlineData : data,
        refetch: isOffline ? offlineRefetch : refetch,
        isLoading: isOffline ? false : isLoading, // isLoading is always true offline
        isRefetching: isOffline ? false : isRefetching,
        setData,
        appendData,
        deleteData,
    };
}

export default useGetApi;
