// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recipe-card-container {
  display: flex;
  flex-wrap: wrap; }

.recipe-card {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  border-radius: 10px;
  min-height: 100px;
  min-width: 150px;
  margin: 8px; }
  .recipe-card .recipe-card-primary {
    flex-grow: 1;
    text-align: center;
    padding: 5px;
    font-size: 18px;
    font-weight: 500; }
  .recipe-card .recipe-card-buttons {
    display: flex;
    justify-content: center;
    border-top: 2px black solid; }
    .recipe-card .recipe-card-buttons button {
      margin: 5px; }
  .recipe-card.clickable {
    cursor: pointer; }
  .recipe-card.no-buttons {
    min-height: unset; }
`, "",{"version":3,"sources":["webpack://./src/cooking/styles/recipeCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe,EAAA;;AAGjB;EACE,aAAa;EACb,sBAAsB;EAEtB,uBAAuB;EACvB,mBAAmB;EAEnB,iBAAiB;EACjB,gBAAgB;EAEhB,WAAW,EAAA;EAVb;IAaI,YAAY;IACZ,kBAAkB;IAClB,YAAY;IAEZ,eAAe;IACf,gBAAgB,EAAA;EAlBpB;IAsBI,aAAa;IACb,uBAAuB;IAEvB,2BAA2B,EAAA;IAzB/B;MA4BM,WAAW,EAAA;EA5BjB;IAiCI,eAAe,EAAA;EAjCnB;IAqCI,iBAAiB,EAAA","sourcesContent":[".recipe-card-container {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.recipe-card {\n  display: flex;\n  flex-direction: column;\n\n  border: 2px solid black;\n  border-radius: 10px;\n\n  min-height: 100px;\n  min-width: 150px;\n\n  margin: 8px;\n\n  .recipe-card-primary {\n    flex-grow: 1;\n    text-align: center;\n    padding: 5px;\n\n    font-size: 18px;\n    font-weight: 500;\n  }\n\n  .recipe-card-buttons {\n    display: flex;\n    justify-content: center;\n\n    border-top: 2px black solid;\n\n    button {\n      margin: 5px;\n    }\n  }\n\n  &.clickable {\n    cursor: pointer;\n  }\n\n  &.no-buttons {\n    min-height: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
