import React from "react";
import { Link } from "react-router-dom";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";

import { AppsEnum } from "foundation/apps";
import appStore from "foundation/stores/AppStore";

const FantasyFootballHome: React.FC = () => {
    appStore.set.currentApp(AppsEnum.FantasyFootball);

    const { currentUser } = useCurrentUser();

    return (
        <section>
            <h1>Fantasy Football!</h1>

            <h2 className="mt-4 mb-0">Actually useful / working</h2>
            <section>
                <Link to="ppg">
                    <button className="btn btn-primary">Player PPG</button>
                </Link>
            </section>

            <h2 className="mt-5 mb-1">Under dev</h2>
            <section>
                <Link to="manage/fantasyLeagues">
                    <button className="btn btn-primary">
                        Manage Fantasy Leagues
                    </button>
                </Link>

                <Link to="manage/fantasyTeams">
                    <button className="btn btn-primary">
                        Manage Fantasy Teams
                    </button>
                </Link>
            </section>

            <section>
                <Link to="draftPrep">
                    <button className="btn btn-primary">Draft Prep</button>
                </Link>

                <Link to="ppg">
                    <button className="btn btn-primary">Player PPG</button>
                </Link>
            </section>

            {currentUser?.isStaff && (
                <section>
                    <Link to="manage/nflPlayers">
                        <button className="btn btn-primary">
                            Manage Players
                        </button>
                    </Link>
                    <Link to="manage/stats">
                        <button className="btn btn-primary">
                            Manage Stats
                        </button>
                    </Link>
                </section>
            )}
        </section>
    );
};

export default FantasyFootballHome;
