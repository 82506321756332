import React from "react";
import { Link } from "react-router-dom";
import useModal from "foundation/modals/useModal";
import { useApi } from "common/hooks/useApi";
import { useGetApi } from "common/hooks/useGetApi";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";

import { Skill, TrackedSkill } from "skills/models/types";
import CreateSkillModal from "./CreateSkillModal";
import SelectSkillList from "./SelectSkillList";

const TrackSkillsPage: React.FC = () => {
    const { newModal } = useModal();
    const { currentUser } = useCurrentUser();

    const { data: allSkills, isLoading } = useGetApi<Skill[]>("skill");
    const {
        data: trackedSkills,
        createItem: createTrackedSkill,
        deleteById,
    } = useApi<TrackedSkill>("tracked_skill");

    const untrackedSkills = trackedSkills
        ? allSkills?.filter(
            (s) => !trackedSkills?.find((ts) => ts.skill.id === s.id)
        )
        : allSkills;

    const untrackedSkillsExist = untrackedSkills && untrackedSkills?.length > 0;
    const trackedSkillsExist = trackedSkills && trackedSkills?.length > 0;

    const trackSkill = (skill: Skill) => {
        const newTrackedSkill = { user: currentUser?.id, skill };

        createTrackedSkill(newTrackedSkill);
    };

    const untrackSkill = (skill: Skill) => {
        // gotta get the TrackedSkill id associated with this skill (should only ever be one)
        const idsToDelete = trackedSkills
            .filter((t) => t.skill.id === skill.id)
            .map((t) => t.id);

        idsToDelete.forEach((id) => deleteById(id));
    };

    return (
        <div className="flex flex-col gap-[40px]">
            <h1>Track yo skillz, yo!</h1>

            {isLoading && <div>Loading...</div>}

            <div className="flex flex-col gap-[30px]">
                <div className="flex flex-col gap-[20px]">
                    <h2 className="underline">Tracked Skills</h2>
                    {!trackedSkillsExist && (
                        <div className="text-[22px]">No tracked skills</div>
                    )}

                    {trackedSkillsExist && (
                        <SelectSkillList
                            skills={trackedSkills.map((t) => t.skill)}
                            onSkillClick={(skill) => untrackSkill(skill)}
                        />
                    )}
                </div>
                {untrackedSkillsExist && (
                    <div className="flex flex-col gap-[20px]">
                        <h2 className="underline">Untracked Skills</h2>
                        {untrackedSkillsExist && (
                            <SelectSkillList
                                skills={untrackedSkills}
                                onSkillClick={(skill) => trackSkill(skill)}
                            />
                        )}
                    </div>
                )}
                <button
                    className="btn btn-primary w-fit"
                    onClick={() => newModal(<CreateSkillModal />)}
                >
                    New Skill
                </button>

                <Link
                    to="/skills"
                    role="button"
                    className="btn btn-primary w-fit"
                >
                    Go Home
                </Link>
            </div>
        </div>
    );
};

export default TrackSkillsPage;
