import { useGetApi } from "common/hooks/useGetApi";
import api from "helpers/api.helpers";

import { Transaction } from "money/models/types";
import filterStore from "money/stores/FilterStore";

const useTransactions = (retrieve: boolean = true) => {
    const {
        data: transactions,
        setData: updateCache,
        isLoading,
    } = useGetApi<Transaction[]>("transaction/", { skip: !retrieve });

    /* 🥅 Filter 🥅 */

    const userTagFilters = filterStore.useTracked.peopleFilterStrings();

    const expenses: Transaction[] = [];
    const income: Transaction[] = [];
    const filteredExpenses: Transaction[] = [];
    const filteredIncome: Transaction[] = [];

    transactions?.forEach((transaction) => {
        let isInFilter = false;
        const activeTagsForTransaction: string[] = transaction.people
            .filter((person) => person.isActive)
            .map((personTag) => personTag.content);

        const overlappingTags = activeTagsForTransaction.filter((t) =>
            userTagFilters?.includes(t)
        );
        if (
            userTagFilters?.length === 0 ||
            overlappingTags.length > 0 ||
            activeTagsForTransaction.includes("Both") // Transactions marked as "Both" are included in all filters
        ) {
            isInFilter = true;
        }
        if (transaction.direction === "out") {
            expenses.push(transaction);
            if (isInFilter) filteredExpenses.push(transaction);
        } else {
            income.push(transaction);
            if (isInFilter) filteredIncome.push(transaction);
        }
    });

    /* 🏭 CRUD 🏭 */

    const createTransaction = async (transaction: Transaction) => {
        try {
            // add to cache
            updateCache([...(transactions ?? []), transaction]);
            await api.post("transaction/", transaction);
        } catch {
            // remove from cache
            updateCache([...(transactions ?? [])]);
        }
    };

    const deleteTransaction = async (transaction: Transaction) => {
        updateCache(transactions.filter((t) => t.id !== transaction.id));
        await api.delete(`transaction/${transaction.id}`);
    };

    return {
        transactions,
        expenses,
        income,
        filteredExpenses,
        filteredIncome,
        isLoading,
        createTransaction,
        deleteTransaction,
    };
};

export default useTransactions;
