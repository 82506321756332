import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useApi } from "common/hooks/useApi";
import { useGetApi } from "common/hooks/useGetApi";
import api, { callApiMethod } from "helpers/api.helpers";

import createRecipeStore from "cooking/stores/CreateRecipeStore";

import { getOrderedSteps } from "cooking/helpers/recipe.helpers";
import {
    ComplexStep,
    Cookbook,
    Recipe,
    RecipeIngredient,
} from "cooking/models/types";

export const useCreateRecipe = (loadRecipe: boolean = true) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState("");

    const { recipeId } = useParams();
    const isEdit = !!recipeId;

    const queryParams = new URLSearchParams(window.location.search);
    const cookbookId = queryParams.get("cookbookId");

    const recipe = createRecipeStore.use.recipe();
    const ingredientBeingLinked =
        createRecipeStore.useTracked.ingredientBeingLinked();
    const isIngredientLinkActive = !!ingredientBeingLinked;

    const updateRecipe = (key: string, newValue: any) => {
        if (submitError) setSubmitError("");
        createRecipeStore.set.updateRecipe(key, newValue);
    };

    const { data, refetch } = useGetApi<Recipe>(
        `createEditComplexRecipe/${recipeId ?? ""}`,
        {
            skip: !loadRecipe,
        }
    );

    const { data: cookbook, setData: updateCookbookCache } =
        useGetApi<Cookbook>(`cookbooks/${cookbookId}`, { skip: !cookbookId });

    const { createItem: createRecipeCache, updateItem: updateRecipeCache } =
        useApi<Recipe>(`recipe/${recipeId}`, undefined, true);

    useEffect(() => {
        // set initial data
        if (data && loadRecipe) {
            const _data = { ...data, steps: getOrderedSteps(data.steps) };
            createRecipeStore.set.recipe(_data);
        }
    }, [data, loadRecipe]);

    // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const handleSubmit = async (e: any) => {
        e.preventDefault(); // prevent page from reloading
        setIsSubmitting(true);

        if (isEdit) {
            await api
                .put(`createEditComplexRecipe/${recipeId}`, recipe)
                .catch((e) => {
                    let errorMessage = e.response.data.error || e.message;

                    // clean error messages like "['The name field is required']"
                    errorMessage = errorMessage.replace(/^\['|'\]$/g, "");

                    if (errorMessage) {
                        setSubmitError(errorMessage);
                    } else {
                        console.log("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥");
                        console.error(e);
                        setSubmitError("An unknown error occurred");
                    }
                })
                .then((res) => {
                    if (!res) {
                        return; // there was an error (handled above)
                    }

                    // api.put(`createEditComplexRecipe/${recipeId}`, testRecipe);
                    // .then(() => navigate("/cooking"))
                    // .catch((err) => console.log(err));

                    updateRecipeCache(recipe, true);

                    // refetch so that we get correct ids
                    refetch();

                    createRecipeStore.set.recipe(undefined);

                    // Navigate to view
                    navigate(`/cooking/recipe/view/${recipeId}`);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        } else {
            api.post("createEditComplexRecipe/", recipe)
                .catch((e) => {
                    let errorMessage = e.response.data.error || e.message;

                    // clean error messages like "['The name field is required']"
                    errorMessage = errorMessage.replace(/^\['|'\]$/g, "");

                    if (errorMessage) {
                        setSubmitError(errorMessage);
                    } else {
                        console.log("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥");
                        console.error(e);
                        setSubmitError("An unknown error occurred");
                    }
                })
                .then(async (res) => {
                    if (!res) {
                        return; // there was an error (handled above)
                    }

                    if (res.status === 200) {
                        const { recipeId } = res.data;

                        const createdRecipe = {
                            ...recipe,
                            id: recipeId,
                        };

                        // update value in cache
                        queryClient.setQueryData(
                            ["createEditComplexRecipe", recipeId],
                            createdRecipe
                        );

                        if (cookbookId) {
                            // we're creating a recipe for a cookbook

                            // add the recipe to the cookbook
                            await callApiMethod(
                                `cookbooks/${cookbookId}/add_recipe/`,
                                {
                                    recipeId,
                                }
                            );

                            // update cookbook cache
                            //   problematic if cookbook hasn't loaded yet.. but probably won't happen
                            const _cookbook = {
                                ...cookbook,
                                recipes: [createdRecipe, ...cookbook.recipes],
                            };
                            updateCookbookCache(_cookbook);

                            // then navigate to the cookbook
                            createRecipeStore.set.recipe(undefined);
                            navigate(`/cooking/cookbook/view/${cookbookId}`);
                        } else {
                            // Navigate to the recipe view
                            createRecipeStore.set.recipe(undefined);
                            navigate(`/cooking/recipe/view/${recipeId}`);
                        }
                    } else {
                        console.log("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥");
                        console.error(`Non-200 status returned: ${res.status}`);
                        console.error(res);
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };

    const clearIngredientLink = () => {
        createRecipeStore.set.ingredientBeingLinked(undefined);
    };

    const handleIngredientLinkClick = (ingredient: RecipeIngredient) => {
        if (ingredientBeingLinked) {
            clearIngredientLink();
        } else {
            createRecipeStore.set.ingredientBeingLinked(ingredient);
        }
    };

    const linkIngredientToStep = (step: ComplexStep) => {
        if (step.ingredients.find((i) => i.id === ingredientBeingLinked.id)) {
            console.log("Duplicate ingredient detected. Not adding it again.");
            // this step already has this ingredient. Don't add a duplicate
            clearIngredientLink();
            return;
        }

        step.ingredients = [...step.ingredients, ingredientBeingLinked];
        updateRecipe("steps", [...recipe.steps]);
        clearIngredientLink();
    };

    // Unused for now bc drag n drop is... not smooth at the moment
    /* const handleIngredientDrop = (dropResult: DropResult) => {
        console.log("dropResult:", dropResult);

        if (!dropResult.destination) return;

        const stepId = dropResult.destination.droppableId.split("_")[1];
        const step = recipe.steps.find((s) => s.id.toString() === stepId);

        const ingredientId = dropResult.draggableId.split("_")[1];
        // @ts-ignore
        if (step.ingredients.find((i) => i.id.toString() === ingredientId)) {
            console.log("Duplicate ingredient detected. Not adding it again.");
            // this step already has this ingredient. Don't add a duplicate
            return;
        }

        const ingredient = recipe.ingredients.find(
            (i) => i.id.toString() === ingredientId
        );

        // @ts-ignore
        step.ingredients = [...step.ingredients, ingredient];
        updateRecipe("steps", [...recipe.steps]);
    }; */

    return {
        recipe,
        updateRecipe,
        handleSubmit,
        isSubmitting,
        submitError,
        isEdit,

        ingredientBeingLinked,
        handleIngredientLinkClick,
        clearIngredientLink,
        linkIngredientToStep,
        isIngredientLinkActive,
    };
};

export default useCreateRecipe;
