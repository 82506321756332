import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type ArrowWithLineProps = {
    className?: string;
    size?: string;
};

const ArrowWithLine: React.FC<ArrowWithLineProps> = ({
    className = "",
    size = "20px",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            fill={color}
            height={size}
            width={size}
            className={className}
        >
            <polyline
                points="10,33 60,33 60,5 100,50 60,95 60,66 10,66"
                strokeWidth="1"
            />
        </svg>
    );
};

export default ArrowWithLine;
