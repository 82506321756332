import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useCreateRecipe from "cooking/hooks/useCreateRecipe";

import TextInput from "foundation/components/forms/TextInput";
import ComplexRecipeIngredients from "cooking/components/ComplexRecipeIngredients";
import ComplexStepSection from "cooking/components/ComplexStepSection";
import RecipeFieldsNew from "./RecipeFieldsNew";

type CreateRecipeProps = {};

const CreateRecipeNew: React.FC<CreateRecipeProps> = () => {
    const {
        recipe,
        updateRecipe,
        handleSubmit: _handleSubmit,
        isIngredientLinkActive,
        clearIngredientLink,
    } = useCreateRecipe();

    const navigate = useNavigate();

    const [isSaving, setIsSaving] = useState(false);

    const handleSubmit = async (e: any) => {
        setIsSaving(true);
        await _handleSubmit(e);
        setIsSaving(false);
    };

    if (!recipe) {
        return <div>Loading...</div>;
    }

    console.log("🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆");
    console.log("recipe:", { ...recipe });

    return (
        <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className="flex flex-col mx-auto gap-[30px] relative"
        >
            <div className="w-full flex justify-between items-center">
                <button
                    onClick={() => navigate(-1)}
                    className="btn btn-secondary w-fit disabled:opacity-70"
                    disabled={isSaving}
                >
                    Back
                </button>
                <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary w-fit disabled:opacity-70"
                    disabled={isSaving}
                >
                    {!isSaving && "Save"}
                    {isSaving && "Saving..."}
                </button>
            </div>
            {isIngredientLinkActive && (
                <button
                    type="button"
                    className="absolute z-[1] h-full w-full opacity-80 bg-theme-background"
                    onClick={clearIngredientLink}
                />
            )}
            <TextInput
                name="name"
                placeholder="Recipe Name"
                displayLabel="Recipe name"
                extraInputClasses="h-[40px]"
                value={recipe.name}
                onChange={(newName) => updateRecipe("name", newName)}
                autoComplete={false}
            />
            <RecipeFieldsNew recipe={recipe} updateRecipe={updateRecipe} />
            <ComplexRecipeIngredients
                ingredients={recipe.ingredients}
                setIngredients={(i) => updateRecipe("ingredients", i)}
            />
            <ComplexStepSection
                // @ts-ignore
                steps={recipe.steps}
                setSteps={(s) => updateRecipe("steps", s)}
            />
            <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary mt-4 w-fit disabled:opacity-70"
                disabled={isSaving}
            >
                {!isSaving && "Save"}
                {isSaving && "Saving..."}
            </button>
        </form>
    );
};

export default CreateRecipeNew;
