import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "foundation/styles/Home.scss";

import appStore from "foundation/stores/AppStore";

import { callApiMethod } from "helpers/api.helpers";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { logout } from "helpers/auth.helpers";

import { AppsEnum } from "foundation/apps";
import { ActivityLinkProps } from "foundation/components/ActivityLink";
import ActivityLinks from "foundation/components/ActivityLinks";
import { Activities } from "foundation/helpers/Activities";

import { Quote } from "quotes/models/types";

const Home: React.FC = () => {
    const { currentUser, setCurrentUser } = useCurrentUser();
    const [quote, setQuote] = useState<Quote>();

    useEffect(() => {
        // in a useEffect so it only fires once
        callApiMethod("getAQuote").then((res) => setQuote(res.data));
    }, []);

    appStore.set.currentApp(AppsEnum.Home);

    const minimallyViableActivities: ActivityLinkProps[] = [
        Activities.cooking,
        Activities.quotes,
        Activities.settings,
    ];

    const workInProgressActivities: ActivityLinkProps[] = [
        Activities.skills,
        Activities.fantasyFootball,
        Activities.money,
        Activities.rememberall,
        Activities.wim,
        Activities.devNotes,
        Activities.library,
    ];

    const futureActivities: ActivityLinkProps[] = [
        Activities.journal,
        Activities.taskTracker,
        Activities.wiki,
        Activities.stats,
    ];

    return (
        <section className="flex flex-col gap-[32px]">
            {currentUser && currentUser.id !== 1 && (
                <div className="bg-theme-background-accent rounded-md p-3 text-[24px] flex flex-col gap-[5px]">
                    <p className="mb-0">
                        👋 Hello. I've noticed that you're not me. Welcome!
                    </p>
                    <p className="mb-0">
                        This site and the tools within are still very much under
                        dev. Beware.
                    </p>
                    <p className="mb-0">
                        Feel free to hit me up if you find bugs or have ideas
                        for things 🤗
                    </p>
                </div>
            )}
            <div className="flex flex-col gap-[5px]">
                <h1 className="text-center">
                    Welcome, enjoy the hodgepodge of tools
                </h1>
                {!currentUser && (
                    <Link to="login">
                        <button className="btn btn-primary w-fit">Login</button>
                    </Link>
                )}
                {currentUser && (
                    <>
                        <h2>Hi {currentUser.username}!</h2>
                        <button
                            className="btn btn-danger w-fit"
                            onClick={() => logout(setCurrentUser)}
                        >
                            Logout
                        </button>
                    </>
                )}
            </div>

            {quote && (
                <div className="mx-auto text-center w-3/4 flex flex-col items-center justify-center shadow-fuzzy-border p-2">
                    <span className="text-[20px] md:text-[32px]">
                        "{quote.quote}"
                    </span>{" "}
                    {quote.source && <span>- {quote.source}</span>}
                </div>
            )}

            <div className="flex flex-col gap-[32px]">
                <ActivityLinks
                    sectionTitle="Minimally viable"
                    activities={minimallyViableActivities}
                />
                <ActivityLinks
                    sectionTitle="Work In Progress"
                    activities={workInProgressActivities}
                />
                <ActivityLinks
                    sectionTitle="Future things (not implemented yet)"
                    activities={futureActivities}
                />
            </div>
        </section>
    );
};

export default Home;
