import React from "react";
import { useGetApi } from "common/hooks/useGetApi";

import { Player } from "fantasyFootball/models/types";

const ManageNflPlayers: React.FC = () => {
    // const { data: players } = useGetApi<Player[]>("player");
    const { data: playerStatsData } = useGetApi<{
        data: {
            season: number;
            players: {
                player_info: Player;
                stats_by_week: any;
                stats_season_total: any;
            };
        };
    }>("fantasy_football/all_player_stats/2021");

    const playerStats = playerStatsData?.data;

    // console.log("players:", players);
    console.log("playerStats:", playerStats);

    return (
        <section>
            <h1>Manage Players</h1>
            {/* {!players ||
                (players.length === 0 && (
                    <div>
                        <div>No player data</div>{" "}
                    </div>
                ))} */}
            {/* {players && players.length > 0 && (
                <ul>
                    {players.map((player) => (
                        <li key={player.id}>{player.name}</li>
                    ))}
                </ul>
            )} */}
        </section>
    );
};

export default ManageNflPlayers;
