import React from "react";

type CheckboxProps = {
    id?: string;
    text: string;
    value?: boolean;
    onClick?: (newVal?: boolean) => void;
    buttonClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    boxEnd?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
    id,
    text,
    value,
    onClick,
    buttonClassName = "",
    inputClassName = "",
    labelClassName = "",
    boxEnd = false,
}) => {
    return (
        <button
            className={`form-check flex items-center gap-[10px] mb-0 !pl-0 ${buttonClassName}`}
            onClick={onClick ? () => onClick(!value) : undefined}
        >
            {!boxEnd && (
                <input
                    className={`form-check-input !ml-0 !mt-0 ${inputClassName}`}
                    type="checkbox"
                    id={id}
                    checked={value}
                    onChange={() => { }} // suppress React error
                />
            )}
            <label
                className={`form-check-label ${labelClassName}`}
                htmlFor={id}
            >
                {text}
            </label>
            {boxEnd && (
                <input
                    className={`form-check-input !ml-0 !mt-0 ${inputClassName}`}
                    type="checkbox"
                    id={id}
                    checked={value}
                    onChange={() => { }} // suppress React error
                />
            )}
        </button>
    );
};

export default Checkbox;
