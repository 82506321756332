import React from "react";
import { Choices } from "./types";

type SelectInputProps = {
    /** The name of the field + the key in request.POST */
    name: string;

    /** The label to display for the selection */
    displayLabel?: string;

    /** The value that's currently selected */
    selectedValue: string;

    /** The options for the select input. Array of [value, text] */
    choices: Choices;

    /** Handle change event */
    onChange?: (newValue: string) => void;

    /** Whether or not to display this input. Defaults to true */
    display?: boolean;

    /** className placed on the wrapping div */
    className?: string;

    /** svgComponent Icon that will go on the right of the dropdown */
    rightIcon?: JSX.Element;
};

const SelectInput: React.FC<SelectInputProps> = ({
    name,
    displayLabel,
    selectedValue,
    choices,
    onChange,
    rightIcon,
    className = "",
    display = true,
}) => {
    if (!display) {
        return <></>;
    }

    const id = `id_${name}`;

    return (
        <div
            id={`${name}_field`}
            className={`form-group col ${className ?? ""}`}
        >
            {displayLabel && <label htmlFor={id}>{displayLabel}:</label>}
            <div className={rightIcon ? `select-with-icon flex-row` : ""}>
                <select
                    id={id}
                    name={`${name}`}
                    value={selectedValue}
                    className="form-control form-control-sm"
                    onChange={(e) => onChange(e.target.value)}
                >
                    <option value="">---------</option>
                    {choices.map((choice) => (
                        <option
                            key={`${choice[0]}-${choice[1]}`}
                            value={choice[0]}
                        >
                            {choice[1]}
                        </option>
                    ))}
                </select>
                {rightIcon}
            </div>
        </div>
    );

    // options.forEach((option) => {
    //   if (option.active) {
    //     defaultValue = option.value;
    //   }
    // });
    // return (
    //   <>
    //     <div id={`${name}_field`} className="form-group col">
    //       <label htmlFor={`id_${name}`}>{displayLabel}:</label>
    //       <select
    //         id={`id_${name}`}
    //         name={`${name}`}
    //         defaultValue={defaultValue}
    //         className="form-control form-control-sm"
    //         onChange={onChange}
    //       >
    //         <option value="">---------</option>
    //         {options.map((option) => (
    //           <option key={`${option.value}-${option.text}`} value={option.value}>
    //             {option.text}
    //           </option>
    //         ))}
    //       </select>
    //     </div>
    //   </>
    // );
};

export default SelectInput;
