import { useGetApi } from "common/hooks/useGetApi";

import { Tag } from "money/models/types";

const usePeopleTags = (retrieve: boolean = true) => {
    const {
        data: peopleTags,
        setData: updateCache,
        isLoading,
    } = useGetApi<Tag[]>("people_tag/", { skip: !retrieve });

    return {
        peopleTags,
        isLoading,
    };
};

export default usePeopleTags;
