import React, { useEffect } from "react";

import { AppsEnum } from "foundation/apps";
import appStore from "foundation/stores/AppStore";

import NotebookSideNav from "./NotebookSideNav";

const RememberallHome: React.FC = () => {
    useEffect(() => {
        appStore.set.currentApp(AppsEnum.Rememberall);
    }, []);

    return (
        <>
            <NotebookSideNav />
        </>
    );
};

export default RememberallHome;
