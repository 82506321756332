import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { useGetApi } from "common/hooks/useGetApi";
import { useApi } from "common/hooks/useApi";
import { callApiMethod } from "helpers/api.helpers";
import useConfirmation from "foundation/hooks/useConfirmation";
import useModal from "foundation/modals/useModal";

import Pencil from "foundation/svgComponents/Pencil";
import TrashIcon from "foundation/svgComponents/Trash";
import RecipeRow from "./RecipeRow";
import PlusButton from "foundation/components/PlusButton";
import AddRecipeToCookbook from "./AddRecipeToCookbook";
import Loading, { LoadingType } from "common/components/Loading";

import { Cookbook, Recipe } from "cooking/models/types";

type ViewCookbookProps = {};

const ViewCookbook: React.FC<ViewCookbookProps> = () => {
    const navigate = useNavigate();
    const confirm = useConfirmation();
    const { newModal } = useModal();
    const { currentUser } = useCurrentUser();

    const [removeRecipeMode, setRemoveRecipeMode] = useState(false);

    const { cookbookId } = useParams();
    const {
        data: cookbook,
        isLoading,
        setData: updateCookbookCache,
    } = useGetApi<Cookbook>(`cookbooks/${cookbookId}`);
    const { deleteItem } = useApi<Cookbook>("cookbooks", undefined, true);
    const { deleteData: deleteFromMyCookbooksCache } = useGetApi<Cookbook>(
        "cookbooks/my_cookbooks",
        { skip: true }
    );

    const { name, description, recipes } = cookbook ?? {};

    const recipesExist = recipes?.length > 0;

    const userOwnsRecipe = currentUser?.username === cookbook?.owner;

    const handleDelete = async () => {
        if (await confirm("Are you sure you want to delete this cookbook?")) {
            deleteItem(cookbook, () => {
                deleteFromMyCookbooksCache(cookbook);
                navigate("/cooking");
            });
        }
    };

    const handleRemoveRecipe = async (recipe: Recipe) => {
        if (
            await confirm(
                `Are you sure you want remove ${recipe.name} from this cookbook?`
            )
        ) {
            await callApiMethod(`cookbooks/${cookbook.id}/remove_recipe/`, {
                recipeId: recipe.id,
            });

            // update cache
            const _cookbook = {
                ...cookbook,
                recipes: cookbook.recipes.filter((r) => r.id !== recipe.id),
            };
            updateCookbookCache(_cookbook);
        }
    };

    return (
        <section className="w-full space-y-4 max-w-[800px] mx-auto">
            {isLoading && (
                <Loading
                    type={LoadingType.coffee}
                    className="mx-auto py-[100px]"
                />
            )}
            {!isLoading && (
                <>
                    <div>
                        <h1 className="text-shadow-sm flex gap-[20px] items-center">
                            {name}
                            {userOwnsRecipe && (
                                <div className="flex gap-[10px] items-center">
                                    <button
                                        onClick={() =>
                                            navigate(
                                                `/cooking/cookbook/edit/${cookbook?.id}`
                                            )
                                        }
                                        title="Edit"
                                    >
                                        <Pencil size="16px" />
                                    </button>

                                    <button
                                        onClick={handleDelete}
                                        title="Delete"
                                    >
                                        <TrashIcon size="16px" />
                                    </button>
                                </div>
                            )}
                        </h1>
                        {description && <div>{description}</div>}
                    </div>

                    {recipesExist && (
                        <ul className="pl-0">
                            {recipes.map((r) => (
                                <RecipeRow
                                    key={r.id}
                                    recipe={r}
                                    rowIcon={
                                        removeRecipeMode ? (
                                            <TrashIcon size="20px" />
                                        ) : undefined
                                    }
                                    onClick={
                                        removeRecipeMode
                                            ? handleRemoveRecipe
                                            : undefined
                                    }
                                />
                            ))}

                            {!recipesExist && <div>No Recipes</div>}
                        </ul>
                    )}

                    <PlusButton
                        text="Add Recipe"
                        size="20px"
                        className="px-[4px]"
                        onClick={() =>
                            newModal(
                                <AddRecipeToCookbook
                                    cookbook={cookbook}
                                    onSuccess={(recipe) => {
                                        const _cookbook = {
                                            ...cookbook,
                                            recipes: [
                                                recipe,
                                                ...cookbook.recipes,
                                            ],
                                        };
                                        updateCookbookCache(_cookbook);
                                    }}
                                />
                            )
                        }
                    />

                    <button
                        className={`flex items-center gap-[10px] text-[20px] border-solid border-[2px] rounded-[5px] pb-[2px] px-[4px] ${removeRecipeMode
                                ? "border-theme-button-danger"
                                : "border-transparent"
                            }`}
                        onClick={() => setRemoveRecipeMode(!removeRecipeMode)}
                    >
                        <TrashIcon size="20px" />
                        {removeRecipeMode
                            ? "Stop Removing Recipes"
                            : "Remove Recipes"}
                    </button>
                </>
            )}
        </section>
    );
};

export default ViewCookbook;
