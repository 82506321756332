import config from "config/config";
import axios from "axios";
import {
    getAccessToken,
    getRefreshToken,
    setAccessToken,
} from "./token.helpers";

let alreadyRetried = false;

const api = axios.create({
    baseURL: config.apiUrl,
    headers: {
        "Content-Type": "application/json",
    },
});

// Include access token in every request
api.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Auto retry for access token refresh
api.interceptors.response.use(
    (res) => {
        alreadyRetried = false;
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        // if we just got slapped with a 401_FORBIDDEN, try to refresh the access token ONCE
        //   only try if we actually have refresh/access tokens
        const refreshToken = getRefreshToken();
        const accessToken = getAccessToken();

        if (
            // We were correctly denied access
            (refreshToken === null &&
                accessToken === null &&
                err.response?.status === 401) ||
            // Backend error (without this an error will just spam retries)
            err.response?.status === 500
        ) {
            Promise.resolve();
            return;
        }

        // if this failed because it's Unauthorized (401), we haven't already tried refreshing,
        //   and we have a refresh token...
        // then refresh the access token and try again
        if (err.response?.status === 401 && !alreadyRetried && refreshToken) {
            alreadyRetried = true;

            try {
                if (!refreshToken) {
                    return;
                }

                const res = await api.post("token/refresh/", {
                    refresh: refreshToken ?? "noToken",
                });

                const { access } = res.data;
                setAccessToken(access);

                return api(originalConfig);
            } catch (_error) {
                if (!refreshToken && _error.response?.status === 401) {
                    // we're correctly unauthorized. No one is logged in
                    Promise.resolve();
                }
                console.log(
                    "🔥 rejecting promise in api. intercepters refresh section. Error:"
                );
                console.log(_error.response);

                return Promise.reject(_error);
            }
        }

        return Promise.reject(err);
    }
);

/** calls /api/{method} */
export const callApiMethod = (method: string, postData?: any): Promise<any> => {
    if (postData) {
        return api.post(method, postData);
    }
    return api.get(method);
};

export default api;
