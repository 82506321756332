import React from "react";
// import { Droppable } from "react-beautiful-dnd";

import useCreateRecipe from "cooking/hooks/useCreateRecipe";

import ComplexSteps from "cooking/components/ComplexSteps";
import ComplexStepIngredientSection from "cooking/components/ComplexStepIngredientSection";
import ButtonGroupToggle from "foundation/components/forms/ButtonGroupToggle";
import TextInput from "foundation/components/forms/TextInput";
import BulletPoints from "foundation/svgComponents/BulletPoints";
import BulletPoint from "foundation/svgComponents/BulletPointSingle";
import { ComplexStep } from "cooking/models/types";

const STEP_TYPE = { SINGLE: "single", GROUP: "group" } as const;
// type boxTypeType = typeof BOX_TYPE[keyof typeof BOX_TYPE];

type ComplexStepBoxProps = {
    step: ComplexStep;
    updateStepField: (field: string, newValue: any) => void;
    id?: string;
};

const ComplexStepBox: React.FC<ComplexStepBoxProps> = ({
    step,
    updateStepField,
    id = "",
}) => {
    const { isIngredientLinkActive, linkIngredientToStep } =
        useCreateRecipe(false);

    const boxTypeChoices = [
        {
            value: STEP_TYPE.SINGLE,
            display: <BulletPoint />,
            title: "Single Step",
        },
        {
            value: STEP_TYPE.GROUP,
            display: <BulletPoints />,
            title: "Step Group",
        },
    ];

    return (
        <div
            className={`flex flex-col gap-2 border-theme-border border-[1px] border-solid p-2 w-fit rounded-md bg-theme-background ${isIngredientLinkActive ? "hover:bg-theme-background-accent" : ""
                }`}
            id={id}
            data-testid={`step box ${step.id}`}
            role={isIngredientLinkActive ? "button" : ""}
            onClick={
                isIngredientLinkActive
                    ? (e) => {
                        e.stopPropagation(); // so that when a substep is clicked it doesn't fire for the parent too
                        linkIngredientToStep(step);
                    }
                    : undefined
            }
        >
            <div className="flex w-full items-center">
                <TextInput
                    name="servings"
                    type="number"
                    value={step.order}
                    onChange={(newVal) => updateStepField("order", newVal)}
                    className="w-[90px]"
                    displayLabel="Order"
                    labelInLine
                    min={1}
                />

                <ButtonGroupToggle
                    choices={boxTypeChoices}
                    selectedValue={step.type}
                    handleChange={(newType) => {
                        updateStepField("type", newType);
                    }}
                    className="ml-auto"
                />
            </div>

            {step.type === STEP_TYPE.SINGLE && (
                <ComplexStepBoxSingle
                    id={id}
                    step={step}
                    updateStepField={updateStepField}
                />
            )}
            {step.type === STEP_TYPE.GROUP && (
                <ComplexStepBoxGroup
                    id={id}
                    step={step}
                    updateStepField={updateStepField}
                />
            )}
        </div>
    );
};

const ComplexStepBoxSingle: React.FC<ComplexStepBoxProps> = ({
    step,
    updateStepField,
}) => {
    return (
        <div className="flex flex-col gap-[8px]">
            <ComplexStepIngredientSection
                ingredients={step.ingredients}
                updateStepField={updateStepField}
            />

            <TextInput
                name="single-step-text"
                type="textarea"
                value={step.text ?? ""}
                onChange={(newValue) => updateStepField("text", newValue)}
                autoFocus
            />
        </div>
    );

    // This isn't working very well, abandoning for now
    /* return (
        <Droppable droppableId={`step_${id}`}>
            {(provided, snapshot) => {
                // console.log("🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆");
                // console.log("provided:", provided);
                // console.log("snapshot:", snapshot);
                return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <ComplexStepIngredientSection
                            ingredients={step.ingredients}
                            updateStepField={updateStepField}
                        />

                        <TextInput
                            name="single-step-text"
                            type="textarea"
                            value={step.text ?? ""}
                            onChange={(newValue) =>
                                updateStepField("text", newValue)
                            }
                        />
                    </div>
                );
            }}
        </Droppable>
    ); */
};

const ComplexStepBoxGroup: React.FC<ComplexStepBoxProps> = ({
    step,
    updateStepField,
}) => {
    return (
        <div className="flex flex-col gap-[8px]">
            <div className="flex flex-col gap-[8px]">
                <TextInput
                    name="group-step-title"
                    placeholder="Name"
                    displayLabel="Step Group Name"
                    value={step.groupTitle ?? ""}
                    onChange={(newValue) =>
                        updateStepField("groupTitle", newValue)
                    }
                />

                <ComplexStepIngredientSection
                    ingredients={step.ingredients}
                    updateStepField={updateStepField}
                />
            </div>

            <ComplexSteps
                steps={step?.groupSteps ?? []}
                setSteps={(s) => updateStepField("groupSteps", s)}
            />
        </div>
    );
    // This isn't working very well, abandoning for now
    /* return (
        <div className="flex flex-col gap-2">
            <Droppable droppableId={`step_${id}`}>
                {(provided, snapshot) => {
                    // console.log("🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆");
                    // console.log("provided:", provided);
                    // console.log("snapshot:", snapshot);
                    return (
                        <div
                            className="flex flex-col gap-[8px]"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <TextInput
                                name="group-step-title"
                                placeholder="Name"
                                displayLabel="Step Group Name"
                                value={step.groupTitle ?? ""}
                                onChange={(newValue) =>
                                    updateStepField("groupTitle", newValue)
                                }
                            />

                            <ComplexStepIngredientSection
                                ingredients={step.ingredients}
                                updateStepField={updateStepField}
                            />
                        </div>
                    );
                }}
            </Droppable>

            <ComplexSteps
                steps={step?.groupSteps ?? []}
                setSteps={(s) => updateStepField("groupSteps", s)}
            />
        </div>
    ); */
};
// <ComplexStepBox step={{ id: 0, type: "single", ingredients: [] }} />

export default ComplexStepBox;
