import React from "react";
import { ICON_MAP } from "skills/helpers/icons";
import { Skill } from "skills/models/types";

type SelectSkillListProps = {
    skills: Skill[];
    onSkillClick: (skill: Skill) => void;
};

const SelectSkillList: React.FC<SelectSkillListProps> = ({
    skills,
    onSkillClick,
}) => {
    if ((skills?.length ?? 0) === 0) return undefined;

    return (
        <ul className="pl-0 w-fit min-w-[33%]">
            {skills.map((skill, index) => (
                <li
                    key={index}
                    className="flex gap-[5px] items-center hover:bg-theme-background-accent border-theme-border border-solid border-b-[2px] last:border-b-0"
                    role="button"
                    onClick={() => onSkillClick(skill)}
                >
                    {skill.icon && (
                        <div className="h-[80px] w-[80px] flex items-center">
                            {ICON_MAP[skill.icon]}
                        </div>
                    )}
                    <span className="text-[20px]">{skill.name}</span>
                </li>
            ))}
        </ul>
    );
};

export default SelectSkillList;
