import React from "react";
import { Link } from "react-router-dom";

import PlaceholderImage from "foundation/svgComponents/PlaceholderIcon";

export type ActivityLinkProps = {
    title: string;
    subtitle?: string;
    image?: string;
    svgComponentIcon?: React.ReactElement;
    noRightBorder?: boolean;

    linkTo: string;
};

const ActivityLink: React.FC<ActivityLinkProps> = ({
    title,
    subtitle,
    image,
    svgComponentIcon,
    noRightBorder = false,
    linkTo,
}) => {
    let icon: React.ReactNode;
    if (svgComponentIcon) {
        icon = svgComponentIcon;
    } else if (image) {
        icon = (
            <img
                src={image}
                className="home-activity-icon"
                alt={`${title} icon`}
            />
        );
    } else {
        icon = <PlaceholderImage />;
    }

    return (
        <div
            className={`hover:bg-theme-background-accent inline-block align-top min-h-[391px] border-[2px] border-solid border-theme-border p-[20px] m-[-1px] col-lg-4 col-md-6 col-12 ${noRightBorder ? "border-r-transparent" : ""
                }`}
        >
            <Link to={linkTo} className="text-theme-text no-underline">
                {icon}

                <h4 className="text-center text-shadow-sm">{title}</h4>
                <div className="text-center">
                    <small>{subtitle}</small>
                </div>
            </Link>
        </div>
    );
};

export default ActivityLink;
