// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex {
  display: flex; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-column {
  display: flex;
  flex-direction: column; }
`, "",{"version":3,"sources":["webpack://./src/foundation/styles/Flex.scss"],"names":[],"mappings":"AAAA;EACI,aAAa,EAAA;;AAGjB;EACI,aAAa;EACb,mBAAmB,EAAA;;AAGvB;EACI,aAAa;EACb,sBAAsB,EAAA","sourcesContent":[".flex {\n    display: flex;\n}\n\n.flex-row {\n    display: flex;\n    flex-direction: row;\n}\n\n.flex-column {\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
