import React from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { useConfirmation } from "foundation/hooks/useConfirmation";
import api from "helpers/api.helpers";

import { Link } from "react-router-dom";
import Pencil from "foundation/svgComponents/Pencil";
import CookingIcon from "foundation/svgComponents/CookingIcon";
import TrashIcon from "foundation/svgComponents/Trash";
import ViewRecipeDetails from "./ViewRecipeDetails";

import { Recipe } from "cooking/models/types";

type ViewRecipeHeaderProps = {
    recipe: Recipe;
};

const ViewRecipeHeader: React.FC<ViewRecipeHeaderProps> = ({ recipe }) => {
    const navigate = useNavigate();
    const { currentUser } = useCurrentUser();
    const confirm = useConfirmation();

    const deleteRecipe = async () => {
        confirm("Are you sure you want to delete this recipe?", () => {
            api.delete(`recipe/${recipe.id}`)
                .then(() => {
                    navigate("/cooking");
                })
                .catch((err) => {
                    console.error(err);
                });
        });
    };

    const userOwnsRecipe = currentUser?.id === recipe.creator;
    return (
        <div className="flex flex-col gap-[12px] relative">
            <button
                className="btn btn-primary absolute left-0"
                onClick={() => navigate("/cooking")}
            >
                Back
            </button>
            <h1 className="flex gap-[10px] items-center justify-center text-[42px] mt-[42px]">
                <span>{recipe.name}</span>
                <div className="flex gap-2 items-center">
                    {userOwnsRecipe && (
                        <button
                            onClick={() =>
                                navigate(`/cooking/recipe/edit/${recipe.id}`)
                            }
                            title="Edit"
                        >
                            <Pencil />
                        </button>
                    )}
                    <button
                        onClick={() =>
                            navigate(`/cooking/recipe/make/${recipe.id}`)
                        }
                        title="Make it!"
                    >
                        <CookingIcon className="h-[45px] w-[45px] pt-[5px]" />
                    </button>
                    {userOwnsRecipe && (
                        <button onClick={deleteRecipe} title="Delete">
                            <TrashIcon />
                        </button>
                    )}
                </div>
            </h1>
            <ViewRecipeDetails recipe={recipe} />
        </div>
    );
};

export default ViewRecipeHeader;
