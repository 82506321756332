import { createStore } from "@udecode/zustood";
import { User } from "foundation/models/types";

type CurrentUserStore = {
    currentUser: User;
};

const currentUserStore = createStore("currentUser")<CurrentUserStore>(
    {
        currentUser: undefined,
    },
    { persist: { name: "currentUser", enabled: true } }
);

export default currentUserStore;
