// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {
  color: var(--button-primary-text) !important;
  background-color: var(--button-primary) !important;
  border-color: var(--button-primary-border) !important; }

.btn-warning {
  color: var(--button-warning-text) !important;
  background-color: var(--button-warning) !important;
  border-color: var(--button-warning-border) !important; }

.btn-danger {
  color: var(--button-danger-text) !important;
  background-color: var(--button-danger) !important;
  border-color: var(--button-danger-border) !important; }

input,
select,
textarea,
option,
option:checked,
option:hover {
  color: var(--input-text) !important;
  background-color: var(--input-background) !important;
  border-color: var(--input-background) !important; }

input[type="checkbox"] {
  accent-color: var(--input-background); }

.btn-group-toggle label {
  color: var(--input-text-inverse-background) !important;
  background-color: var(--button-group) !important; }
  .btn-group-toggle label.active {
    background-color: var(--button-group-selected) !important; }

:focus {
  box-shadow: 0 0 0 0.2rem var(--focus-outline) !important; }
`, "",{"version":3,"sources":["webpack://./src/foundation/styles/Theme.scss"],"names":[],"mappings":"AAAA;EACI,4CAA4C;EAC5C,kDAAkD;EAClD,qDAAqD,EAAA;;AAGzD;EACI,4CAA4C;EAC5C,kDAAkD;EAClD,qDAAqD,EAAA;;AAGzD;EACI,2CAA2C;EAC3C,iDAAiD;EACjD,oDAAoD,EAAA;;AAGxD;;;;;;EAMI,mCAAmC;EACnC,oDAAoD;EACpD,gDAAgD,EAAA;;AAGpD;EACI,qCAAqC,EAAA;;AAGzC;EACI,sDAAsD;EACtD,gDAAgD,EAAA;EAFpD;IAKQ,yDAAyD,EAAA;;AAIjE;EACI,wDAAwD,EAAA","sourcesContent":[".btn-primary {\n    color: var(--button-primary-text) !important;\n    background-color: var(--button-primary) !important;\n    border-color: var(--button-primary-border) !important;\n}\n\n.btn-warning {\n    color: var(--button-warning-text) !important;\n    background-color: var(--button-warning) !important;\n    border-color: var(--button-warning-border) !important;\n}\n\n.btn-danger {\n    color: var(--button-danger-text) !important;\n    background-color: var(--button-danger) !important;\n    border-color: var(--button-danger-border) !important;\n}\n\ninput,\nselect,\ntextarea,\noption,\noption:checked,\noption:hover {\n    color: var(--input-text) !important;\n    background-color: var(--input-background) !important;\n    border-color: var(--input-background) !important;\n}\n\ninput[type=\"checkbox\"] {\n    accent-color: var(--input-background);\n}\n\n.btn-group-toggle label {\n    color: var(--input-text-inverse-background) !important;\n    background-color: var(--button-group) !important;\n\n    &.active {\n        background-color: var(--button-group-selected) !important;\n    }\n}\n\n:focus {\n    box-shadow: 0 0 0 0.2rem var(--focus-outline) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
