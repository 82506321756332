import React from "react";
import IngredientDisplay from "./IngredientDisplay";
import { RecipeIngredient } from "cooking/models/types";

type ViewRecipeIngredientsProps = {
    ingredients: RecipeIngredient[];
    showHeader?: boolean;
    className?: string;
};

const ViewRecipeIngredients: React.FC<ViewRecipeIngredientsProps> = ({
    ingredients,
    showHeader = false,
    className = "",
}) => {
    const areIngredients = ingredients && ingredients.length > 0;

    return (
        <section
            className={`flex flex-col gap-[20px] items-center ${className}`}
        >
            {showHeader && (
                <h2 className="underline text-[28px]">Ingredients</h2>
            )}
            {areIngredients && (
                <ul className="py-[16px] px-[34px] shadow-crisp-border shadow-theme-background-accent">
                    {ingredients.map((ingredient, index) => (
                        <li key={index}>
                            <IngredientDisplay
                                ingredient={ingredient}
                                includeInStock
                            />
                        </li>
                    ))}
                </ul>
            )}
            {!areIngredients && <p>No Ingredients!</p>}
        </section>
    );
};

export default ViewRecipeIngredients;
