import React, { useState } from "react";
import { callApiMethod } from "helpers/api.helpers";
import { useGetApi } from "common/hooks/useGetApi";

import { FantasyLeague } from "fantasyFootball/models/types";

import TextInput from "foundation/components/forms/TextInput";

const PLAYING_FOR_KEEPS_ID = "461051";
const COUCH_COACHES_ID = "613947";

const ImportYahooFantasyLeague: React.FC = () => {
    const [yahooUrl, setYahooUrl] = useState(PLAYING_FOR_KEEPS_ID);

    const { data: fantasyLeagues, setData: setFantasyLeagues } =
        useGetApi<FantasyLeague[]>("fantasy_league");

    console.log("fantasyLeagues:", fantasyLeagues);

    const importYahooLeague = () => {
        // console.log("yahooUrl:", yahooUrl);

        callApiMethod(`fantasy_football/import_yahoo_league/${yahooUrl}`).then(
            (res) => {
                if (res.data.success) {
                    const importedLeague = res.data.league;
                    setFantasyLeagues([
                        importedLeague,
                        ...fantasyLeagues.filter(
                            (league) => league.id !== importedLeague.id
                        ),
                    ]);
                } else {
                    console.error(res.data.errorMessage);
                }
            }
        );
    };

    return (
        <section>
            <h2>Yahoo import...</h2>
            <section>
                <h3>Existing leagues</h3>
                {fantasyLeagues &&
                    fantasyLeagues.map((league) => (
                        <div key={league.id}>{league.name}</div>
                    ))}
            </section>
            <section>
                <p>
                    PLAYING FOR KEEPS: {PLAYING_FOR_KEEPS_ID}
                    <br />
                    Couch Coaches: {COUCH_COACHES_ID}
                </p>
                <TextInput
                    name="yahoo-url"
                    displayLabel="League url"
                    value={yahooUrl}
                    placeholder="League url... todo put exact url form here"
                    className="form-group"
                    onChange={setYahooUrl}
                />
                <button className="btn btn-primary" onClick={importYahooLeague}>
                    Import
                </button>
            </section>
        </section>
    );
};

export default ImportYahooFantasyLeague;
