import { Recipe } from "cooking/models/types";

export const generateFakeRecipe = (id?: number) => {
    const recipe: Recipe = {
        DESIRE_TO_MAKE_CHOICES: [],
        DIFFICULTY_CHOICES: [],
        RATING_CHOICES: [],
        STATUS_CHOICES: [],
        createdDate: new Date().toString(),
        creator: 1,
        desireToMake: "5",
        difficulty: "5",
        id: id ?? -1,
        name: "Test Recipe",
        rating: "5",
        servings: 10,
        source: "somewhere",
        status: "1",
        time: "10 hours",

        tags: [],
        ingredients: [],
        steps: [],
    };

    return recipe;
};
