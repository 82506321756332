import React from "react";
import ViewRecipeStep from "./ViewRecipeStep";
import ViewRecipeIngredients from "./ViewRecipeIngredients";

import { ComplexStep } from "cooking/models/types";

type ViewRecipeStepGroupProps = {
    step: ComplexStep;
};

const ViewRecipeStepGroup: React.FC<ViewRecipeStepGroupProps> = ({ step }) => {
    return (
        <div className="shadow-crisp-border shadow-theme-background-accent p-[10px] rounded-lg flex flex-col gap-[10px]">
            <h3 className="text-[22px] mb-[10px] font-[600]">
                {step.groupTitle}
            </h3>

            <div className="pl-[10px] flex flex-col gap-[20px]">
                {step.ingredients.length > 0 && (
                    <ViewRecipeIngredients
                        ingredients={step.ingredients}
                        className="!items-baseline"
                    />
                )}

                {step.groupSteps.map((s, index) => (
                    <ViewRecipeStep key={index} step={s} />
                ))}
            </div>
        </div>
    );
};

export default ViewRecipeStepGroup;
