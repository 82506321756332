import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { callApiMethod } from "helpers/api.helpers";

import { Recipe } from "cooking/models/types";

import RecipeRows from "cooking/components/RecipeRows";
import SearchableRecipes from "cooking/components/SearchableRecipes";

import PlusIcon from "foundation/svgComponents/Plus";
import CheckIcon from "foundation/svgComponents/Check";

const CreateMealPlan: React.FC = () => {
    const navigate = useNavigate();
    const [currentMealPlan, setCurrentMealPlan] = useState<Recipe[]>([]);

    const addToMealPlan = (recipe: Recipe) => {
        setCurrentMealPlan([...currentMealPlan, recipe]);
    };

    const removeFromMealPlan = (recipeToRemove: Recipe) => {
        setCurrentMealPlan(
            currentMealPlan.filter((recipe) => recipe.id !== recipeToRemove.id)
        );
    };

    const createMealPlan = () => {
        const recipeIds = currentMealPlan.map((r) => r.id);

        callApiMethod("cooking/createSimpleMealPlan", recipeIds)
            .then(() => navigate("/cooking"))
            .catch((err) => console.error(err));
    };

    return (
        <>
            <h1>Create Meal Plan</h1>

            <section className="mb-10">
                <h2>Selected Recipes</h2>
                {currentMealPlan.length > 0 && (
                    <>
                        <RecipeRows
                            recipes={currentMealPlan}
                            onClick={removeFromMealPlan}
                            rowIcon={<CheckIcon />}
                        />
                        <button
                            className="btn btn-primary"
                            onClick={createMealPlan}
                        >
                            Save
                        </button>
                    </>
                )}
                {currentMealPlan.length === 0 && (
                    <p className="mb-10">Select recipes...</p>
                )}
            </section>

            <SearchableRecipes
                onRecipeClick={addToMealPlan}
                recipesToExclude={currentMealPlan}
                rowIcon={<PlusIcon />}
            />
        </>
    );
};

export default CreateMealPlan;
