import React, { useState } from "react";
import { useApi } from "common/hooks/useApi";
import useModal from "foundation/modals/useModal";

import IngredientsByTag from "./IngredientsByTag";
import TextInput from "foundation/components/forms/TextInput";
import Loading, { LoadingType } from "common/components/Loading";

import { Ingredient, KitchenIngredient, Tag } from "cooking/models/types";
import KitchenIngredientDetailsModal from "./KitchenIngredientDetailsModal";
import PlusButton from "foundation/components/PlusButton";

type KitchenProps = {};

const Kitchen: React.FC<KitchenProps> = () => {
    const { newModal } = useModal();

    const [searchString, setSearchString] = useState("");

    const {
        data: allIngredients,
        updateItem: updateIngredient,
        updateTempItemInCache: updateTempIngredient,
        createItem: createIngredient,
        isLoading,
    } = useApi<Ingredient>("ingredient");

    const {
        createItem: addKitchenIngredient,
        updateItem: updateKitchenIngredient,
        deleteItem: removeKitchenIngredient,
    } = useApi<KitchenIngredient>("kitchen_ingredient", undefined, false);

    const filteredIngredients = allIngredients?.filter((i) => {
        if (!searchString) return true;
        return i.name.toLowerCase().includes(searchString);
    });

    const { data: tags, refetch: refetchTags } = useApi<Tag>(
        "tag?type=ingredient"
    );

    const openIngredientModal = (
        ki: KitchenIngredient | undefined,
        ingredient: Ingredient | undefined
    ) => {
        newModal(
            <KitchenIngredientDetailsModal
                kitchenIngredient={ki}
                ingredient={ingredient}
                allExistingTags={tags}
                handleSave={async (updatedKi) => {
                    if (ingredient) {
                        // optimistically update ingredient first so that it moves if tags were updated
                        updateIngredient(updatedKi.ingredient, true);
                        // note: this^ used to call refetch ingredients, might need to add it back?
                    }

                    if (ki) {
                        await updateKitchenIngredient(updatedKi);
                    } else {
                        if (!ingredient) {
                            createIngredient(
                                updatedKi.ingredient,
                                undefined,
                                true
                            );
                        }

                        // adding to kitchen
                        const createdKi = await addKitchenIngredient(
                            updatedKi,
                            true
                        );
                        updateTempIngredient(createdKi.ingredient);
                    }

                    refetchTags();
                }}
                handleIngredientOnlySave={
                    ki
                        ? undefined
                        : async (updatedIngredient) => {
                            if (ingredient) {
                                updateIngredient(updatedIngredient);
                            } else {
                                createIngredient(updatedIngredient);
                            }
                        }
                }
                handleRemove={removeKitchenIngredient}
            />
        );
    };

    return (
        <section className="flex flex-col gap-[30px] w-full">
            <TextInput
                displayLabel="Search"
                value={searchString}
                onChange={(v) => setSearchString(v)}
                placeholder="Filter..."
            />

            {isLoading && <Loading type={LoadingType.coffee} />}

            {!isLoading && (
                <>
                    <PlusButton
                        text="Add New"
                        size="20px"
                        className="w-fit"
                        onClick={() =>
                            openIngredientModal(undefined, undefined)
                        }
                    />

                    <IngredientsByTag
                        title="Tracked"
                        ingredients={filteredIngredients}
                        justKitchenIngredients
                        openIngredientModal={openIngredientModal}
                    />

                    <IngredientsByTag
                        title="Untracked"
                        ingredients={filteredIngredients}
                        justKitchenIngredients={false}
                        openIngredientModal={openIngredientModal}
                    />
                </>
            )}
        </section>
    );
};

export default Kitchen;
