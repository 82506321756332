// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expanding-text-input-wrapper .content-sizer {
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre; }

.expanding-text-input-wrapper input {
  min-width: 71px;
  padding: 0; }
  .expanding-text-input-wrapper input:focus {
    outline: none;
    box-shadow: none; }
`, "",{"version":3,"sources":["webpack://./src/foundation/styles/ExpandingTextInput.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,gBAAgB,EAAA;;AALpB;EASI,eAAe;EACf,UAAU,EAAA;EAVd;IAaM,aAAa;IACb,gBAAgB,EAAA","sourcesContent":[".expanding-text-input-wrapper {\n  .content-sizer {\n    position: absolute;\n    opacity: 0;\n    z-index: -100;\n    white-space: pre;\n  }\n\n  input {\n    min-width: 71px; // Width of \"Add Tag...\" placeholder text\n    padding: 0;\n\n    &:focus {\n      outline: none;\n      box-shadow: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
