import { ComplexStep } from "cooking/models/types";

export const getOrderedSteps = (steps: ComplexStep[]) => {
    const orderedSteps = steps.reduce((ordered, step) => {
        // insert step before it's dependencies
        if (step.dependencies.length > 0) {
            const dependencyIndex = ordered.findIndex((o) =>
                step.dependencies.includes(o.id)
            );

            if (dependencyIndex !== -1) {
                ordered.splice(dependencyIndex, 0, step);
                return ordered;
            }
        }

        if (step.dependsOn.length === 0) {
            // no dependencies to the top
            return [step, ...ordered];
        } else {
            return [...ordered, step];
        }
    }, []);

    return orderedSteps;
};
