import React from "react";

import ViewRecipeStepSingle from "./ViewRecipeStepSingle";

import { ComplexStep, STEP_TYPE } from "cooking/models/types";
import ViewRecipeStepGroup from "./ViewRecipeStepGroup";

type ViewRecipeStepProps = {
    step: ComplexStep;
};

const ViewRecipeStep: React.FC<ViewRecipeStepProps> = ({ step }) => {
    if (step.type === STEP_TYPE.single) {
        return <ViewRecipeStepSingle step={step} />;
    }

    if (step.type === STEP_TYPE.group) {
        return <ViewRecipeStepGroup step={step} />;
    }

    return <div>🚧 you shouldn't be here 🚧</div>;
};

export default ViewRecipeStep;
