import React from "react";
import Tabs from "foundation/components/Tabs";

import ImportYahooFantasyLeague from "fantasyFootball/components/ImportYahooFantasyLeague";

const CreateFantasyLeague: React.FC = () => {
    return (
        <section>
            <h1>Create Fantasy League</h1>
            <Tabs
                tabs={[
                    { name: "Custom", content: "todo..." },
                    { name: "Yahoo", content: <ImportYahooFantasyLeague /> },
                ]}
            />
        </section>
    );
};

export default CreateFantasyLeague;
