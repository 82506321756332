import { useEffect } from "react";
import { useGetApi } from "common/hooks/useGetApi";

import { SETTING_KEYS } from "common/cacheKeys";
import { SetState } from "helpers/types";
import { CURRENT_USER_API } from "helpers/auth.helpers";

import { User } from "foundation/models/types";
import currentUserStore from "foundation/stores/CurrentUserStore";
import { getAccessToken } from "helpers/token.helpers";

export type UserContextValues = {
    currentUser?: User;
    setCurrentUser: SetState<User>;
};

export const useCurrentUser = () => {
    const currentUser = currentUserStore.use.currentUser();
    const isLoggedIn = getAccessToken() !== null;

    const { data: userData, setData: setUserQueryData } = useGetApi<User>(
        CURRENT_USER_API,
        {
            skip: !isLoggedIn,
        }
    );

    useEffect(() => {
        if (userData) {
            currentUserStore.set.currentUser(userData);
        }
    }, [userData]);

    useEffect(() => {
        if (currentUser?.settings?.selectedTheme) {
            localStorage.setItem(
                SETTING_KEYS.THEME,
                currentUser.settings?.selectedTheme
            );
        }
    }, [currentUser?.settings?.selectedTheme]);

    const setCurrentUser = (user: User) => {
        setUserQueryData(user);
        currentUserStore.set.currentUser(user);
    };

    return {
        currentUser,
        setCurrentUser,
    };
};

// jwt token auth
//   https://medium.com/@dakota.lillie/django-react-jwt-authentication-5015ee00ef9a
//   https://simpleisbetterthancomplex.com/tutorial/2018/12/19/how-to-use-jwt-authentication-with-django-rest-framework.html
