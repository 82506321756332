import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type TablespoonProps = {
    className?: string;
    quantity?: string;
    size?: string;
};

const Tablespoon: React.FC<TablespoonProps> = ({
    className = "",
    quantity,
    size = "30px",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 100 50"
            fill="none"
            stroke={color}
            strokeWidth="3"
            className={className}
        >
            {/* Simple tablespoon handle */}
            <line x1="10" y1="30" x2="58" y2="30" />

            {/* Tablespoon bowl - much larger than teaspoon */}
            <ellipse cx="78" cy="30" rx="20" ry="15" fill="none" />

            {/* "T" in the tablespoon bowl */}
            <text
                x="78"
                y="32"
                fontSize="18"
                textAnchor="middle"
                dominantBaseline="middle"
                fill={color}
                stroke="none"
            >
                T
            </text>

            {/* Quantity text - bigger */}
            <text
                x="40"
                y="14"
                fontSize="40"
                textAnchor="middle"
                dominantBaseline="middle"
                fill={color}
                stroke="none"
            >
                {quantity}
            </text>
        </svg>
    );
};

export default Tablespoon;
