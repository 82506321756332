import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetApi } from "common/hooks/useGetApi";
import api from "helpers/api.helpers";

import CookbookForm, { CookbookFormData } from "./CookbookForm";
import { Cookbook } from "cooking/models/types";

type EditCookbookProps = {};

const EditCookbook: React.FC<EditCookbookProps> = () => {
    const navigate = useNavigate();

    const { cookbookId } = useParams();
    const { data: cookbook, setData } = useGetApi<Cookbook>(
        `cookbooks/${cookbookId}`
    );

    const handleSubmit = async (data: CookbookFormData) => {
        await api.put(`/cookbooks/${cookbookId}/`, {
            ...data,
            recipeIds: data.recipes.map((r) => r.id),
        });

        setData({ ...cookbook, ...data });

        navigate(`/cooking/cookbook/view/${cookbookId}`);
    };

    if (!cookbook) return <div>Loading...</div>;

    return <CookbookForm initialData={cookbook} onSubmit={handleSubmit} />;
};

export default EditCookbook;
