import React, { useState } from "react";
import { useApi } from "common/hooks/useApi";
import useModal from "foundation/modals/useModal";

import TextInput from "foundation/components/forms/TextInput";
import IconSelector from "quotes/components/IconSelector";

import { Skill } from "skills/models/types";

const CreateSkillModal: React.FC = () => {
    const { closeModal } = useModal();
    const { createItem: createSkill } = useApi<Skill>("skill");

    const [skill, setSkill] = useState<Skill>({ name: "", icon: undefined });
    const { name, icon } = skill;

    const updateSkill = (key: string, newVal: string) => {
        setSkill({ ...skill, [key]: newVal });
    };

    return (
        <div className="flex flex-col gap-[22px]">
            <h1 className="text-center">✨ Create Skill ✨</h1>
            <div className="flex flex-col gap-[15px]">
                <TextInput
                    name="name"
                    value={name}
                    placeholder={"Skill name..."}
                    extraInputClasses="text-[32px]"
                    displayLabel="Name"
                    required
                    onChange={(newVal) => updateSkill("name", newVal)}
                />

                <IconSelector
                    icon={icon}
                    setIcon={(newIcon) => updateSkill("icon", newIcon)}
                />
            </div>
            <button
                className="btn btn-primary disabled:opacity-50"
                disabled={!name || !icon}
                onClick={() => {
                    createSkill(skill);
                    closeModal();
                }}
            >
                Create
            </button>
        </div>
    );
};

export default CreateSkillModal;
