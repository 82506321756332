import React from "react";

import { Recipe } from "cooking/models/types";
import { Choices } from "foundation/components/forms/types";

import MeterIcon from "foundation/svgComponents/MeterIcon";
import StarIcon from "foundation/svgComponents/StarIcon";
import ClockIcon from "foundation/svgComponents/ClockIcon";

export enum RECIPE_STATUS {
    MADE_BEFORE = "1",
    HAVENT_MADE_YET = "2",
    NO_RECIPE = "3",
    MADE_BEFORE_BUT_NEEDS_INPUT = "4",
}

export enum WRAPPER_MODE {
    VIEW,
    EDIT,
}

export class RecipeWrapper {
    recipe?: Recipe;
    mode: WRAPPER_MODE;

    constructor(recipe: Recipe, wrapperMode: WRAPPER_MODE = WRAPPER_MODE.VIEW) {
        this.recipe = recipe;
        this.mode = wrapperMode;
    }

    // Display words
    get desireToMakeDisplay() {
        return this.convertChoiceNumberToWord(
            this.recipe?.desireToMake,
            this.recipe?.DESIRE_TO_MAKE_CHOICES
        );
    }

    get difficultyDisplay() {
        return this.convertChoiceNumberToWord(
            this.recipe?.difficulty,
            this.recipe?.DIFFICULTY_CHOICES
        );
    }

    get statusDisplay() {
        return this.convertChoiceNumberToWord(
            this.recipe?.status,
            this.recipe?.STATUS_CHOICES
        );
    }

    get ratingDisplay() {
        return this.convertChoiceNumberToWord(
            this.recipe?.rating,
            this.recipe?.RATING_CHOICES
        );
    }

    // Icons
    get difficultyIcon() {
        return <MeterIcon className="mt-[1px] mb-[-1px]" />; // margins are to center the icon
    }

    get ratingIcon() {
        return <StarIcon />;
    }

    get timeIcon() {
        return <ClockIcon />;
    }

    // status helpers
    get haventMadeYet() {
        return (
            [RECIPE_STATUS.HAVENT_MADE_YET, RECIPE_STATUS.NO_RECIPE] as [
                any,
                any
            ]
        ).includes(this.recipe?.status);
    }

    get madeBefore() {
        return (
            [
                RECIPE_STATUS.MADE_BEFORE,
                RECIPE_STATUS.MADE_BEFORE_BUT_NEEDS_INPUT,
            ] as [any, any]
        ).includes(this.recipe?.status);
    }

    get haveRecipe() {
        return (
            [
                RECIPE_STATUS.MADE_BEFORE,
                RECIPE_STATUS.MADE_BEFORE_BUT_NEEDS_INPUT,
                RECIPE_STATUS.HAVENT_MADE_YET,
            ] as [any, any, any]
        ).includes(this.recipe?.status);
    }

    // ShouldDisplay...
    get shouldDisplayDesireToMake() {
        if (this.mode === WRAPPER_MODE.EDIT) {
            return this.haventMadeYet;
        }
        return this.haventMadeYet && !!this.recipe?.desireToMake;
    }

    get shouldDisplayRating() {
        if (this.mode === WRAPPER_MODE.EDIT) {
            return this.madeBefore;
        }
        return this.madeBefore && !!this.recipe?.rating;
    }

    get shouldDisplayServings() {
        if (this.mode === WRAPPER_MODE.EDIT) {
            return this.haveRecipe;
        }
        return this.haveRecipe && !!this.recipe?.servings;
    }

    get shouldDisplayTime() {
        if (this.mode === WRAPPER_MODE.EDIT) {
            return this.haveRecipe;
        }
        return this.haveRecipe && !!this.recipe?.time;
    }

    get shouldDisplayDifficulty() {
        if (this.mode === WRAPPER_MODE.EDIT) {
            return this.haveRecipe;
        }
        return this.haveRecipe && !!this.recipe?.difficulty;
    }

    get shouldDisplaySource() {
        if (this.mode === WRAPPER_MODE.EDIT) {
            return this.haveRecipe;
        }
        return this.haveRecipe && !!this.recipe?.source;
    }

    get hasAnyBasicDetails() {
        const { rating, difficulty, servings, time } = this.recipe;
        return rating || difficulty || servings || time;
    }

    convertChoiceNumberToWord(choiceNumber: string, choices: Choices) {
        if (!choiceNumber || !choices) {
            return "?";
        }

        // Choices are like [['1', 'word1'], ['2', 'word2'], ...]
        return choices.find((c) => c[0] === choiceNumber)[1];
    }

    userIsRecipeCreator(user) {
        return user?.id === this.recipe?.creator;
    }
}
