import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type WikiIconProps = {
    className?: string;
    color?: string;
};

const WikiIcon: React.FC<WikiIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                fill={color}
                className={className}
            >
                <g>
                    <path d="M84.9,21.6c-0.8-0.9-1.6-1.8-2.4-2.7C74.3,10.4,62.8,5,50,5c-12.8,0-24.3,5.4-32.5,13.9c-0.8,0.9-1.6,1.8-2.4,2.7   C8.8,29.4,5,39.3,5,50c0,10.7,3.8,20.6,10.1,28.4c0.8,0.9,1.6,1.8,2.4,2.7C25.7,89.6,37.2,95,50,95c12.8,0,24.3-5.4,32.5-13.9   c0.8-0.9,1.6-1.8,2.4-2.7C91.2,70.6,95,60.7,95,50C95,39.3,91.2,29.4,84.9,21.6z M79.4,20.7c-3,1.4-6.4,2.7-10.1,3.6   c-2.2-6.1-5.1-11-8.5-14.4C67.9,11.8,74.3,15.6,79.4,20.7z M69.9,50c0,7.8-1.1,15.2-3,21.6C61.7,70.5,56,69.9,50,69.9   c-6,0-11.7,0.6-16.9,1.7c-1.9-6.3-3-13.7-3-21.6c0-7.8,1.1-15.2,3-21.6c5.2,1.1,10.9,1.7,16.9,1.7s11.7-0.6,16.9-1.7   C68.8,34.8,69.9,42.2,69.9,50z M65.8,25.1c-4.9,1-10.2,1.6-15.8,1.6c-5.6,0-10.9-0.6-15.8-1.6c3.7-10,9.4-16.6,15.8-16.6   S62.1,15.1,65.8,25.1z M39.3,9.9c-3.5,3.4-6.4,8.3-8.5,14.4c-3.7-1-7.1-2.2-10.1-3.6C25.7,15.6,32.1,11.8,39.3,9.9z M8.5,50   c0-10.1,3.6-19.4,9.7-26.6c3.3,1.7,7.2,3.2,11.5,4.3c-1.9,6.6-3,14.2-3,22.4c0,8.2,1.1,15.8,3,22.4c-4.3,1.1-8.2,2.5-11.5,4.3   C12.1,69.4,8.5,60.1,8.5,50z M20.6,79.3c3-1.4,6.4-2.7,10.1-3.6c2.2,6.1,5.1,11,8.5,14.4C32.1,88.2,25.7,84.4,20.6,79.3z    M34.2,74.9c4.9-1,10.2-1.6,15.8-1.6s10.9,0.6,15.8,1.6c-3.7,10-9.4,16.6-15.8,16.6S37.9,84.9,34.2,74.9z M60.7,90.1   c3.5-3.4,6.4-8.3,8.5-14.4c3.7,1,7.1,2.2,10.1,3.6C74.3,84.4,67.9,88.2,60.7,90.1z M81.9,76.6c-3.3-1.7-7.2-3.2-11.5-4.3   c1.9-6.6,3-14.2,3-22.4c0-8.2-1.1-15.8-3-22.4c4.3-1.1,8.2-2.5,11.5-4.3c6,7.2,9.7,16.5,9.7,26.6S87.9,69.4,81.9,76.6z" />
                </g>
                <g>
                    <path d="M51.9,61.1h2.7c1.2,0,2.5,0.3,2.5,1.8c0,1.6-1.4,1.8-2.5,1.8h-9.2c-1.1,0-2.5-0.2-2.5-1.8c0-1.6,1.3-1.8,2.5-1.8h2.9V48   h-2.9c-1.1,0-2.5-0.2-2.5-1.8c0-1.6,1.3-1.8,2.5-1.8h3.8c2.1,0,2.7,0.4,2.7,2.5V61.1z M46.4,37c0-1.3,0.7-1.7,1.7-1.7h2.1   c1,0,1.7,0.4,1.7,1.7v1.7c0,1.3-0.7,1.7-1.7,1.7h-2.1c-1,0-1.7-0.4-1.7-1.7V37z" />
                </g>
            </svg>
        </>
    );
};

export default WikiIcon;
