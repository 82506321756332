import React from "react";
import ComplexSteps from "cooking/components/ComplexSteps";

import { ComplexStep } from "cooking/models/types";

type ComplexStepSectionProps = {
    steps: ComplexStep[];
    setSteps: (steps: ComplexStep[]) => void;
};

const ComplexStepSection: React.FC<ComplexStepSectionProps> = ({
    steps,
    setSteps,
}) => {
    return (
        <section>
            <h2 className="underline mb-4">Steps</h2>
            <ComplexSteps steps={steps} setSteps={setSteps} />
        </section>
    );
};

export default ComplexStepSection;
