import React, { useState, useRef, useEffect } from "react";

import "foundation/styles/ExpandingTextInput.scss";

type ExpandingTextInputProps = {
  text: string;
  setText: (newText: string) => void;
  className?: string;
  placeholder?: string;
  onEnter?: (text: string) => void;
};

const ExpandingTextInput: React.FC<ExpandingTextInputProps> = ({
  text,
  setText,
  className = "",
  placeholder,
  onEnter,
}) => {
  const [width, setWidth] = useState(0);
  const span = useRef<HTMLSpanElement>();

  useEffect(() => {
    if (span?.current) {
      setWidth(span?.current?.offsetWidth ?? 0);
    } else {
      setWidth(0);
    }
  }, [text]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newValue = (e.target as HTMLInputElement).value;
    if (e.key === "Enter") {
      e.preventDefault();
      onEnter(newValue);
    }
  };

  return (
    <span className="expanding-text-input-wrapper">
      <span className="content-sizer" ref={span}>
        {text}
      </span>
      <input
        type="text"
        className={className}
        style={{ width }}
        placeholder={placeholder}
        onKeyDown={handleKeyPress}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
    </span>
  );
};

export default ExpandingTextInput;
