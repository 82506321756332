import {
    AVAILABLE_THEMES,
    DEFAULT_THEME_KEY,
    THEME_COLORS,
} from "settings/Themes";

import { User } from "foundation/models/types";
import { SETTING_KEYS } from "common/cacheKeys";

export const getSelectedThemeKey = (user: User) => {
    return (
        user?.settings?.selectedTheme ??
        localStorage.getItem(SETTING_KEYS.THEME) ??
        DEFAULT_THEME_KEY
    );
};

export const getSelectedTheme = (user: User) => {
    return AVAILABLE_THEMES[getSelectedThemeKey(user)];
};

export const getThemeColors = (user: User) => {
    return THEME_COLORS[getSelectedTheme(user)];
};

export const getThemeTextColor = (user: User) => {
    return getThemeColors(user).text;
};
