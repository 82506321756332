import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type ArrowWithStrikethroughProps = {
    className?: string;
    size?: string;
    strikeColor?: string;
};

const ArrowWithStrikethrough: React.FC<ArrowWithStrikethroughProps> = ({
    className = "",
    size = "20px",
    strikeColor,
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);
    const strike = strikeColor || color;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            fill={color}
            height={size}
            width={size}
            className={className}
        >
            {/* Original arrow */}
            <polyline
                points="10,33 60,33 60,5 100,50 60,95 60,66 10,66"
                strokeWidth="1"
            />

            {/* Diagonal strike-through line */}
            <line
                x1="10"
                y1="10"
                x2="90"
                y2="90"
                stroke={strike}
                strokeWidth="8"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default ArrowWithStrikethrough;
