import React, { useState } from "react";
import useModal from "foundation/modals/useModal";
import { useGetApi } from "common/hooks/useGetApi";

import TextInput from "foundation/components/forms/TextInput";
import { Ingredient, RecipeIngredient } from "cooking/models/types";
import Trash from "foundation/svgComponents/Trash";

type MapRecipeIngredientToIngredientModalProps = {
    recipeIngredient: RecipeIngredient;
    handleIngredientClick: (ingredientToLinkToId: number) => void;
};

// TODO add new ingredient here
const MapRecipeIngredientToIngredientModal: React.FC<
    MapRecipeIngredientToIngredientModalProps
> = ({ recipeIngredient, handleIngredientClick }) => {
    const { closeModal } = useModal();
    const [filterText, setFilterText] = useState("");
    const { data: ingredients } = useGetApi<Ingredient[]>("ingredient");

    const filteredIngredients = ingredients?.filter((i) =>
        i.name.toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="p-[10px] flex flex-col gap-[20px]">
            <h1 className="font-[600]">
                Map "{recipeIngredient.name || recipeIngredient.originalText}"
                to...
            </h1>

            <TextInput
                name="Filter"
                placeholder="Filter..."
                value={filterText}
                onChange={(newVal) => setFilterText(newVal)}
            />

            <div className="flex flex-col gap-[5px]">
                {filteredIngredients?.map((ingredient, index) => (
                    <button
                        key={index}
                        onClick={() => handleIngredientClick(ingredient.id)}
                        className={
                            ingredient.id === recipeIngredient.ingredient
                                ? "bg-theme-text text-theme-background"
                                : ""
                        }
                    >
                        {ingredient.name}
                    </button>
                ))}
            </div>

            <footer className="sticky bottom-[-15px] py-[15px] bg-theme-background flex justify-center gap-[20px]">
                {recipeIngredient.ingredient && (
                    <button
                        onClick={() => handleIngredientClick(null)}
                        className="btn btn-danger flex gap-[5px] items-center"
                    >
                        Unlink <Trash color="currentColor" size="20px" />
                    </button>
                )}
                <button onClick={closeModal} className="btn btn-secondary">
                    Close
                </button>
            </footer>
        </div>
    );
};

export default MapRecipeIngredientToIngredientModal;
