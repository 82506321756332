import api from "helpers/api.helpers";
import { cloneDeep } from "lodash";
import { useGetApi } from "./useGetApi";

export function useApi<T extends { id?: number }>(
    url: string,
    postprocess?: (input: T[]) => T[],
    skip: boolean = false
) {
    const urlWithSlash = url.endsWith("/") ? url : `${url}/`;

    const { data, refetch, isLoading, appendData, setData } = useGetApi<T[]>(
        url,
        {
            postprocess,
            skip,
        }
    );

    // preferArray is to handle an edge case where the data was undefined, so it would setData
    //   as not an array, but the consuming code wanted an array, so...
    const createItem = async (
        item: T,
        preferArray: boolean = false,
        skipDb: boolean = false
    ) => {
        const oldData = cloneDeep(data);

        // @ts-ignore
        appendData(item, preferArray);

        if (skipDb) return;

        try {
            const res = await api.post(urlWithSlash, item);

            if (!res.data) {
                console.error("Error creating item");
                setData(oldData);
            }

            // reset and add the real item (i.e. the one with a real ID)
            setData(oldData);
            // @ts-ignore
            appendData(res.data, preferArray);

            return res.data;
        } catch (e) {
            console.error("Error creating item", e);
            setData(oldData);
        }
    };

    const deleteById = (id: number) => {
        const oldData = cloneDeep(data);
        const newData = data.filter((item: T) => item?.id !== id);
        setData(newData);

        // doesn't really exist (i.e. it was just cached)
        //   just update cache and return
        if (id === -1) return;

        api.delete(`${urlWithSlash}${id}`).catch((e) => {
            console.error("Failed to delete:", e);
            setData(oldData);
        });
    };

    const deleteItem = (item: T) => {
        deleteById(item.id);
    };

    const updateItem = async (item: T, skipDb: boolean = false) => {
        const isArray = Array.isArray(data);

        const oldData = cloneDeep(data);

        if (isArray) {
            const itemIndex = data.findIndex((d) => d.id === item.id);

            if (itemIndex === -1) return; // something went wrong, abort
            data[itemIndex] = item;
            setData(cloneDeep(data));
        } else {
            setData(item);
        }

        if (skipDb) return;

        try {
            await api.put(urlWithSlash, item);
        } catch (e) {
            console.error("Error updating item", e);
            setData(oldData);
        }
    };

    // use when there's an item with id: -1 in the cache and you want to update it with real data
    const updateTempItemInCache = (item: T) => {
        const itemIndex = data.findIndex((d) => d.id === -1);

        if (itemIndex === -1) return; // something went wrong, abort
        data[itemIndex] = item;
        setData(cloneDeep(data));
    };

    return {
        data,
        createItem,
        updateItem,
        deleteById,
        deleteItem,
        refetch,
        isLoading,

        updateTempItemInCache,
    };
}
