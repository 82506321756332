import React, { useState, useRef, FormEvent } from "react";
import api from "helpers/api.helpers";
import { useGetApi } from "common/hooks/useGetApi";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";

import { AppsEnum } from "foundation/apps";
import appStore from "foundation/stores/AppStore";

import { Quote } from "quotes/models/types";
import TextInput from "foundation/components/forms/TextInput";
import Trash from "foundation/svgComponents/Trash";

const QuotesHome: React.FC = () => {
    const { currentUser } = useCurrentUser();
    const [newQuote, setNewQuote] = useState("");
    const [newSource, setNewSource] = useState("");
    const quoteInputRef = useRef<HTMLInputElement>();

    appStore.set.currentApp(AppsEnum.Quotes);

    const { data: quotes, refetch } = useGetApi<Quote[]>("quotes");

    const handleAddQuote = (e: FormEvent) => {
        e.preventDefault();
        if (!newQuote) return;

        api.post("createEditQuote/", {
            quote: newQuote,
            source: newSource,
        }).then(() => {
            refetch();
            setNewQuote("");
            setNewSource("");
            quoteInputRef.current.focus();
        });
    };

    const deleteQuote = async (id: number) => {
        await api.delete(`quotes/${id}`);
        refetch();
    };

    return (
        <section className="flex flex-col gap-[30px]">
            <h1>Quotes 🤔</h1>
            <form
                onSubmit={handleAddQuote}
                className="flex flex-col gap-4 border-theme-border border-[1px] border-solid p-3 max-w-[800px]"
            >
                <h2 className="mb-0">Add New Quote</h2>
                <TextInput
                    name="quote"
                    displayLabel="Quote"
                    type="textarea"
                    value={newQuote}
                    onChange={setNewQuote}
                    innerRef={quoteInputRef}
                />
                <TextInput
                    name="source"
                    displayLabel="Source"
                    value={newSource}
                    onChange={setNewSource}
                />
                <button className="btn btn-primary">Add</button>
            </form>
            {quotes && (
                <section>
                    <h2>Quotes</h2>
                    <ul>
                        {quotes.map((quote, index) => (
                            <li
                                key={index}
                                className="flex gap-[8px] items-center text-[20px] mb-2"
                            >
                                "{quote.quote}"
                                {quote.source && <> - {quote.source}</>}
                                {currentUser.id === quote.createdBy && (
                                    <button
                                        type="button"
                                        onClick={() => deleteQuote(quote.id)}
                                    >
                                        <Trash className="mt-[6px]" />
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
                </section>
            )}
        </section>
    );
};

export default QuotesHome;
