import React from "react";

import { useApi } from "common/hooks/useApi";

import Loading, { LoadingType } from "common/components/Loading";

import { Ingredient, KitchenIngredient } from "cooking/models/types";

type IngredientWithKi = Ingredient & { kitchenIngredient?: KitchenIngredient };

type IngredientsByTagProps = {
    title: string;
    ingredients: Ingredient[];
    justKitchenIngredients?: boolean;
    openIngredientModal: (
        ki: KitchenIngredient | undefined,
        i: Ingredient | undefined
    ) => void;
};

const IngredientsByTag: React.FC<IngredientsByTagProps> = ({
    title,
    ingredients,
    justKitchenIngredients,
    openIngredientModal,
}) => {
    const { data: kitchenIngredients, isLoading } =
        useApi<KitchenIngredient>("kitchen_ingredient");

    let ingredientsWithKi: IngredientWithKi[] =
        ingredients?.map((i) => ({
            ...i,
            kitchenIngredient: kitchenIngredients?.find(
                (ki) => ki.ingredient.id === i.id
            ),
        })) ?? [];

    ingredientsWithKi = ingredientsWithKi
        .filter((i) =>
            justKitchenIngredients ? i.kitchenIngredient : !i.kitchenIngredient
        )
        .sort((a, b) => a.name.localeCompare(b.name)); // sort alphabetically

    const NO_TAGS_KEY = "no tags";
    const ingredientsByTag: { [tag: string]: IngredientWithKi[] } = {};

    ingredientsWithKi.forEach((i) => {
        const appendToMaybeArray = (key: string) => {
            if (!ingredientsByTag[key]) ingredientsByTag[key] = []; // create the array if it doesn't exist
            ingredientsByTag[key].push(i);
        };

        const ingredientTags = i.tags;

        if (ingredientTags.length === 0) {
            appendToMaybeArray(NO_TAGS_KEY);
        } else {
            ingredientTags.forEach((tag) => {
                appendToMaybeArray(tag.content.toLowerCase());
            });
        }
    });

    const orderedTags = Object.keys(ingredientsByTag)
        .map((t) => t.toLowerCase())
        .sort();

    if (ingredientsWithKi.length === 0 && !isLoading) return undefined;

    return (
        <section>
            <h2 className="underline mb-2">{title}</h2>
            {isLoading && <Loading type={LoadingType.coffee} />}
            {!isLoading && (
                <div className="flex gap-[60px] w-full flex-wrap">
                    {orderedTags?.map((tag) => {
                        return (
                            <div
                                key={tag}
                                className={`flex flex-col gap-[20px] shrink-0 ${tag === NO_TAGS_KEY ? "order-1" : ""
                                    }`}
                            >
                                <h3 className="capitalize">{tag}</h3>
                                <ul className="p-0 w-full flex flex-col border-solid border-theme-border border-[1px] rounded-[10px] overflow-hidden">
                                    {ingredientsByTag[tag]?.map((i, index) => (
                                        <li
                                            key={index}
                                            className={`border-b-[1px] border-solid border-theme-border ${justKitchenIngredients &&
                                                    !i.kitchenIngredient?.inStock
                                                    ? "opacity-50 order-1"
                                                    : ""
                                                }`}
                                        >
                                            <button
                                                className="py-[5px] px-[10px] text-[18px] w-full text-left"
                                                onClick={() =>
                                                    openIngredientModal(
                                                        i.kitchenIngredient,
                                                        i
                                                    )
                                                }
                                            >
                                                {i.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            )}
        </section>
    );
};

export default IngredientsByTag;
