import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type LinkIconProps = {
    className?: string;
    size?: string;
};

const LinkIcon: React.FC<LinkIconProps> = ({
    className = "",
    size = "20px",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35 35"
            version="1.1"
            x="0px"
            y="0px"
            className={className}
            style={{ height: size, width: size }}
        >
            <title>Link</title>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M16.4,26.1 L12.5,30 C11.1,31.4 8,30.8 5.9,28.7 C4.8,27.6 4.1,26.2 3.9,24.9 C3.7,23.7 4,22.7 4.6,22.1 L12,14.7 C12.7,14.1 13.7,13.8 14.8,14 C16.1,14.2 17.5,14.9 18.6,16 C19.1,16.5 19.6,17.1 19.9,17.7 C20.3,18.4 21.2,18.7 21.9,18.3 C22.6,17.9 22.9,17 22.5,16.3 C22,15.5 21.4,14.7 20.7,14 C19.1,12.4 17.2,11.4 15.2,11.1 C13.1,10.8 11.1,11.3 9.8,12.7 L2.4,20.1 C1.1,21.4 0.5,23.4 0.8,25.5 C1.1,27.5 2.1,29.4 3.7,31 C5.6,32.9 8.1,34 10.3,34 C11.9,34 13.4,33.5 14.5,32.3 L18.4,28.4 C19,27.8 19,26.9 18.4,26.3 C17.9,25.5 17,25.5 16.4,26.1 L16.4,26.1 Z M34.4,8.7 C34.2,6.6 33.2,4.6 31.6,3 C30,1.4 28,0.4 25.9,0.2 C23.7,-1.13242749e-14 21.7,0.6 20.3,2.1 L15.5,6.9 C14.9,7.5 14.9,8.4 15.5,9 C16.1,9.6 17,9.6 17.6,9 L22.4,4.2 C23.2,3.4 24.3,3.1 25.6,3.2 C27,3.3 28.4,4 29.5,5.1 C30.6,6.2 31.3,7.6 31.4,9 C31.5,10.3 31.2,11.4 30.4,12.2 L22.2,20.4 C21.4,21.2 20.3,21.5 19,21.4 C17.6,21.3 16.2,20.6 15.1,19.5 C14.5,18.9 14,18.2 13.7,17.5 C13.4,16.7 12.5,16.4 11.7,16.8 C10.9,17.1 10.6,18 11,18.8 C11.5,19.9 12.2,20.8 13,21.7 C14.6,23.3 16.6,24.3 18.7,24.5 L19.5,24.5 C21.4,24.5 23,23.8 24.3,22.6 L32.5,14.4 C33.9,12.9 34.6,10.9 34.4,8.7 L34.4,8.7 Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default LinkIcon;
