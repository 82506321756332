import React from "react";
import { Draggable } from "react-beautiful-dnd";

import { Player } from "fantasyFootball/models/types";

type PlayerRowProps = {
    player: Player;
    index: number;
};

const PlayerRow: React.FC<PlayerRowProps> = ({ player, index }) => {
    return (
        <Draggable draggableId={player.id.toString()} index={index}>
            {(provided) => (
                <tr
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <td>{player.name}</td>
                    <td>{player.position}</td>
                </tr>
            )}
        </Draggable>
    );
};

export default React.memo(PlayerRow); // memo so that every row doesn't re-render a bunch of times when dragging
