import React from "react";
import ViewRecipeIngredients from "./ViewRecipeIngredients";

import { ComplexStep } from "cooking/models/types";

type ViewRecipeStepSingleProps = {
    step: ComplexStep;
};

const ViewRecipeStepSingle: React.FC<ViewRecipeStepSingleProps> = ({
    step,
}) => {
    return (
        <div className="pl-[10px] flex flex-col gap-[8px] shadow-crisp-border shadow-theme-background-accent p-[10px] rounded-lg">
            {step.ingredients.length > 0 && (
                <div className="flex flex-col gap-[5px]">
                    <h4 className="text-[20px]">Ingredients</h4>
                    <ViewRecipeIngredients
                        ingredients={step.ingredients}
                        className="!items-baseline"
                    />
                </div>
            )}
            <div className="text-[18px]">{step.text}</div>
        </div>
    );
};

export default ViewRecipeStepSingle;
