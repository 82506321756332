import React from "react";
const useMousePosition = (manualMode: boolean = false) => {
    const [mousePosition, setMousePosition] = React.useState({
        x: null,
        y: null,
    });

    const updateMousePosition = (ev) => {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    const activate = () => {
        window.addEventListener("mousemove", updateMousePosition);
    };

    const deactivate = () => {
        window.removeEventListener("mousemove", updateMousePosition);
    };

    // React.useEffect(() => {
    //     if (!manualMode) activate();

    //     return deactivate;
    // }, [manualMode, activate, deactivate]);

    return { mousePosition, activate, deactivate };
};
export default useMousePosition;
