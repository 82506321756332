// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#wim-mode {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;
  background-color: red;
  padding: 2px 5px;
  border-radius: 0 0 0 10px; }
`, "",{"version":3,"sources":["webpack://./src/wim/styles/wim.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,YAAY;EACZ,qBAAqB;EAErB,gBAAgB;EAChB,yBAAyB,EAAA","sourcesContent":["#wim-mode {\n    position: absolute;\n    top: 0;\n    right: 0;\n    z-index: 101;\n    background-color: red;\n\n    padding: 2px 5px;\n    border-radius: 0 0 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
