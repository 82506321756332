import React from "react";

import useModal from "foundation/modals/useModal";

import Confirmation from "foundation/components/Confirmation";

/**
 * React hook for showing a confirmation dialog for
 *
 * Use like so:
 *
 * const confirm = useConfirmation();
 *
 * const confirmSomething = () => {
 *     confirm("Are you sure?", () => alert("You said yes!"), () => alert("You said no!"));
 * };
 *
 * return (
 *     <button type="button" onClick={confirmSomething}>Confirm Something for me please!</button>
 * );
 */

export const useConfirmation = () => {
    const { newModal } = useModal();

    const confirm = (
        text: string,
        onConfirm?: () => void,
        onCancel?: () => void
    ) => {
        return new Promise<boolean>((resolve) => {
            const _onConfirm = () => {
                resolve(true);
                onConfirm?.();
            };

            const _onCancel = () => {
                resolve(false);
                onCancel?.();
            };

            newModal(
                <Confirmation
                    text={text}
                    onConfirm={_onConfirm}
                    onCancel={_onCancel}
                />,
                {
                    // onClose: _onCancel,
                    className: "confirmation-modal",
                    // sectionClassName: "confirmation-modal-section",
                }
            );
        });
    };

    return confirm;
};

export default useConfirmation;
