import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import "foundation/styles/App.scss";
import "foundation/styles/Theme.scss";
import "foundation/modals/modals.scss";
import "foundation/styles/Flex.scss";
import "foundation/styles/Inputs.scss";

import ErrorPage from "./ErrorPage";
import Providers from "foundation/components/Providers";
import Routes from "foundation/components/Routes";
import NavBar from "foundation/components/NavBar";
import Themer from "settings/components/Themer";
import Wim from "wim/components/Wim";

import NotesSidebar from "devNotes/components/NotesSidebar";

import Modals from "foundation/modals/Modal";
import appStore from "foundation/stores/AppStore";
import devNoteSidebarStore from "devNotes/stores/DevNoteSidebarStore";
import { AppsEnum } from "foundation/apps";

const App: React.FC = () => {
    const currentApp = appStore.useTracked.currentApp();
    const isDevNoteSidebarOpen = devNoteSidebarStore.useTracked.isOpen();

    let appSpecificClassName = "";
    if (currentApp === AppsEnum.Rememberall) {
        appSpecificClassName = "no-side-buffer";
    }

    return (
        <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorPage}>
                <Providers>
                    <Wim>
                        <Themer>
                            <div className="flex w-full">
                                <div
                                    className={`${isDevNoteSidebarOpen
                                            ? "w-[70%]"
                                            : "grow"
                                        }`}
                                >
                                    <NavBar />
                                    <main
                                        className={`container flex pb-[30%] ${appSpecificClassName} ${isDevNoteSidebarOpen
                                                ? "!px-[30px]"
                                                : ""
                                            }`}
                                    >
                                        <Routes />
                                    </main>
                                </div>
                                <NotesSidebar />
                            </div>
                            <div id="modal-root" />
                            <Modals />
                        </Themer>
                    </Wim>
                </Providers>
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default App;
