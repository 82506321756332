// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/auth/user.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-field {
  border-radius: 0.25rem; }
  .login-field > input {
    order: 1;
    border-radius: 0.25rem; }
  .login-field .input-group-prepend {
    order: 0;
    border-radius: 0.25rem; }
    .login-field .input-group-prepend > span {
      border-radius: 0.25rem; }

.login-user-icon {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat 0 0; }
`, "",{"version":3,"sources":["webpack://./src/auth/styles/login.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAA;EADxB;IAII,QAAQ;IACR,sBAAsB,EAAA;EAL1B;IASI,QAAQ;IACR,sBAAsB,EAAA;IAV1B;MAaM,sBAAsB,EAAA;;AAK5B;EACE,iEAAsD,EAAA","sourcesContent":[".login-field {\n  border-radius: 0.25rem;\n\n  > input {\n    order: 1;\n    border-radius: 0.25rem;\n  }\n\n  .input-group-prepend {\n    order: 0;\n    border-radius: 0.25rem;\n\n    > span {\n      border-radius: 0.25rem;\n    }\n  }\n}\n\n.login-user-icon {\n  background: url(\"/images/auth/user.png\") no-repeat 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
