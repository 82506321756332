import React, { useState } from "react";
import useModal from "foundation/modals/useModal";
import { useApi } from "common/hooks/useApi";
import { useGetApi } from "common/hooks/useGetApi";

import Trash from "foundation/svgComponents/Trash";
import TextInput from "foundation/components/forms/TextInput";
import PlusButton from "foundation/components/PlusButton";
import KitchenIngredientDetailsModal from "./KitchenIngredientDetailsModal";

import {
    Ingredient,
    KitchenIngredient,
    RecipeIngredient,
} from "cooking/models/types";

type MapRecipeIngredientToIngredientModalProps = {
    recipeIngredient: RecipeIngredient;
    handleIngredientClick: (ingredientToLinkToId: number) => void;
};

const MapRecipeIngredientToIngredientModal: React.FC<
    MapRecipeIngredientToIngredientModalProps
> = ({ recipeIngredient, handleIngredientClick }) => {
    const { newModal, closeModal } = useModal();
    const [filterText, setFilterText] = useState("");
    const { data: ingredients } = useGetApi<Ingredient[]>("ingredient");

    const recipeIngredientName =
        recipeIngredient.name || recipeIngredient.originalText;

    const filteredIngredients = ingredients?.filter((i) =>
        i.name.toLowerCase().includes(filterText.toLowerCase())
    );

    const recipeIngredientParts = recipeIngredientName.split(" ");
    const suggestedIngredients = ingredients?.filter((i) => {
        if (i.id === recipeIngredient.ingredient) {
            // include ingredient if it's already linked
            return true;
        }

        for (const part of recipeIngredientParts) {
            if (!part.trim()) continue;

            if (i.name.toLowerCase().includes(part.toLowerCase())) {
                return true;
            }
        }
        return false;
    });

    const {
        createItem: createIngredient,
        updateTempItemInCache: updateTempIngredient,
    } = useApi<Ingredient>("ingredient", undefined, true);

    const { createItem: addKitchenIngredient } = useApi<KitchenIngredient>(
        "kitchen_ingredient",
        undefined,
        true
    );

    const openIngredientModal = () => {
        newModal(
            <KitchenIngredientDetailsModal
                kitchenIngredient={undefined}
                ingredient={undefined}
                trackButtonTextOverride="Link"
                handleSave={async (updatedKi) => {
                    if (updatedKi.inStock) {
                        // auto-track in kitchen if they marked inStock
                        createIngredient(updatedKi.ingredient, undefined, true); // add to cache

                        // adding to kitchen
                        const createdKi = await addKitchenIngredient(
                            updatedKi,
                            true
                        );

                        updateTempIngredient(createdKi.ingredient);

                        handleIngredientClick(createdKi.ingredient.id);
                    } else {
                        // Otherwise, just create the ingredient without tracking
                        const createdIngredient = await createIngredient(
                            updatedKi.ingredient,
                            true
                        );
                        handleIngredientClick(createdIngredient.id);
                    }
                }}
            />
        );
    };

    return (
        <div className="p-[10px] flex flex-col gap-[20px]">
            <h1 className="font-[600]">Map "{recipeIngredientName}" to...</h1>

            <TextInput
                name="Filter"
                placeholder="Filter..."
                value={filterText}
                onChange={(newVal) => setFilterText(newVal)}
            />

            <PlusButton
                text="Add New"
                size="20px"
                className="w-fit"
                onClick={() => openIngredientModal()}
            />

            {suggestedIngredients?.length > 0 && (
                <div className="flex flex-col gap-[5px]">
                    <h3 className="mb-[5px]">Suggested</h3>
                    {suggestedIngredients?.map((ingredient, index) => (
                        <button
                            key={index}
                            onClick={() => handleIngredientClick(ingredient.id)}
                            className={
                                ingredient.id === recipeIngredient.ingredient
                                    ? "bg-theme-text text-theme-background"
                                    : ""
                            }
                        >
                            {ingredient.name}
                        </button>
                    ))}
                </div>
            )}

            <div className="flex flex-col gap-[5px]">
                {suggestedIngredients?.length > 0 && (
                    <h3 className="mb-[5px]">All</h3>
                )}
                {filteredIngredients?.map((ingredient, index) => (
                    <button
                        key={index}
                        onClick={() => handleIngredientClick(ingredient.id)}
                        className={
                            ingredient.id === recipeIngredient.ingredient
                                ? "bg-theme-text text-theme-background"
                                : ""
                        }
                    >
                        {ingredient.name}
                    </button>
                ))}
            </div>

            <footer className="sticky bottom-[-15px] py-[15px] bg-theme-background flex justify-center gap-[20px]">
                {recipeIngredient.ingredient && (
                    <button
                        onClick={() => handleIngredientClick(null)}
                        className="btn btn-danger flex gap-[5px] items-center"
                    >
                        Unlink <Trash color="currentColor" size="20px" />
                    </button>
                )}
                <button onClick={closeModal} className="btn btn-secondary">
                    Close
                </button>
            </footer>
        </div>
    );
};

export default MapRecipeIngredientToIngredientModal;
