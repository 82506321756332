import { useEffect, useRef } from "react";

const useEventListener = (
    eventName: string,
    handler: (event: KeyboardEvent) => void,
    element = window
) => {
    const savedHandler = useRef(undefined);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const eventListener = (event: KeyboardEvent) =>
            savedHandler.current(event);
        element.addEventListener(eventName, eventListener);
        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
};

export default useEventListener;
