import { useEffect, useRef } from "react";
import { useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import api from "helpers/api.helpers";
import useOnScreen from "./useOnScreen";

const isOffline = process.env.OFFLINE_MODE;

interface PaginatedResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

interface UsePaginatedApiOptions<T> {
    pageSize?: number;
    postprocess?: (data: T[]) => T[];

    query?: { [queryParam: string]: string };

    skip?: boolean;
    disableAllRefetch?: boolean;
    refetchOnWindowFocus?: boolean;
    refetchOnReconnect?: boolean;
    refetchOnMount?: boolean;
}

// for some reason this doesn't seem to work in offline mode
//   calling api.get in a useEffect works just fine though 🤷
export function useGetApiPaginated<T>(
    getUrl: string,
    {
        query = {},
        pageSize = 10,
        postprocess,
        skip,
        disableAllRefetch,
        refetchOnWindowFocus,
        refetchOnReconnect,
        refetchOnMount,
    }: UsePaginatedApiOptions<T>
) {
    const queryClient = useQueryClient();

    const queryKey = [getUrl, postprocess, pageSize, query];

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        refetch,
        isRefetching,
    } = useInfiniteQuery<PaginatedResponse<T>>(
        [queryKey],
        async ({ pageParam = 1 }) => {
            let url = `${getUrl}?page=${pageParam}&page_size=${pageSize}`;

            const queryString = Object.keys(query)
                .map((k) => {
                    const value = query[k];
                    return value ? `${k}=${value}` : undefined;
                })
                .filter((x) => Boolean(x))
                .join("&");
            if (queryString) {
                url = `${url}&${queryString}`;
            }
            const response = await api.get(url);

            if (!response) return null;

            const { data: paginatedData } = response;

            if (postprocess) {
                return {
                    ...paginatedData,
                    results: postprocess(paginatedData.results),
                };
            }

            return paginatedData;
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage?.next) return undefined;
                return allPages.length + 1;
            },
            keepPreviousData: true,
            enabled: !skip && !isOffline,
            refetchOnWindowFocus: refetchOnWindowFocus && !disableAllRefetch,
            refetchOnReconnect: refetchOnReconnect && !disableAllRefetch,
            refetchOnMount: refetchOnMount && !disableAllRefetch,
        }
    );

    // Combine all results from all pages
    const flatResults =
        data?.pages.flatMap((page) => page?.results ?? []) ?? [];
    const totalCount = data?.pages[0]?.count ?? 0;

    // 🔁 infinite scroll logic
    const infiniteScrollRef = useRef<HTMLElement>(null);
    const { onScreen } = useOnScreen(infiniteScrollRef, { offset: 50 });
    useEffect(() => {
        if (onScreen && hasNextPage) fetchNextPage();
    }, [fetchNextPage, hasNextPage, onScreen]);

    const setData = (data: any) => {
        console.log("TODO paginated set data");
        return;
        // queryClient.setQueryData(queryKey, data);
    };

    const appendData = (
        dataElement: T,
        preferArray: boolean = false,
        prepend: boolean = false
    ) => {
        console.log("TODO paginated append data");
        return;
        /* if (Array.isArray(data)) {
        if (prepend) {
            setData([dataElement, ...data]);
        } else {
            setData([...data, dataElement]);
        }
    } else if (data === undefined && preferArray) {
        setData([dataElement]);
    } else {
        setData(dataElement);
    } */
    };

    const deleteData = (dataElement: T) => {
        console.log("TODO paginated delete data");
        return;
        /* if (Array.isArray(data)) {
        // @ts-ignore
        setData(data?.filter((d) => d.id !== dataElement.id));
    } else {
        setData(undefined);
    } */
    };

    // offline shenanigans
    /* const [refetchTrigger, setRefetchTrigger] = useState(false);
const offlineRefetch = () => setRefetchTrigger(!refetchTrigger);

const [offlineData, setOfflineData] = useState<PaginatedResponse<T>>();

useEffect(() => {
    if (isOffline) {
        api.get(getUrl).then((res) => setOfflineData(res?.data));
    }
}, [refetchTrigger, getUrl]); */

    return {
        data: flatResults,
        isLoading,
        refetch,
        isRefetching,
        isFetchingNextPage,
        hasNextPage,
        totalCount,
        infiniteScrollRef,
    };

    /* return {
        data: isOffline ? offlineData?.results : flatResults,
        totalResults: isOffline ? offlineData?.count : totalCount,
        refetch: isOffline ? offlineRefetch : refetch,
        isLoading: isOffline ? false : isLoading, // isLoading is always true offline
        isRefetching: isOffline ? false : isRefetching,
        setData,
        appendData,
        deleteData,
        // totalPages,
        hasNextPage,
        // hasPreviousPage,
    }; */
}

export default useGetApiPaginated;
