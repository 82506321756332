// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.player-ranking-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around; }
  .player-ranking-section .unranked-players-section,
  .player-ranking-section .ranked-players-section {
    min-width: 200px; }

.is-being-dragged {
  opacity: 0.5; }
`, "",{"version":3,"sources":["webpack://./src/fantasyFootball/styles/draftPrep.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,6BAA6B,EAAA;EAHjC;;IAOQ,gBAAgB,EAAA;;AAIxB;EACI,YAAY,EAAA","sourcesContent":[".player-ranking-section {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n\n    .unranked-players-section,\n    .ranked-players-section {\n        min-width: 200px;\n    }\n}\n\n.is-being-dragged {\n    opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
