import React, { useEffect, useState } from "react";
import api from "helpers/api.helpers";
import useModal from "foundation/modals/useModal";

import CreateIngredientModal from "cooking/components/CreateIngredientModal";
import EditIngredientModal from "cooking/components/EditIngredientModal";
import IngredientCard from "cooking/components/IngredientCard";

import { Ingredient } from "cooking/models/types";
import { cloneDeep } from "lodash";

const ManageIngredients: React.FC = () => {
    const { newModal } = useModal();

    const openCreateIngredientModal = () => {
        newModal(<CreateIngredientModal />);
    };

    const [ingredients, setIngredients] = useState<Ingredient[]>([]);

    useEffect(() => {
        api.get("ingredient")
            .then(({ data }) => {
                setIngredients(data);
            })
            .catch((err) => console.log(err));
    }, []);

    // TODO add newly created ingredient to ingredients list

    const deleteIngredient = (ingredientToDelete: Ingredient) => {
        const oldIngredients = cloneDeep(ingredients); // in case delete fails

        // delete the ingredient from the UI immediately
        setIngredients(
            ingredients.filter((i) => i.id !== ingredientToDelete.id)
        );

        api.delete(`ingredient/${ingredientToDelete.id}`).catch((err) => {
            console.error(err);
            setIngredients(oldIngredients);
        });
    };

    const editIngredient = (ingredientToEdit: Ingredient) => {
        newModal(
            <EditIngredientModal
                ingredient={ingredientToEdit}
                onSubmit={(updatedIngredient) => {
                    const updatedIngredients = ingredients.map((i) => {
                        return i.id === updatedIngredient.id
                            ? updatedIngredient
                            : i;
                    });
                    setIngredients(updatedIngredients);
                }}
            />
        );
    };

    return (
        <div className="flex flex-col gap-[20px]">
            <h1>Manage Ingredients</h1>
            <button
                className="btn btn-primary w-fit mx-auto"
                onClick={openCreateIngredientModal}
            >
                Add Ingredient
            </button>
            {ingredients && (
                <section className="flex flex-wrap">
                    {ingredients.map((ingredient) => (
                        <IngredientCard
                            key={ingredient.id}
                            ingredient={ingredient}
                            editIngredient={editIngredient}
                            deleteIngredient={deleteIngredient}
                        />
                    ))}
                </section>
            )}
        </div>
    );
};

export default ManageIngredients;
