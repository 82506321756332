import React from "react";

import useTransactions from "money/hooks/useTransactions";
import useDiscreteTransactions from "money/hooks/useDiscreteTransactions";

import discreteStore from "money/stores/DiscreteStore";

const MonthlyNetIncome: React.FC = () => {
    const { filteredExpenses, filteredIncome } = useTransactions();

    const discreteModeOn = discreteStore.useTracked.discreteModeOn();
    const { discreteExpenses, discreteIncome } = useDiscreteTransactions();

    const expenses = discreteModeOn ? discreteExpenses : filteredExpenses;
    const income = discreteModeOn ? discreteIncome : filteredIncome;

    let total = 0;
    expenses.forEach((e) => (total -= e.amount));
    income.forEach((i) => (total += i.amount));

    const textColorClassName =
        total >= 0 ? "text-theme-button-primary" : "text-theme-button-warning";

    return (
        <h2 className="!m-0">
            This Month: <span className={textColorClassName}>${total}</span>
        </h2>
    );
};

export default MonthlyNetIncome;
