import { useModalActionsContext } from "foundation/modals/ModalsContext";

import {
    ModalTypes,
    ModalType,
    ModalOptions,
    ModalComponent,
} from "foundation/modals/ModalTypes";

const useModal = ({
    desktop = ModalTypes.Center,
    mobile = ModalTypes.Mobile,
}: Partial<ModalType> = {}) => {
    const {
        newModal: _newModal,
        replaceModal,
        closeModal,
        closeAllModals,
    } = useModalActionsContext();

    const newModal = (
        component: ModalComponent,
        options?: ModalOptions,
        type: ModalType = { desktop, mobile }
    ) => {
        _newModal(component, type, options);
    };

    return { newModal, replaceModal, closeModal, closeAllModals };
};

export default useModal;
