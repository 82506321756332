import React from "react";
import { useNavigate } from "react-router-dom";
import useCreateRecipe from "cooking/hooks/useCreateRecipe";
import createRecipeStore from "cooking/stores/CreateRecipeStore";

import TextInput from "foundation/components/forms/TextInput";
import ComplexRecipeIngredients from "cooking/components/ComplexRecipeIngredients";
import ComplexStepSection from "cooking/components/ComplexStepSection";
import RecipeFieldsNew from "./RecipeFieldsNew";
import Loading, { LoadingType } from "common/components/Loading";

type CreateRecipeProps = {};

const CreateRecipeNew: React.FC<CreateRecipeProps> = () => {
    const {
        recipe,
        updateRecipe,
        handleSubmit: _handleSubmit,
        isIngredientLinkActive,
        clearIngredientLink,
        isSubmitting,
        submitError,
        isEdit,
    } = useCreateRecipe();

    const navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        await _handleSubmit(e);
    };

    if (!recipe) {
        return <div>Loading...</div>;
    }

    // console.log("🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆");
    // console.log("recipe:", { ...recipe });

    const error = submitError ? (
        <span className="text-theme-button-danger text-[22px]">
            {submitError}
        </span>
    ) : undefined;

    const saveButton = (
        <button
            type="button"
            onClick={handleSubmit}
            className="btn btn-primary mt-4 w-fit disabled:opacity-70"
            disabled={isSubmitting || !recipe.name}
        >
            {isEdit && (
                <>
                    {!isSubmitting && "Save"}
                    {isSubmitting && "Saving..."}
                </>
            )}
            {!isEdit && (
                <>
                    {!isSubmitting && "Create"}
                    {isSubmitting && "Creating..."}
                </>
            )}
        </button>
    );

    return (
        <div className="relative">
            {isSubmitting && (
                <div className="absolute h-full w-full opacity-90 bg-theme-background z-50 flex justify-center items-center flex-col gap-[10px]">
                    <Loading type={LoadingType.coffee} className="scale-150" />
                    <span className="text-[22px]">
                        {isEdit ? "Updating" : "Creating"} recipe...
                    </span>
                </div>
            )}
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                className="flex flex-col mx-auto gap-[30px] relative"
            >
                <div className="w-full flex justify-between items-center">
                    <button
                        onClick={() => {
                            createRecipeStore.set.recipe(undefined);
                            navigate(-1);
                        }}
                        className="btn btn-secondary w-fit disabled:opacity-70"
                        disabled={isSubmitting}
                    >
                        Back
                    </button>
                    {saveButton}
                </div>

                {isIngredientLinkActive && (
                    <button
                        type="button"
                        className="absolute z-[1] h-full w-full opacity-80 bg-theme-background"
                        onClick={clearIngredientLink}
                    />
                )}

                {error}
                <TextInput
                    name="name"
                    placeholder="Recipe Name"
                    displayLabel="Recipe name"
                    extraInputClasses="h-[40px]"
                    value={recipe.name}
                    onChange={(newName) => updateRecipe("name", newName)}
                    autoComplete={false}
                />
                <RecipeFieldsNew recipe={recipe} updateRecipe={updateRecipe} />
                <ComplexRecipeIngredients
                    ingredients={recipe.ingredients}
                    setIngredients={(i) => updateRecipe("ingredients", i)}
                />
                <ComplexStepSection
                    // @ts-ignore
                    steps={recipe.steps}
                    setSteps={(s) => updateRecipe("steps", s)}
                />
                {error}
                {saveButton}
            </form>
        </div>
    );
};

export default CreateRecipeNew;
