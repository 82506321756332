import React, { ReactNode } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import ModalsProvider from "foundation/modals/ModalsContext";

const queryClient = new QueryClient();

const Providers: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <ModalsProvider>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </ModalsProvider>
    );
};

export default Providers;
