import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetApi } from "common/hooks/useGetApi";
import { callApiMethod } from "helpers/api.helpers";

import "fantasyFootball/styles/manageLeagues.scss";

import SelectInput from "foundation/components/forms/SelectInput";
import RefreshIcon from "foundation/svgComponents/RefreshIcon";

import { FantasyLeague } from "fantasyFootball/models/types";

const ManageFantasyLeagues: React.FC = () => {
    const { data: fantasyLeagues, setData: setFantasyLeagues } =
        useGetApi<FantasyLeague[]>("fantasy_league");
    const [selectedLeague, setSelectedLeague] = useState<
        FantasyLeague | undefined
    >(fantasyLeagues?.[0]);
    const [loadingLeagueInfo, setLoadingLeagueInfo] = useState(false);

    useEffect(() => {
        setSelectedLeague(fantasyLeagues?.[0]);
    }, [fantasyLeagues]);

    const importYahooLeague = (leagueId: String) => {
        setLoadingLeagueInfo(true);
        callApiMethod(`fantasy_football/import_yahoo_league/${leagueId}`)
            .then((res) => {
                if (res.data.success) {
                    const importedLeague = res.data.league;
                    setFantasyLeagues([
                        importedLeague,
                        ...fantasyLeagues.filter(
                            (league) => league.id !== importedLeague.id
                        ),
                    ]);
                } else {
                    console.error(res.data.errorMessage);
                }
            })
            .finally(() => setLoadingLeagueInfo(false));
    };

    console.log("selectedLeague:", selectedLeague);

    return (
        <section>
            <h1>Manage Fantasy Leagues here...</h1>

            <Link to="create">
                <button className="btn btn-primary">
                    Create Fantasy Leagues
                </button>
            </Link>

            {fantasyLeagues && selectedLeague && (
                <section>
                    <div className="league-select-wrapper flex-row">
                        <SelectInput
                            name="selectedLeague"
                            className="manage-league-select"
                            displayLabel="League"
                            selectedValue={selectedLeague?.id.toString()}
                            choices={fantasyLeagues.map((league) => [
                                league.id.toString(),
                                league.name,
                            ])}
                            onChange={(selectedId) =>
                                setSelectedLeague(
                                    fantasyLeagues.find(
                                        (l) => l.id.toString() === selectedId
                                    )
                                )
                            }
                            rightIcon={
                                <RefreshIcon
                                    onClick={() =>
                                        importYahooLeague(
                                            selectedLeague.yahooLeagueId
                                        )
                                    }
                                    refreshing={loadingLeagueInfo}
                                />
                            }
                        ></SelectInput>
                    </div>

                    {selectedLeague.teams && selectedLeague.teams.length > 0 && (
                        <section>
                            <h2>Teams</h2>
                            {selectedLeague.teams.map((team) => (
                                <div key={team.id}>
                                    {team.rank !== 0 && `${team.rank}. `}
                                    {team.name}
                                    <p>(Points: {team.totalPoints ?? "0"})</p>
                                </div>
                            ))}
                        </section>
                    )}

                    {selectedLeague.settings && (
                        <section>
                            <h2>League Info</h2>
                            <div>
                                Teams: {selectedLeague.settings.numTeams ?? "?"}
                                /{selectedLeague.settings.maxTeams ?? "?"}
                            </div>
                            <div>
                                Playoff Teams:{" "}
                                {selectedLeague.settings.numPlayoffTeams ?? "?"}
                            </div>
                            <div>
                                Current Week:{" "}
                                {selectedLeague.settings.currentWeek ?? "?"}
                            </div>
                            <div>
                                Playoff Start Week:{" "}
                                {selectedLeague.settings.playoffStartWeek ??
                                    "?"}
                            </div>
                            <br />
                            <h3>Roster Slots</h3>
                            <ul>
                                {selectedLeague.settings?.rosterSlots.map(
                                    (slot) => (
                                        <li key={slot.position}>
                                            {slot.position}: {slot.count}
                                        </li>
                                    )
                                )}
                            </ul>
                        </section>
                    )}
                </section>
            )}
        </section>
    );
};

export default ManageFantasyLeagues;
