import React from "react";

import IngredientDisplay from "cooking/components/IngredientDisplay";
import X from "foundation/svgComponents/X";

import { RecipeIngredient } from "cooking/models/types";

type ComplexStepIngredientSectionProps = {
    ingredients: RecipeIngredient[];
    updateStepField: (field: string, newValue: any) => void;
};

const ComplexStepIngredientSection: React.FC<
    ComplexStepIngredientSectionProps
> = ({ ingredients, updateStepField }) => {
    if (ingredients.length === 0) return <></>;

    return (
        <div>
            <h4 className="underline text-[18px]">Ingredients</h4>
            <ul className="pl-[12px]">
                {ingredients.map((i) => (
                    <li key={i.id} className="flex gap-2">
                        <IngredientDisplay ingredient={i} />
                        <button
                            type="button"
                            onClick={() =>
                                updateStepField(
                                    "ingredients",
                                    ingredients.filter(
                                        (ingr) => ingr.id !== i.id
                                    )
                                )
                            }
                        >
                            <X size="14px" />
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ComplexStepIngredientSection;
