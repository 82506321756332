import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type DevNotesIconProps = {
    className?: string;
    color?: string;
};

const DevNotesIcon: React.FC<DevNotesIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 200 250"
                fill={color}
                className={className}
            >
                <path d="M134.4,144.3c-1.2,0-2.2-0.9-2.3-2.1c-0.1-1.3,0.9-2.4,2.1-2.5l19.5-1.5L146.4,41L47,48.6c-1.2,0.1-2.4-0.8-2.5-2.1  s0.8-2.4,2.1-2.5l101.7-7.7c1.4-0.1,2.4,0.8,2.5,2.1l7.7,101.7c0,0.6-0.2,1.2-0.6,1.7c-0.4,0.5-1,0.7-1.6,0.8l-21.8,1.6  C134.5,144.3,134.5,144.3,134.4,144.3z" />
                <path d="M116.8,150.5h-74c-1.3,0-2.3-1-2.3-2.3v-102c0-1.3,1-2.3,2.3-2.3h102c1.3,0,2.3,1,2.3,2.3v74  C147.1,137,133.5,150.5,116.8,150.5z M45,146h71.8c14.2,0,25.7-11.5,25.7-25.7V48.5H45V146z" />
                <path d="M117.3,150.5c-1.3,0-2.3-1-2.3-2.3v-28.4c0-1.3,1-2.3,2.3-2.3h27.5c1.3,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3h-25.2v26.2  C119.6,149.5,118.5,150.5,117.3,150.5z" />
                <path d="M58.9,81c-5.6,0-10.1-4.5-10.1-10.1V47.3c0-5.6,4.5-10.1,10.1-10.1c2.8,0,5.5,1.2,7.4,3.2c1.7,1.8,1.6,4.7-0.2,6.5  c-1.8,1.7-4.7,1.6-6.5-0.2c-0.3-0.3-1.7-0.2-1.7,0.7V71c0,1.1,1.9,1.1,1.9,0V55.2c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6v15.7  C69.1,76.5,64.5,81,58.9,81z" />
            </svg>
        </>
    );
};

export default DevNotesIcon;
