export enum AVAILABLE_THEMES {
    og = "OG Theme",
    test = "Test Switch",
    kanagawa = "The Great Wave of Kanagawa",
}

export const THEME_COLORS = {
    [AVAILABLE_THEMES.og]: {
        background: "darkgray",
        backgroundAccent: "#afafaf",
        nav: "#343a40",
        navText: "white",
        text: "black",
        textAccent: "grey",
        buttonPrimary: "#007bff",
        buttonPrimaryText: "white",
        buttonWarning: "#ffc107",
        buttonWarningText: "#212529",
        buttonDanger: "#dc3545",
        buttonDangerText: "white",
        inputBackground: "white",
        inputText: "#495057",
        inputTextForInverseBackground: "white",
        buttonGroup: "#6c757d",
        buttonGroupSelected: "#545b62",
        focusOutline: "#007bff", // only supports hex (bc "40" is appended for opacity)
    },
    [AVAILABLE_THEMES.test]: {
        background: "lightblue",
        backgroundAccent: "lightpink",
        nav: "red",
        navText: "black",
        text: "purple",
        textAccent: "yellow",
        buttonPrimary: "#007bff",
        buttonPrimaryText: "white",
        buttonWarning: "#ffc107",
        buttonWarningText: "#212529",
        buttonWarningBorder: "black",
        buttonDanger: "#dc3545",
        buttonDangerText: "white",
        buttonDangerBorder: "blue", // only needed if border is different than primary button color
        inputBackground: "white",
        inputText: "#495057",
        inputTextForInverseBackground: "white",
        inputSelected: "#545b62",
        buttonGroup: "lightblue",
        buttonGroupSelected: "blue",
        focusOutline: "#664422", // only supports hex (bc "40" is appended for opacity)
    },
    // https://github.com/rebelot/kanagawa.nvim#color-palette
    [AVAILABLE_THEMES.kanagawa]: {
        background: "#2A2A37",
        backgroundAccent: "#363646",
        nav: "#16161D",
        navText: "#DCD7BA",
        text: "#7FB4CA",
        textAccent: "#658594",
        buttonPrimary: "#957FB8",
        buttonPrimaryText: "#252535",
        buttonPrimaryBorder: "#54546D",
        buttonWarning: "#FFA066",
        buttonWarningText: "#252535",
        buttonWarningBorder: "#FFA066",
        buttonDanger: "#FF5D62",
        buttonDangerText: "#252535",
        buttonDangerBorder: "#E82424",
        inputBackground: "#E6C384",
        inputText: "#252535",
        inputTextForInverseBackground: "#252535",
        buttonGroup: "#D27E99",
        buttonGroupSelected: "#E46876",
        focusOutline: "#A3D4D5", // only supports hex (bc "40" is appended for opacity)
    },
};

// theme ideas:
//   water, fire, earth, air
//   seasons

export const DEFAULT_THEME_KEY = "kanagawa";
export const DEFAULT_THEME = AVAILABLE_THEMES[DEFAULT_THEME_KEY];
