import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type ArrowProps = {
    className?: string;
    direction?: "right" | "left" | "up" | "down";
};

const Arrow: React.FC<ArrowProps> = ({
    className = "",
    direction = "right",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    let path: JSX.Element;

    switch (direction) {
        case "up":
            path = (
                <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
            );
            break;
        case "down":
            path = (
                <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            );
            break;
        case "left":
            path = (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            );
            break;
        case "right":
        default:
            path = (
                <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
            );
    }

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill={color}
                className={className}
            >
                {path}
            </svg>
        </>
    );
};

export default Arrow;
