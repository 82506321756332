import React from "react";
import { Link } from "react-router-dom";

const ManageFantasyTeams: React.FC = () => {
    return (
        <section>
            <h1>Manage Fantasy Teams here...</h1>
            <p>
                Create leagues first (TODO make this conditional on having
                leagues)
            </p>
            <Link to="/fantasyFootball/manage/fantasyLeagues">
                <button className="btn btn-primary">
                    Manage Fantasy Leagues
                </button>
            </Link>
        </section>
    );
};

export default ManageFantasyTeams;
