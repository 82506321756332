import { NavigateFunction } from "react-router-dom";

import api from "./api.helpers";
import { SetState } from "./types";
import { setTokens, clearTokens } from "./token.helpers";

// Routes
const LOGIN_API = "token/"; // get access + refresh tokens in exchange for username + password
export const CURRENT_USER_API = "user/current/"; // get user profile if user is logged in (i.e. if tokens are present);
const CREATE_USER_API = "user/create/"; // creates a new user

export const login = (
    username: string,
    password: string,
    setError: SetState<string>,
    setCurrentUser: SetState<any>,
    navigate: NavigateFunction,
    redirectTo = "/"
) => {
    api.post(LOGIN_API, { username, password })
        .then((response) => {
            if (!response) {
                setError("Failed to log in");
                return;
            }

            const { data } = response;
            const { access, refresh } = data;

            setTokens(access, refresh);

            // setCurrentUser(getCurrentUser());  // cleaner but more annoying
            getCurrentUser(setCurrentUser);

            navigate(redirectTo);
        })
        .catch((error) => {
            if (error.response?.status === 500) {
                setError(`500 ${error.response.statusText}`);
            } else if (error.response?.data.detail) {
                setError(error.response.data.detail);
            } else {
                console.log("Error logging in: ");
                console.log(error);
                console.log(error.response);
            }
        });
};

export const getCurrentUser = (setCurrentUser: SetState<any>) => {
    api.get(CURRENT_USER_API)
        .then(({ data }) => {
            setCurrentUser(data);
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                // if we're not logged in, we're unauthorized. Working as intended
            } else {
                throw err;
            }
        });
};

export const signup = (
    username: string,
    password: string,
    setError: SetState<string>,
    setCurrentUser: SetState<any>,
    navigate: NavigateFunction,
    redirectTo = "/"
) => {
    api.post(CREATE_USER_API, { username, password })
        .then((res) => {
            const { user } = res.data;
            setCurrentUser(user);

            // // if we could grab tokens at the same time
            // const { access_token, refresh_token, ...user } = res.data;
            // setTokens(access_token, refresh_token);
            // navigate(redirectTo);
        })
        .then(() => {
            // to get tokens
            //   ideally we could just get the tokens on user creation... but I can't figure out how to do that rn and this works
            login(
                username,
                password,
                setError,
                setCurrentUser,
                navigate,
                redirectTo
            );
        })
        .catch((error) => {
            if (error.response?.status === 422) {
                const firstError =
                    error.response.data[Object.keys(error.response.data)[0]];
                setError(firstError);
            } else {
                console.log("Error signing up:", error);
            }
        });
};

export const logout = (setCurrentUser: SetState<any>) => {
    clearTokens();
    setCurrentUser(undefined);
};

// class AuthService {
//   login(username, password) {
//     return api
//       .post("/token/", {
//         username,
//         password,
//       })
//       .then((response) => {
//         if (response.data.accessToken) {
//           TokenService.setUser(response.data);
//         }
//         return response.data;
//       });
//   }
//   logout() {
//     TokenService.removeUser();
//   }
//   register(username, email, password) {
//     return api.post("/auth/signup", {
//       username,
//       email,
//       password,
//     });
//   }
//   getCurrentUser() {
//     return TokenService.getUser();
//   }
// }
// export default new AuthService();
// We also need to create TokenService which Axios instance and other services use above.
// TokenService pr
