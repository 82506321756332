import { TOKEN_KEYS } from "common/cacheKeys";

export const setTokens = (access_token: string, refresh_token: string) => {
    setAccessToken(access_token);
    setRefreshToken(refresh_token);
};

export const setAccessToken = (access_token: string) => {
    localStorage.setItem(TOKEN_KEYS.ACCESS_TOKEN, access_token);
};

const setRefreshToken = (refresh_token: string) => {
    localStorage.setItem(TOKEN_KEYS.REFRESH_TOKEN, refresh_token);
};

export const getAccessToken = () => {
    return localStorage.getItem(TOKEN_KEYS.ACCESS_TOKEN);
};

export const getRefreshToken = () => {
    return localStorage.getItem(TOKEN_KEYS.REFRESH_TOKEN);
};

export const clearTokens = () => {
    localStorage.clear();
};
