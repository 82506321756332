import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import api from "helpers/api.helpers";

import useModal from "foundation/modals/useModal";
import TextInput from "foundation/components/forms/TextInput";

import { Ingredient } from "cooking/models/types";

const CreateIngredientModal: React.FC = () => {
    const { closeModal } = useModal();

    const [ingredient, setIngredient] = useState<Ingredient>();

    useEffect(() => {
        api.get("createEditIngredient/")
            .then(({ data }) => setIngredient(data))
            .catch((err) => console.log(err.response));
    }, []);

    if (!ingredient) {
        return <h1>Loading...</h1>;
    }

    const handleSubmit = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        api.post("createEditIngredient/", ingredient)
            .catch((err) => console.log(err))
            .finally(closeModal);
    };

    const updateIngredient = (key: string, newValue: any) => {
        let updatedIngredient = cloneDeep(ingredient);
        updatedIngredient[key] = newValue;

        setIngredient(updatedIngredient);
    };

    return (
        <>
            <h2>Create Ingredient</h2>
            <form autoComplete="off">
                <TextInput
                    name="name"
                    value={ingredient.name}
                    placeholder="Ingredient Name"
                    className="form-group row"
                    extraInputClasses="form-control-lg"
                    required
                    onChange={(newVal) => updateIngredient("name", newVal)}
                />

                <TextInput
                    name="description"
                    displayLabel="Description"
                    value={ingredient.description}
                    placeholder="Ingredient Description"
                    className="form-group row"
                    type="textarea"
                    onChange={(newVal) =>
                        updateIngredient("description", newVal)
                    }
                />
                <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={closeModal}
                    style={{ marginLeft: "10px" }}
                >
                    Cancel
                </button>
            </form>
        </>
    );
};

export default CreateIngredientModal;
