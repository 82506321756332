import React, { CSSProperties } from "react";
import {
    BabelLoading,
    BoxLoading,
    BlockLoading,
    BlockReserveLoading,
    CircleLoading,
    CircleToBlockLoading,
    CommonLoading,
    DisappearedLoading,
    LoopCircleLoading,
    NineCellLoading,
    TouchBallLoading,
    TransverseLoading,
    WaveLoading,
    WaveTopBottomLoading,
    WindMillLoading,
    JumpCircleLoading,
    MeteorRainLoading,
    RotateCircleLoading,
    StickyBallLoading,
    SemipolarLoading,
    SolarSystemLoading,
    LadderLoading,
    HeartBoomLoading,
    RollBoxLoading,
    RectGraduallyShowLoading,
    PointSpreadLoading,
    ThreeHorseLoading,
    PassThrouthLoading,
    CoffeeLoading,
    BatteryLoading,
    DiamonLoading,
    EatLoading,
} from "react-loadingg";

export enum LoadingType {
    box,
    babel,
    block,
    blockReverse,
    blockRoll,
    circleToBlock,
    common,
    disappeared,
    loopCircle,
    nineCell,
    touchBall,
    transverse,
    wave,
    waveTopBottom,
    windmill,
    jumpCircle,
    meteorRain,
    rotateCircle,
    stickyBall,
    semipolar,
    solarSystem,
    ladder,
    heartBoom,
    rollBox,
    rectGraduallyShow,
    pointSpread,
    threeHorse,
    passThrouth,
    coffee,
    battery,
    diamon,
    eat,
}

type LoadingProps = {
    type: LoadingType;
    className?: string;
};

const Loading: React.FC<LoadingProps> = ({
    type = LoadingType.box,
    className = "",
}) => {
    const typeToComponent = {
        [LoadingType.box]: BoxLoading,
        [LoadingType.babel]: BabelLoading,
        [LoadingType.block]: BlockLoading,
        [LoadingType.blockReverse]: BlockReserveLoading,
        [LoadingType.blockRoll]: CircleLoading,
        [LoadingType.circleToBlock]: CircleToBlockLoading,
        [LoadingType.common]: CommonLoading,
        [LoadingType.disappeared]: DisappearedLoading,
        [LoadingType.loopCircle]: LoopCircleLoading,
        [LoadingType.nineCell]: NineCellLoading,
        [LoadingType.touchBall]: TouchBallLoading,
        [LoadingType.transverse]: TransverseLoading,
        [LoadingType.wave]: WaveLoading,
        [LoadingType.waveTopBottom]: WaveTopBottomLoading,
        [LoadingType.windmill]: WindMillLoading,
        [LoadingType.jumpCircle]: JumpCircleLoading,
        [LoadingType.meteorRain]: MeteorRainLoading,
        [LoadingType.rotateCircle]: RotateCircleLoading,
        [LoadingType.stickyBall]: StickyBallLoading,
        [LoadingType.semipolar]: SemipolarLoading,
        [LoadingType.solarSystem]: SolarSystemLoading,
        [LoadingType.ladder]: LadderLoading,
        [LoadingType.heartBoom]: HeartBoomLoading,
        [LoadingType.rollBox]: RollBoxLoading,
        [LoadingType.rectGraduallyShow]: RectGraduallyShowLoading,
        [LoadingType.pointSpread]: PointSpreadLoading,
        [LoadingType.threeHorse]: ThreeHorseLoading,
        [LoadingType.passThrouth]: PassThrouthLoading,
        [LoadingType.coffee]: CoffeeLoading,
        [LoadingType.battery]: BatteryLoading,
        [LoadingType.diamon]: DiamonLoading,
        [LoadingType.eat]: EatLoading,
    };

    // fine tuning as needed
    const styleOverrides: Record<any, CSSProperties> = {
        [LoadingType.coffee]: {
            height: "60px",
            width: "60px",
            marginTop: "20px",
        },
        [LoadingType.diamon]: {
            height: "100px",
            width: "100px",
            marginLeft: "-16px",
        },
    };

    const LoadingComponent: React.FC<{
        style?: CSSProperties;
        speed?: number;
        size?: string;
        color?: string;
    }> = typeToComponent[type];

    // see Themer.tsx for the color variables

    return (
        <div
            className={`relative h-[50px] w-[50px] ${className}`}
            style={styleOverrides[type]}
        >
            <LoadingComponent color="var(--text-color)" />
        </div>
    );
};

export default Loading;
