import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type QuoteIconProps = {
    className?: string;
    color?: string;
};

const QuoteIcon: React.FC<QuoteIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                enableBackground="new 0 0 100 100"
                fill={color}
                className={className}
            >
                <path d="M95,38.209C95,67.018,68.402,84.57,64.271,86.76c-2.992,1.63-5.762,2.725-7.659,2.725c-1.653,0-2.748-0.805-2.748-2.189  s1.385-3.015,2.748-4.109c3.818-2.746,14.538-17.017,14.538-30.461c0-3.551-1.921-6.834-4.109-7.95  c-2.479-1.362-14.003-3.841-14.003-18.357c0-8.509,6.856-15.9,16.459-15.9C82.114,10.517,95,20.097,95,38.209z" />
                <path d="M46.986,38.209c0,28.809-26.621,46.361-30.73,48.551c-3.016,1.63-5.762,2.725-7.682,2.725c-1.652,0-2.725-0.805-2.725-2.189  s1.361-3.015,2.725-4.109c3.84-2.746,14.537-17.017,14.537-30.461c0-3.551-1.92-6.834-4.109-7.95C16.523,43.412,5,40.934,5,26.417  c0-8.509,6.879-15.9,16.482-15.9C34.078,10.517,46.986,20.097,46.986,38.209z" />
            </svg>
        </>
    );
};

export default QuoteIcon;
