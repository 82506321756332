import React from "react";
import { createPortal } from "react-dom";

import useModal from "foundation/modals/useModal";
import { useModalsContext } from "foundation/modals/ModalsContext";

const Modals = () => {
    const { modals } = useModalsContext();
    const { closeModal } = useModal();

    if (!document.getElementById("modal-root")) {
        // Handles "DOM element hasn't mounted yet" errors
        return <></>;
    }

    // TODO animate open (close works!)
    // TODO X button to close

    return createPortal(
        modals.map((modal, index) => {
            return (
                <aside
                    className={`modal-container center-modal-container ${modal.open ? "open" : "closed"
                        }`}
                    key={index}
                >
                    <button className="modal-dimmer" onClick={closeModal} />
                    <div className="modal-card">{modal.component}</div>
                </aside>
            );
        }),
        document.getElementById("modal-root") as HTMLElement
    );
};

export default Modals;
