import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { signup } from "helpers/auth.helpers";

import "auth/styles/login.scss";
// import TextInput from "foundation/components/forms/TextInput";

type SignupFormProps = {};

const SignupForm: React.FC<SignupFormProps> = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const { setCurrentUser } = useCurrentUser();

    const handleSignup = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError("");

        signup(username, password, setError, setCurrentUser, navigate);
    };

    return (
        <>
            <div className="d-flex justify-content-center h-100">
                <div className="card">
                    <div className="card-header">
                        <h3>Sign Up</h3>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSignup}>
                            {/* TODO clean this up and get icons showing */}
                            {/* <TextInput
                              name="username"
                              placeholder="Username"
                              extraInputClasses=""
                              className="input-group login-field"
                            >
                              <div className="input-group-prepend login-user-icon">
                                <span className="input-group-text "></span>
                              </div>
                            </TextInput> */}

                            <div className="input-group form-group">
                                <div className="input-group-prepend login-user-icon">
                                    <span className="input-group-text ">
                                        <i className=""></i>
                                    </span>
                                </div>

                                <input
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    className="form-control"
                                    required
                                    id="id_username"
                                    value={username}
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                        setError("");
                                    }}
                                />
                            </div>

                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="login-password-icon"></i>
                                    </span>
                                </div>

                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    className="form-control"
                                    required
                                    id="id_password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setError("");
                                    }}
                                />
                            </div>

                            {/* <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="rememberMe"
                              />
                              <label className="form-check-label" htmlFor="rememberMe">
                                Remember Me
                              </label>
                            </div> */}
                            {error && (
                                <div className="form-group text-danger">
                                    {error}
                                </div>
                            )}
                            <div className="form-group">
                                <input
                                    type="submit"
                                    value="Sign Up"
                                    className="btn btn-primary float-right"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="card-footer">
                        <div className="d-flex justify-content-center links">
                            Already have an account?&nbsp;
                            <Link to="/login">Log In</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignupForm;
