export const FantasyPositions = {
    QB: "QB",
    WR: "WR",
    RB: "RB",
    TE: "TE",
    K: "K",
    DEF: "DEF",
    IDP: "IDP",
    DB: "DB",
    LB: "LB",
} as const;

export const getEligibleFantasyPositions = (
    position: string
): typeof FantasyPositions[keyof typeof FantasyPositions][] => {
    const eligiblePositions = [];

    switch (position) {
        case "QB":
            eligiblePositions.push(FantasyPositions.QB);
            break;
        case "RB":
        case "FB":
            eligiblePositions.push(FantasyPositions.RB);
            break;
        case "WR":
            eligiblePositions.push(FantasyPositions.WR);
            break;
        case "TE":
            eligiblePositions.push(FantasyPositions.TE);
            break;
        case "CB":
        case "SS":
        case "FS":
        case "S":
        case "DB":
            eligiblePositions.push(FantasyPositions.DB);
            eligiblePositions.push(FantasyPositions.IDP);
            break;
        case "LB":
        case "OLB":
        case "ILB":
        case "LILB":
        case "RILB":
        case "LOLB":
        case "MLB":
            eligiblePositions.push(FantasyPositions.LB);
            eligiblePositions.push(FantasyPositions.IDP);
            break;
        case "DE":
        case "DT":
        case "DL":
        case "EDGE":
        case "NT":
            eligiblePositions.push(FantasyPositions.IDP);
            break;
        case "K":
            eligiblePositions.push(FantasyPositions.IDP);
            break;
        case "QB/TE": // Logan Thomas
        case "HB": // Jordan Franks
        case "LS,TE": // Beau Brinkley
            eligiblePositions.push(FantasyPositions.TE);
            break;
        case "WR/CB": // Jamal Agnew
        case "WR/BB": // Ty Montgomery
            eligiblePositions.push(FantasyPositions.WR);
            break;
        default:
            break;
    }

    return eligiblePositions;
};
