import { Tag } from "cooking/models/types";
import React from "react";

type ViewTagsProps = {
    tags: Tag[];
    className?: string;

    showLabel?: boolean;
};

const ViewTags: React.FC<ViewTagsProps> = ({
    tags,
    className = "",
    showLabel = false,
}) => {
    return (
        <div
            className={`flex items-center flex-wrap gap-[5px] ${className ?? ""
                }`}
        >
            {showLabel && "Tags:"}
            {tags.map((tag, i) => (
                <div key={`${tag}-${i}`} className="tag">
                    {tag.content}
                </div>
            ))}
        </div>
    );
};

export default ViewTags;
