import React from "react";
import { IconEnum, ICON_MAP } from "skills/helpers/icons";

type IconSelectorProps = {
    icon: IconEnum;
    setIcon: (newIcon: string) => void;
};

const IconSelector: React.FC<IconSelectorProps> = ({ icon, setIcon }) => {
    return (
        <div className="flex flex-col gap-[5px] max-w-[522px]">
            <span>Icon:</span>
            <div className="flex gap-[8px] flex-wrap">
                {Object.keys(ICON_MAP).map((iconKey, index) => {
                    const isSelected = icon === iconKey;
                    return (
                        <div
                            key={index}
                            role="button"
                            className={`h-[80px] w-[80px] flex items-center ${isSelected ? "bg-theme-background-accent" : ""
                                }`}
                            onClick={() => setIcon(iconKey)}
                        >
                            {ICON_MAP[iconKey]}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default IconSelector;
