import { cloneDeep } from "lodash";

import useTransactions from "./useTransactions";

import { getRandomInt } from "helpers/number.helpers";

import { Transaction } from "money/models/types";

export const useDiscreteTransactions = () => {
    const {
        filteredExpenses: expenses,
        filteredIncome: income,
        isLoading,
    } = useTransactions();

    let discreteExpenses: Transaction[] = [];
    let discreteIncome: Transaction[] = [];

    const scrambleTransactions = (transactions: Transaction[]) => {
        const scrambledTransactions = cloneDeep(transactions);
        scrambledTransactions.forEach((transaction) => {
            transaction.amount =
                getRandomInt(
                    transaction.direction === "in" ? 2222 : 123 // 💸💸💸
                ) + getRandomInt(5);
        });
        return scrambledTransactions;
    };

    if (!isLoading) {
        discreteExpenses = scrambleTransactions(expenses);
        discreteIncome = scrambleTransactions(income);
    }

    return {
        discreteExpenses,
        discreteIncome,
    };
};

export default useDiscreteTransactions;
