import CookingIcon from "foundation/svgComponents/CookingIcon";
import LibraryIcon from "foundation/svgComponents/LibraryIcon";
import DevNotesIcon from "foundation/svgComponents/DevNotesIcon";
import FantasyFootballIcon from "foundation/svgComponents/FantasyFootballIcon";
import JournalIcon from "foundation/svgComponents/JournalIcon";
import QuoteIcon from "foundation/svgComponents/QuoteIcon";
import RememberallIcon from "foundation/svgComponents/RememberallIcon";
import SettingsIcon from "foundation/svgComponents/SettingsIcon";
import TaskTrackerIcon from "foundation/svgComponents/TaskTrackerIcon";
import WikiIcon from "foundation/svgComponents/WikiIcon";
import WimIcon from "foundation/svgComponents/WimIcon";
import MoneyIcon from "foundation/svgComponents/MoneyIcon";
import PlaceholderImage from "foundation/svgComponents/PlaceholderIcon";

export const ICON_MAP = {
    crystalBall: <RememberallIcon />,
    wand: <WimIcon />,
    firework: <PlaceholderImage />,
    cooking: <CookingIcon />,
    football: <FantasyFootballIcon />,
    money: <MoneyIcon />,
    library: <LibraryIcon />,
    journal: <JournalIcon />,
    note: <DevNotesIcon />,
    checklist: <TaskTrackerIcon />,
    quote: <QuoteIcon />,
    gear: <SettingsIcon />, // not showing up for some reason
    infoGlobe: <WikiIcon />,
} as const;
export type IconEnum = keyof typeof ICON_MAP;
