import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type CheckProps = {
    className?: string;
    size?: string;
};

const Check: React.FC<CheckProps> = ({ className = "", size = "25px" }) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <svg
            width="500pt"
            height="500pt"
            version="1.1"
            viewBox="0 0 500 500"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill={color}
            className={className}
            style={{ height: size, width: size }}
        >
            <g>
                <path d="m415.98 110.3-229.32 229.32-102.65-102.65c-13.273-13.273-34.777-13.273-48.047 0-13.273 13.273-13.273 34.777 0 48.047l126.67 126.67c6.6094 6.6094 15.344 9.9688 24.023 9.9688 8.6797 0 17.359-3.3047 24.023-9.9688l253.34-253.34c13.273-13.273 13.273-34.777 0-48.047-13.27-13.273-34.773-13.273-48.047 0z" />
            </g>
        </svg>
    );
};

export default Check;
