import React from "react";
import { useErrorBoundary } from "react-error-boundary";

type ErrorPageProps = {
    error: any;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
    const { resetBoundary } = useErrorBoundary();

    return (
        <section className="flex flex-col gap-[20px] items-center justify-center mt-[60px]">
            <h1>Whoops...</h1>
            <div>It seems something went wrong:</div>
            <div className="text-red-800">{error.message}</div>

            <button className="btn btn-primary" onClick={resetBoundary}>
                Try Again
            </button>
        </section>
    );
};

export default ErrorPage;
