import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "helpers/api.helpers";

import useEventListener from "common/hooks/useEventListener";

import Checkbox from "foundation/components/forms/Checkbox";
import ViewRecipeStep from "./ViewRecipeStep";
import { Recipe } from "cooking/models/types";

import { getOrderedSteps } from "cooking/helpers/recipe.helpers";

const MakeRecipe: React.FC = () => {
    const { recipeId } = useParams();

    const [recipe, setRecipe] = useState<Recipe>();
    const [currentStepIndex, setCurrentStepIndex] = useState(-1);
    const [checkedIngredients, setCheckedIngredients] = useState<number[]>([]);

    useEffect(() => {
        api.get(`recipe/${recipeId}`)
            .then(({ data }) => setRecipe(data))
            .catch((err) => console.log(err));
    }, [recipeId]);

    const nextStep = () => {
        if (!recipe) {
            return;
        }

        if (currentStepIndex < recipe.steps.length - 1) {
            setCurrentStepIndex(currentStepIndex + 1);
        }
    };

    const prevStep = () => {
        if (currentStepIndex > -1) {
            setCurrentStepIndex(currentStepIndex - 1);
        }
    };

    useEventListener("keydown", (event) => {
        if (event.key === "ArrowRight") {
            event.preventDefault();
            nextStep();
        } else if (event.key === "ArrowLeft") {
            event.preventDefault();
            prevStep();
        }
    });

    if (!recipe) {
        return <h1>Loading...</h1>;
    }

    const orderedSteps = getOrderedSteps(recipe.steps);
    let currentStep;
    let remainingSteps = orderedSteps;
    if (currentStepIndex === -1) {
        if (!recipe.ingredients || recipe.ingredients.length === -1) {
            setCurrentStepIndex(0);
        }

        // ingredients
        currentStep = (
            <ul className="pl-[10px]">
                {recipe.ingredients.map((ingredient) => {
                    const isChecked = checkedIngredients.includes(
                        ingredient.id
                    );

                    return (
                        <Checkbox
                            key={ingredient.id}
                            id={`ingredient-${ingredient.id}`}
                            text={ingredient.originalText}
                            value={isChecked}
                            onClick={
                                isChecked
                                    ? () =>
                                        setCheckedIngredients(
                                            checkedIngredients.filter(
                                                (i) => i !== ingredient.id
                                            )
                                        )
                                    : () =>
                                        setCheckedIngredients([
                                            ...checkedIngredients,
                                            ingredient.id,
                                        ])
                            }
                            labelClassName={
                                isChecked ? "line-through opacity-40" : ""
                            }
                        />
                    );
                })}
            </ul>
        );
    } else {
        currentStep = <ViewRecipeStep step={orderedSteps[currentStepIndex]} />;
        remainingSteps = orderedSteps.slice(currentStepIndex + 1);
    }

    return (
        <div className="flex flex-col mx-auto gap-[20px]">
            <section className="recipe-details">
                <h1>{recipe.name}</h1>
            </section>
            <section className="p-[10px] bg-theme-background-accent text-[20px] rounded-md">
                <h2>Current Step</h2>
                {currentStep}
            </section>

            <div className="flex gap-[10px]">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Prev Step
                </button>
                <button className="btn btn-primary" onClick={nextStep}>
                    Next Step
                </button>
            </div>

            <section>
                {remainingSteps && remainingSteps.length > 0 && (
                    <>
                        <h2 className="mb-[10px]">Next Steps</h2>
                        <ul className="pl-[5px] flex flex-col gap-[10px]">
                            {remainingSteps.map((step) => (
                                <ViewRecipeStep key={step.id} step={step} />
                            ))}
                        </ul>
                    </>
                )}
            </section>
        </div>
    );
};

export default MakeRecipe;
