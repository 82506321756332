import React from "react";
import { Recipe } from "cooking/models/types";
import RecipeRow from "cooking/components/RecipeRow";

type RecipeRowsProps = {
    recipes: Recipe[];
    onClick?: (recipe: Recipe) => void;
    rowIcon?: React.ReactNode;
    infiniteScrollRef?: React.MutableRefObject<HTMLElement>; // from useGetApiPaginated
};

const RecipeRows: React.FC<RecipeRowsProps> = ({
    recipes,
    onClick,
    rowIcon,
    infiniteScrollRef,
}) => {
    return (
        <ul className="p-0 max-h-[600px] overflow-y-auto">
            {recipes.map((recipe, index) => (
                <RecipeRow
                    key={recipe.id}
                    recipe={recipe}
                    onClick={onClick}
                    rowIcon={rowIcon}
                    innerRef={
                        index === recipes.length - 1
                            ? infiniteScrollRef
                            : undefined
                    }
                />
            ))}
        </ul>
    );
};

export default RecipeRows;
