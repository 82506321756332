import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import { useGetApi } from "common/hooks/useGetApi";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";

import { AppsEnum } from "foundation/apps";

import appStore from "foundation/stores/AppStore";

import SearchableRecipes from "cooking/components/SearchableRecipes";
import RecipeRows from "cooking/components/RecipeRows";

// import { SimpleMealPlan } from "cooking/models/types";

type CookingHomeProps = {};

const CookingHome: React.FC<CookingHomeProps> = () => {
    const { currentUser } = useCurrentUser();

    useEffect(() => {
        appStore.set.currentApp(AppsEnum.Cooking);
    }, []);

    const mealPlan = undefined;
    // const { data: mealPlan } = useGetApi<SimpleMealPlan>(
    //     "simple_meal_plan/1", // 1 is a dummy value so it uses the latest meal plan
    //     undefined,
    //     !currentUser
    // );

    return (
        <div className="flex flex-col gap-[50px] mx-auto">
            <div className="flex flex-col gap-[10px]">
                <h1>Let's get cooking</h1>

                {!currentUser && (
                    <>
                        <h2>Just browsing?</h2>
                        <div>
                            <Link to="/login?redirectTo=/cooking">
                                <button className="btn btn-primary">
                                    Login
                                </button>
                            </Link>{" "}
                            to do more
                        </div>
                    </>
                )}
                {currentUser && (
                    <div className="flex gap-[10px]">
                        <Link
                            to="recipe/create"
                            role="button"
                            className="btn btn-primary"
                        >
                            Create Recipe
                        </Link>

                        <Link
                            to="meal_plan/create"
                            role="button"
                            className="btn btn-primary"
                        >
                            New Meal Plan
                        </Link>
                        <Link
                            to="kitchen/"
                            role="button"
                            className="btn btn-primary"
                        >
                            Kitchen
                        </Link>
                        <Link
                            to="ingredients/"
                            role="button"
                            className="btn btn-primary"
                        >
                            Ingredients
                        </Link>
                    </div>
                )}
            </div>

            {mealPlan && mealPlan.recipes && (
                <section>
                    <h2>Meal Plan</h2>
                    <RecipeRows recipes={mealPlan.recipes} />
                </section>
            )}

            <SearchableRecipes />
        </div>
    );
};

export default CookingHome;
