export const parseIngredientText = (ingredientText: string) => {
    // Updated regex to handle ranges like "2-3", "2 to 3", and mixed numbers like "2 1/2"
    const regex =
        /(?<quantity>[\d\s/.-]+(?:\s+to\s+[\d\s/.]+)?)\s+(?<unit>[\w.]+)\s+(?<name>[\w\s()]+)/i;
    const match = ingredientText.match(regex);

    if (!match?.groups) return {};

    return {
        unit: match.groups.unit.trim().toLowerCase(),
        quantity: match.groups.quantity.trim(),
        name: match.groups.name.trim(),
    };
};
