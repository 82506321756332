import React, { CSSProperties } from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";

import { THEME_COLORS } from "settings/Themes";
import { getSelectedTheme } from "helpers/settings.helpers";

const Themer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { currentUser } = useCurrentUser();

    const selectedTheme = getSelectedTheme(currentUser);

    // Keep in sync with tailwind.config.js to use with tailwind
    const themeVariables: CSSProperties = {
        ["--background-color" as any]: THEME_COLORS[selectedTheme].background,
        ["--background-accent-color" as any]:
            THEME_COLORS[selectedTheme].backgroundAccent,
        ["--nav-color" as any]: THEME_COLORS[selectedTheme].nav,
        ["--nav-text-color" as any]: THEME_COLORS[selectedTheme].navText,
        ["--text-color" as any]: THEME_COLORS[selectedTheme].text,
        ["--text-accent-color" as any]: THEME_COLORS[selectedTheme].textAccent,
        ["--border-color" as any]: THEME_COLORS[selectedTheme].textAccent, // textAccent is intentional
        ["--button-primary" as any]: THEME_COLORS[selectedTheme].buttonPrimary,
        ["--button-primary-text" as any]:
            THEME_COLORS[selectedTheme].buttonPrimaryText,
        ["--button-primary-border" as any]:
            THEME_COLORS[selectedTheme].buttonPrimaryBorder ??
            THEME_COLORS[selectedTheme].buttonPrimary,
        ["--button-warning" as any]: THEME_COLORS[selectedTheme].buttonWarning,
        ["--button-warning-text" as any]:
            THEME_COLORS[selectedTheme].buttonWarningText,
        ["--button-warning-border" as any]:
            THEME_COLORS[selectedTheme].buttonWarningBorder ??
            THEME_COLORS[selectedTheme].buttonWarning,
        ["--button-danger" as any]: THEME_COLORS[selectedTheme].buttonDanger,
        ["--button-danger-text" as any]:
            THEME_COLORS[selectedTheme].buttonDangerText,
        ["--button-danger-border" as any]:
            THEME_COLORS[selectedTheme].buttonDangerBorder ??
            THEME_COLORS[selectedTheme].buttonDanger,
        ["--input-background" as any]:
            THEME_COLORS[selectedTheme].inputBackground,
        ["--input-text" as any]: THEME_COLORS[selectedTheme].inputText,
        ["--input-text-inverse-background" as any]:
            THEME_COLORS[selectedTheme].inputTextForInverseBackground ??
            THEME_COLORS[selectedTheme].inputText,
        ["--button-group" as any]: THEME_COLORS[selectedTheme].buttonGroup,
        ["--button-group-selected" as any]:
            THEME_COLORS[selectedTheme].buttonGroupSelected,
        ["--focus-outline" as any]: `${THEME_COLORS[selectedTheme].focusOutline ?? "#007bff"
            }40`, // append 40 for 25% opacity
    };

    return (
        <div id="themer" style={themeVariables}>
            {children}
        </div>
    );
};

export default Themer;
