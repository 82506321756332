// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#themer {
  background-color: var(--background-color);
  min-height: 100vh;
  color: var(--text-color); }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.home-activity-icon {
  display: block;
  width: 53%;
  height: 260px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto; }
`, "",{"version":3,"sources":["webpack://./src/foundation/styles/Home.scss"],"names":[],"mappings":"AAAA;EACI,yCAAyC;EACzC,iBAAiB;EACjB,wBAAwB,EAAA;;AAG5B;EACI,SAAS;EACT,8JAEgC;EAChC,mCAAmC;EACnC,kCAAkC,EAAA;;AAGtC;EACI,+EACa,EAAA;;AAGjB;EACI,cAAc;EAEd,UAAU;EACV,aAAa;EAEb,eAAe;EACf,iBAAiB;EACjB,kBAAkB,EAAA","sourcesContent":["#themer {\n    background-color: var(--background-color);\n    min-height: 100vh;\n    color: var(--text-color);\n}\n\nbody {\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\",\n        \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n        \"Helvetica Neue\", sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n        monospace;\n}\n\n.home-activity-icon {\n    display: block;\n\n    width: 53%;\n    height: 260px;\n\n    margin-top: 5px;\n    margin-left: auto;\n    margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
