import React from "react";

import useTransactions from "money/hooks/useTransactions";
import useDiscreteTransactions from "money/hooks/useDiscreteTransactions";

import discreteStore from "money/stores/DiscreteStore";

import TransactionColumn from "./TransactionColumn";

const TransactionHistory: React.FC = () => {
    const { filteredExpenses, filteredIncome } = useTransactions();

    const discreteModeOn = discreteStore.useTracked.discreteModeOn();
    const { discreteExpenses, discreteIncome } = useDiscreteTransactions();

    console.log("filteredExpenses:", filteredExpenses);
    console.log("discreteExpenses:", discreteExpenses);

    const transactionClassName = "w-[50%]";
    return (
        <section className="flex flex-col gap-[20px] !m-0">
            <div className="flex gap-[20px] !m-0">
                <TransactionColumn
                    title="Expenses"
                    className={transactionClassName}
                    transactions={
                        discreteModeOn ? discreteExpenses : filteredExpenses
                    }
                    isExpense
                />
                <TransactionColumn
                    title="Income"
                    className={transactionClassName}
                    transactions={
                        discreteModeOn ? discreteIncome : filteredIncome
                    }
                />
            </div>
        </section>
    );
};

export default TransactionHistory;
