import { createStore } from "@udecode/zustood";
import { Tag } from "cooking/models/types";

type FilterStore = {
    peopleFilter: Tag[];
};

const filterStore = createStore("filterStore")<FilterStore>({
    peopleFilter: [],
}).extendSelectors((state, get) => ({
    peopleFilterStrings: () => state.peopleFilter.map((tag) => tag.content),
}));

export default filterStore;
