import config from "config/config";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
    getAccessToken,
    getRefreshToken,
    setAccessToken,
} from "./token.helpers";

// Extend AxiosRequestConfig to include our custom property
interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean;
}

// Create axios instance
const api = axios.create({
    baseURL: config.apiUrl,
    headers: {
        "Content-Type": "application/json",
    },
});

// Track if we're currently refreshing to prevent multiple refresh calls
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

// Subscribe failed requests to be retried after token refresh
const subscribeTokenRefresh = (cb: (token: string) => void) => {
    refreshSubscribers.push(cb);
};

// Retry failed requests with new token
const onTokenRefreshed = (token: string) => {
    refreshSubscribers.forEach((cb) => cb(token));
    refreshSubscribers = [];
};

// Include access token in every request
api.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Handle response errors and token refresh
api.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        const originalRequest = error.config as CustomAxiosRequestConfig;
        if (!originalRequest) {
            return Promise.reject(error);
        }

        // If error is not 401 or request has already been retried, reject
        if (error.response?.status !== 401 || originalRequest._retry) {
            return Promise.reject(error);
        }

        // Check if we have a refresh token
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
            return Promise.reject(error);
        }

        // Set retry flag
        originalRequest._retry = true;

        if (!isRefreshing) {
            isRefreshing = true;

            try {
                const response = await api.post("token/refresh/", {
                    refresh: refreshToken,
                });

                const { access } = response.data;
                setAccessToken(access);

                // Notify all subscribers and reset
                onTokenRefreshed(access);
                isRefreshing = false;

                // Update the failed request's authorization header
                originalRequest.headers["Authorization"] = "Bearer " + access;
                return api(originalRequest);
            } catch (refreshError) {
                isRefreshing = false;
                refreshSubscribers = [];
                return Promise.reject(refreshError);
            }
        }

        // If we're already refreshing, wait for the new token
        return new Promise((resolve) => {
            subscribeTokenRefresh((token: string) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                resolve(api(originalRequest));
            });
        });
    }
);

/** calls /api/{method} */
export const callApiMethod = (method: string, postData?: any): Promise<any> => {
    if (postData) {
        return api.post(method, postData);
    }
    return api.get(method);
};

export default api;
