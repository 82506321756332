import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type TeaspoonProps = {
    className?: string;
    quantity?: string;
    size?: string;
};

const Teaspoon: React.FC<TeaspoonProps> = ({
    className = "",
    quantity,
    size = "30px",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 100 50"
            fill="none"
            stroke={color}
            strokeWidth="3"
            className={className}
        >
            {/* Simple teaspoon handle */}
            <line x1="10" y1="30" x2="75" y2="30" />

            {/* Teaspoon bowl on right side */}
            <circle cx="85" cy="30" r="10" fill="none" />

            {/* Quantity text - bigger */}
            <text
                x="45"
                y="14"
                fontSize="40"
                textAnchor="middle"
                dominantBaseline="middle"
                fill={color}
                stroke="none"
            >
                {quantity}
            </text>
        </svg>
    );
};

export default Teaspoon;
