import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type PlaceholderIconProps = {
    className?: string;
    color?: string;
};

const PlaceholderIcon: React.FC<PlaceholderIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                enableBackground="new 0 0 100 100"
                fill={color}
                className={className}
            >
                <g>
                    <polygon points="90.365,48.085 86.535,48.085 82.676,48.085 78.846,48.085 74.986,48.085 71.156,48.085 67.296,48.085    67.296,51.945 71.156,51.945 74.986,51.945 78.846,51.945 82.676,51.945 86.535,51.945 90.365,51.945 94.226,51.945 94.226,48.085     " />
                    <rect x="82.676" y="82.705" width="3.859" height="3.83" />
                    <rect x="82.676" y="13.465" width="3.859" height="3.86" />
                    <rect x="78.846" y="78.846" width="3.83" height="3.859" />
                    <rect x="78.846" y="17.325" width="3.83" height="3.83" />
                    <rect x="74.986" y="75.016" width="3.859" height="3.83" />
                    <rect x="74.986" y="21.155" width="3.859" height="3.86" />
                    <rect x="71.156" y="71.154" width="3.83" height="3.861" />
                    <rect x="71.156" y="25.015" width="3.83" height="3.83" />
                    <rect x="67.296" y="67.324" width="3.86" height="3.83" />
                    <rect x="67.296" y="28.845" width="3.86" height="3.86" />
                    <rect x="63.466" y="63.465" width="3.83" height="3.859" />
                    <rect x="63.466" y="32.705" width="3.83" height="3.83" />
                    <polygon points="48.056,71.154 48.056,75.016 48.056,78.846 48.056,82.705 48.056,86.535 48.056,90.395 48.056,94.225    51.916,94.225 51.916,90.395 51.916,86.535 51.916,82.705 51.916,78.846 51.916,75.016 51.916,71.154 51.916,67.324 48.056,67.324     " />
                    <polygon points="48.056,9.635 48.056,13.465 48.056,17.325 48.056,21.155 48.056,25.015 48.056,28.845 48.056,32.705    51.916,32.705 51.916,28.845 51.916,25.015 51.916,21.155 51.916,17.325 51.916,13.465 51.916,9.635 51.916,5.775 48.056,5.775     " />
                    <rect x="32.676" y="63.465" width="3.859" height="3.859" />
                    <rect x="32.676" y="32.705" width="3.859" height="3.83" />
                    <rect x="28.846" y="67.324" width="3.83" height="3.83" />
                    <rect x="28.846" y="28.845" width="3.83" height="3.86" />
                    <rect x="24.986" y="71.154" width="3.859" height="3.861" />
                    <rect x="24.986" y="25.015" width="3.859" height="3.83" />
                    <rect x="21.156" y="75.016" width="3.83" height="3.83" />
                    <rect x="21.156" y="21.155" width="3.83" height="3.86" />
                    <rect x="17.296" y="78.846" width="3.86" height="3.859" />
                    <rect x="17.296" y="17.325" width="3.86" height="3.83" />
                    <rect x="13.466" y="82.705" width="3.83" height="3.83" />
                    <polygon points="17.296,51.945 21.156,51.945 24.986,51.945 28.846,51.945 32.676,51.945 32.676,48.085 28.846,48.085    24.986,48.085 21.156,48.085 17.296,48.085 13.466,48.085 9.605,48.085 5.775,48.085 5.775,51.945 9.605,51.945 13.466,51.945  " />
                    <rect x="13.466" y="13.465" width="3.83" height="3.86" />
                </g>
            </svg>
        </>
    );
};

export default PlaceholderIcon;
