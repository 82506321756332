import { createStore } from "@udecode/zustood";

type DevNoteSidebarStore = {
    isOpen: boolean;
    noteText: string;
};

const devNoteSidebarStore = createStore("devNoteSidebar")<DevNoteSidebarStore>(
    {
        isOpen: false,
        noteText: "",
    },
    { persist: { name: "devNoteSidebar", enabled: true } }
).extendActions((set, get) => ({
    toggleIsOpen: () => {
        set.isOpen(!get.isOpen());
    },
}));

export default devNoteSidebarStore;
