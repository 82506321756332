import React from "react";

type ButtonGroupToggleProps = {
    /** The name of the field + name of selection in request.POST */
    name?: string;

    choices: { value: string; display: React.ReactNode; title?: string }[];

    selectedValue: string;

    handleChange: (newValue: string) => void;

    className?: string;
};

const ButtonGroupToggle: React.FC<ButtonGroupToggleProps> = ({
    name,
    selectedValue,
    choices,
    handleChange,
    className = "",
}) => {
    return (
        <div
            className={`btn-group btn-group-toggle ${className}`}
            data-toggle="buttons"
        >
            {choices.map((choice) => (
                <label
                    key={`${name}_${choice.value}`}
                    className={`btn btn-secondary flex items-center justify-center ${selectedValue === choice.value ? "active" : ""
                        }`}
                    onClick={() => handleChange(choice.value)}
                    title={choice.title}
                >
                    {choice.display}
                    <input
                        type="radio"
                        className="opacity-0 pointer-events-none fixed"
                        name={name}
                        value={choice.value}
                        id={`id_${name}_${choice.value}`}
                        defaultChecked={selectedValue === choice.value}
                    />
                </label>
            ))}
        </div>
    );
};

export default ButtonGroupToggle;
