import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetApi } from "common/hooks/useGetApi";
import api from "helpers/api.helpers";

import { Cookbook } from "cooking/models/types";
import CookbookForm, { CookbookFormData } from "./CookbookForm";

const CreateCookbook: React.FC = () => {
    const navigate = useNavigate();

    const { appendData: addToMyCookbooksCache } = useGetApi<Cookbook[]>(
        "cookbooks/my_cookbooks",
        { skip: true }
    );

    const handleSubmit = async (data: CookbookFormData) => {
        const { name, description, isPublic, recipes } = data;
        api.post("/cookbooks/", {
            name,
            description,
            isPublic,
            recipeIds: recipes.map((r) => r.id),
        })
            .then((resp) => {
                if (!resp) throw Error("Failed to create cookbook, idk why");
                addToMyCookbooksCache(resp.data, true, true);
                navigate("/cooking");
            })
            .catch((e) => {
                console.error("e:", e);
            });
    };

    return <CookbookForm onSubmit={handleSubmit} />;
};

export default CreateCookbook;
