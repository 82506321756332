import React from "react";
import TagFilter from "foundation/components/TagFilter";
import filterStore from "money/stores/FilterStore";
import usePeopleTags from "money/hooks/usePeopleTags";

const PeopleFilter: React.FC = () => {
    const { peopleTags: peopleTagOptions } = usePeopleTags();

    return (
        <TagFilter
            className="mb-[-10px]"
            tags={peopleTagOptions}
            handleTagFilter={filterStore.set.peopleFilter}
        />
    );
};

export default PeopleFilter;
