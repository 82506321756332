import React, { useState } from "react";
import Xarrow from "react-xarrows";
import uuid from "react-uuid";
import { cloneDeep } from "lodash";

import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";
import useMousePosition from "common/hooks/useMousePosition";

import ComplexStepBox from "cooking/components/ComplexStepBox";
import PlusButton from "foundation/components/PlusButton";
import Trash from "foundation/svgComponents/Trash";

import { ComplexStep } from "cooking/models/types";
import ArrowWithLine from "foundation/svgComponents/ArrowWithLine";
import useCreateRecipe from "cooking/hooks/useCreateRecipe";

export const emptyStep = { id: -1, type: "single", ingredients: [], order: 1 };

type ComplexStepsProps = {
    steps: ComplexStep[];
    setSteps: (steps: ComplexStep[]) => void;
};

const ComplexSteps: React.FC<ComplexStepsProps> = ({ steps, setSteps }) => {
    const [addDepStep, setAddDepStep] = useState<ComplexStep>();
    const isDependencySelectionMode = !!addDepStep;

    const { isIngredientLinkActive } = useCreateRecipe(false);

    const {
        mousePosition: { x: mouseX, y: mouseY },
        activate: activateMouseTracking,
        deactivate: deactivateMouseTracking,
    } = useMousePosition(true);

    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    if ((steps?.length ?? 0) === 0) {
        // gotta 'clone' so that steps don't accidentally share dependencies
        steps = [{ ...emptyStep }];
    }

    const updateStepField = (stepId: number, field: string, newValue: any) => {
        const step = steps.find((s) => s.id === stepId);
        if (step[field] === newValue) return;

        step[field] = newValue;

        const updatedSteps = [...steps];
        setSteps(updatedSteps);
    };

    const arrowUuid = uuid();
    const getArrowId = (stepId: number, topBottom: "top" | "bottom") => {
        return `${stepId.toString()}_${topBottom}_${arrowUuid}`;
    };

    const addNewStep = (level: number, dependsOnId?: number) => {
        const newStepId =
            steps.reduce((minId, step) => Math.min(minId, step.id), -1) - 1;

        const newStep: ComplexStep = {
            ...emptyStep,
            id: newStepId,
            order: level,
        };

        if (dependsOnId) {
            newStep.dependsOn = [dependsOnId];

            const dependsOnStep = steps.find((s) => s.id === dependsOnId);
            dependsOnStep.dependencies
                ? dependsOnStep.dependencies.push(newStepId)
                : (dependsOnStep.dependencies = [newStepId]);
        }

        setSteps([...steps, newStep]);
    };

    const deleteStep = (step: ComplexStep) => {
        const { id, dependsOn, dependencies } = step;
        const updatedSteps = cloneDeep(steps).filter((s) => s.id !== id);

        // Remove this step from the dependencies array of its parent
        dependsOn?.forEach((parentStepId) => {
            const parentStep = updatedSteps.find((s) => s.id === parentStepId);
            parentStep.dependencies = parentStep.dependencies.filter(
                (d) => d !== id
            );
        });

        // Remove this step from the dependsOn array of its children
        dependencies?.forEach((childStepId) => {
            const childStep = updatedSteps.find((s) => s.id === childStepId);
            childStep.dependsOn = childStep.dependsOn.filter((d) => d !== id);
        });

        setSteps(updatedSteps);
    };

    const handleAddDependencyFirstClick = (node: StepNode) => {
        // 1. draw a line from bottom-{node.arrowId} to cursor
        activateMouseTracking();
        setAddDepStep(node.step);
    };

    const handleAddDependencySecondClick = (dependencyStep: ComplexStep) => {
        // 2. onClick
        //      if a node was clicked: add its step id as a dependency to the current node
        //      if not a node (or ESC): exit selection mode
        updateStepField(addDepStep.id, "dependencies", [
            ...(addDepStep.dependencies ?? []),
            dependencyStep.id,
        ]);
        updateStepField(dependencyStep.id, "dependsOn", [
            ...(dependencyStep.dependsOn ?? []),
            addDepStep.id,
        ]);

        deactivateMouseTracking();
        setAddDepStep(undefined);
    };

    /*

steps by level:
- get root nodes => level 1
- foreach root node: add dependencies + add dep button to level 2
- foreach step in level 2: add dependencies + dep button to level 3
- keep going until steps don't have any dependencies (but still need a row of add dep buttons)

example: only one step
- level 1 / root nodes: the step
- level 2: no dependencies, so add a row of buttons

example: one step (A) w/ one dependency (B)
- level 1 / root node: A
- level 2: B, button (both with arrows from A)
- level 3: add dep button from B

example: A -> B and C, X -> Y
- level 1: A, X
- level 2: B (from A), C (from A), + button (from A), Y (from X), + button (from X)
- level 3: + (from B), + (from C), + (from Y)

*/

    const arrows: { startId: string; endId: string }[] = [];

    const rootSteps: ComplexStep[] = [];
    steps.forEach((step) => {
        if ((step.dependsOn?.length ?? 0) === 0) rootSteps.push(step);
    });

    type StepNode = {
        type: "step" | "addButton";
        step?: ComplexStep;
        node: React.ReactNode;
    };
    const nodesByLevel: StepNode[][] = [
        rootSteps.map((r) => {
            return {
                type: "step",
                step: r,
                node: (
                    <ComplexStepBox
                        key={r.id}
                        id={`${r.id}_${arrowUuid}`}
                        step={r}
                        updateStepField={(field, newValue) =>
                            updateStepField(r.id, field, newValue)
                        }
                    />
                ),
            };
        }),
    ];

    let failSafe = 0;
    let currentLevel = 1; // we've already the root steps at level (index) 0
    let moreDependenciesToAdd = true;
    const alreadyAddedSteps = rootSteps.map((s) => s.id);
    while (moreDependenciesToAdd) {
        moreDependenciesToAdd = false;

        // get all the step nodes from the previous level
        const prevLevelStepNodes = nodesByLevel[currentLevel - 1].filter(
            (node) => node.type === "step"
        );

        // for each step node, add it's dependencies and an add dependency button
        prevLevelStepNodes.forEach((prevStepNode) => {
            const prevStep = prevStepNode.step;

            // add dependencies
            prevStep.dependencies?.forEach((dependencyId) => {
                if (!alreadyAddedSteps.includes(dependencyId)) {
                    alreadyAddedSteps.push(dependencyId);
                    const dependencyStep = steps.find(
                        (s) => s.id === dependencyId
                    ); // TODO could make an id => ComplexStep map for a bit of a perf win

                    if (!nodesByLevel[currentLevel]) nodesByLevel.push([]);

                    nodesByLevel[currentLevel].push({
                        type: "step",
                        step: dependencyStep,
                        node: (
                            <ComplexStepBox
                                id={`${dependencyId}_${arrowUuid}`}
                                step={dependencyStep}
                                updateStepField={(field, newValue) =>
                                    updateStepField(
                                        dependencyStep.id,
                                        field,
                                        newValue
                                    )
                                }
                            />
                        ),
                    });

                    if ((dependencyStep.dependencies?.length ?? 0) > 0) {
                        moreDependenciesToAdd = true;
                    }
                } else {
                    // if the step has already been added AND we're currently at a new level
                    //   then remove the step from where it was, and add it to the current level instead
                    // e.g. A -> B -> C and Z -> C, then we want C to be at level 2 (0 indexed)
                    //   in this case C is dependencyId and we'd check levels 0 and 1, and end up moving it to level 3
                    let levelOfAlreadyAddedStep: number, indexOfNode: number;

                    for (let level = 0; level < currentLevel; level += 1) {
                        // check if the dependencyId has been added to level
                        nodesByLevel[level].forEach((node, nodeIndex) => {
                            if (node.step.id === dependencyId) {
                                levelOfAlreadyAddedStep = level;
                                indexOfNode = nodeIndex;
                            }
                        });

                        if (indexOfNode) {
                            break; // will probably need to remove this for more complicated dependencies
                        }
                    }

                    if (
                        levelOfAlreadyAddedStep !== undefined &&
                        indexOfNode !== undefined &&
                        levelOfAlreadyAddedStep < currentLevel
                    ) {
                        // remove it from the previous level and add it to the current (higher) one
                        const nodeLevel = nodesByLevel[levelOfAlreadyAddedStep];
                        const removedNodeArr = nodeLevel.splice(indexOfNode, 1);
                        const removedNode = removedNodeArr[0];

                        if (!nodesByLevel[currentLevel]) nodesByLevel.push([]);
                        nodesByLevel[currentLevel].push(removedNode);
                    }
                }

                // add arrow from this node to its dependency
                arrows.push({
                    startId: getArrowId(prevStepNode.step.id, "bottom"),
                    endId: getArrowId(dependencyId, "top"),
                });
            });
        });

        currentLevel += 1;
        failSafe += 1;
        if (failSafe > 222) {
            moreDependenciesToAdd = false;
            console.error(
                "Fail safe triggered. You've got an infinite loop in ComplexSteps.tsx"
            );
        }
    }

    return (
        <section className="flex gap-3 mt-0">
            <div className="flex flex-col items-center gap-12 grow">
                {nodesByLevel.map((nodes, level) => {
                    if (nodes.length === 0) return undefined;
                    return (
                        <section
                            key={level}
                            className="flex gap-4 w-full justify-evenly"
                            data-testid={`level ${level}`}
                        >
                            {nodes.map((node) => {
                                // cases:
                                //   false:
                                //     you're the only node
                                //     if you're already connected to all the other nodes
                                const isTouchingAllOtherNodes =
                                    1 + // self
                                    (node.step.dependsOn?.length ?? 0) +
                                    (node.step.dependencies?.length ??
                                        0) ===
                                    steps.length;
                                const canAddDependency =
                                    steps.length > 1 &&
                                    !isTouchingAllOtherNodes;

                                return (
                                    <div
                                        className="flex flex-col items-center z-10 relative h-fit"
                                        key={node.step.id}
                                        data-testid={`step box with buttons ${node.step.id}`}
                                        // style={{opacity: isDependencySelectionMode ? }}
                                        onClick={
                                            isDependencySelectionMode
                                                ? () =>
                                                    handleAddDependencySecondClick(
                                                        node.step
                                                    )
                                                : undefined
                                        }
                                    >
                                        {node.node}
                                        {/* TODO maybe only show this when the box is focused? */}
                                        {/* TODO eventually these can be floaty buttons that beautifully animate in/out */}
                                        <div
                                            className={`border-theme-border border-[1px] border-t-0 border-solid rounded-b-md px-2 py-1 bg-theme-background flex items-center justify-center gap-2 ${isIngredientLinkActive
                                                    ? "pointer-events-none opacity-20"
                                                    : ""
                                                }`}
                                        >
                                            <PlusButton
                                                title="Add dependent step"
                                                size="15px"
                                                onClick={() =>
                                                    addNewStep(
                                                        level + 1,
                                                        node.step.id
                                                    )
                                                }
                                            />
                                            {canAddDependency && (
                                                <button
                                                    title="Add dependency"
                                                    type="button"
                                                    onClick={() =>
                                                        handleAddDependencyFirstClick(
                                                            node
                                                        )
                                                    }
                                                >
                                                    <ArrowWithLine size="15px" />
                                                </button>
                                            )}
                                            <button
                                                title="Delete Step"
                                                type="button"
                                                onClick={() =>
                                                    deleteStep(node.step)
                                                }
                                            >
                                                <Trash size="15px" />
                                            </button>
                                        </div>
                                        <div
                                            id={getArrowId(node.step.id, "top")}
                                            className="absolute top-0"
                                        />
                                        <div
                                            id={getArrowId(
                                                node.step.id,
                                                "bottom"
                                            )}
                                            className="absolute bottom-0"
                                        />
                                    </div>
                                );
                            })}
                        </section>
                    );
                })}
            </div>
            <PlusButton
                className="text-[12px] h-fit w-fit flex flex-col items-center justify-center gap-2 opacity-80 border-theme-border border-[1px] border-solid rounded-md p-2"
                onClick={() => addNewStep(0)}
                size="30px"
                title="Add independent step"
            />
            {arrows.map((arrow, index) => (
                <Xarrow
                    key={index}
                    start={arrow.startId}
                    end={arrow.endId}
                    color={color}
                    strokeWidth={3}
                    // gridBreak="0%"
                    path="straight"
                />
            ))}

            <div
                id="mouse-arrow"
                className="fixed"
                style={{ top: mouseY, left: mouseX }}
            />
            {addDepStep && (
                <Xarrow
                    start={getArrowId(addDepStep.id, "bottom")}
                    end="mouse-arrow"
                    color={color}
                    strokeWidth={3}
                />
            )}
        </section>
    );
};

export default ComplexSteps;
