import DevConfig from "config/devConfig";
import ProdConfig from "config/prodConfig";

const isDev = process.env.NODE_ENV === "development";
// const isDev = false;

// setting process.env.NODE_ENV to "production" causes the compiler / node / w/e to break due to sytax in Activities.tsx...
//   so we'll just workaround that for now by using a different env variable to use the prod config
const useProdConfigOverride = process.env.USE_PROD_CONFIG_THO === "true";

const config = isDev && !useProdConfigOverride ? DevConfig : ProdConfig;

export default config;
