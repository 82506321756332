import React from "react";
import ViewRecipeStep from "./ViewRecipeStep";
import ViewRecipeIngredients from "./ViewRecipeIngredients";

import { ComplexStep } from "cooking/models/types";

type ViewRecipeStepGroupProps = {
    step: ComplexStep;
};

const ViewRecipeStepGroup: React.FC<ViewRecipeStepGroupProps> = ({ step }) => {
    return (
        <div className="shadow-crisp-border shadow-theme-background-accent p-[10px] rounded-lg">
            <h3 className="text-[22px] mb-[10px] font-[600]">
                {step.groupTitle}
            </h3>

            <div className="pl-[10px] flex flex-col gap-[8px]">
                {step.ingredients.length > 0 && (
                    <div className="flex flex-col gap-[5px]">
                        <h4 className="text-[20px]">Ingredients</h4>
                        <ViewRecipeIngredients
                            ingredients={step.ingredients}
                            className="!items-baseline"
                        />
                    </div>
                )}

                {step.groupSteps.map((s, index) => (
                    <ViewRecipeStep key={index} step={s} />
                ))}
            </div>
        </div>
    );
};

export default ViewRecipeStepGroup;
