import React from "react";
import { Droppable } from "react-beautiful-dnd";

import PlayerRow from "fantasyFootball/components/PlayerRow";

import { Player } from "fantasyFootball/models/types";

type PlayerTableProps = {
    players: Player[];
    tableId: string;
};

const PlayerTable: React.FC<PlayerTableProps> = ({ players, tableId }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Player</th>
                    <th>Position</th>
                </tr>
            </thead>
            <Droppable droppableId={tableId}>
                {(provided) => (
                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                        {players.map((player, index) => (
                            <PlayerRow
                                key={player.id}
                                player={player}
                                index={index}
                            />
                        ))}
                        {provided.placeholder}
                    </tbody>
                )}
            </Droppable>
        </table>
    );
};

export default PlayerTable;
