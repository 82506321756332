import { createStore } from "@udecode/zustood";
import { Recipe, RecipeIngredient } from "cooking/models/types";

type CreateRecipeStore = {
    recipe: Recipe | undefined;

    ingredientBeingLinked: RecipeIngredient | undefined;
};

const createRecipeStore = createStore("createRecipe")<CreateRecipeStore>({
    recipe: undefined,
    ingredientBeingLinked: undefined,
}).extendActions((set, get) => ({
    updateRecipe: (key: string, newValue: any) => {
        set.recipe({ ...get.recipe(), [key]: newValue });
    },
}));

export default createRecipeStore;
