import React from "react";

import MeasuringCup from "cooking/svgs/MeasuringCup";
import { RecipeIngredient } from "cooking/models/types";
import { parseIngredientText } from "cooking/helpers/ingredientParser";

type IngredientDisplayProps = {
    ingredient: RecipeIngredient;
    includeInStock?: boolean;
};

const IngredientDisplay: React.FC<IngredientDisplayProps> = ({
    ingredient,
    includeInStock = false,
}) => {
    const { originalText } = ingredient;
    const { name, quantity, unit } = parseIngredientText(originalText);

    // const name = ingredient.name || ingredient.originalText;
    // const quantity = ingredient.quantity;
    // const unit = ingredient.unit;

    const inStockTag: React.ReactNode = (
        <div className="border-solid border-theme-button-primary border-[1px] rounded-full px-[5px] py-[2px] text-[12px] leading-[12px] items-center h-fit text-theme-button-primary">
            In Stock
        </div>
    );

    if (unit === "cup" || unit === "cups") {
        return (
            <div
                className="flex gap-[8px] text-[18px] items-center"
                title={`${quantity} ${unit}`}
            >
                <MeasuringCup quantity={quantity} /> {name}
                {includeInStock && ingredient.isInStock && inStockTag}
            </div>
        );
    }

    return (
        <div className="text-[18px] leading-[30px] flex gap-[8px] items-center">
            {ingredient.originalText}
            {includeInStock && ingredient.isInStock && inStockTag}
        </div>
    );
    // return (
    //     <div>
    //         {quantity} {unit} {name}
    //     </div>
    // );
};

export default IngredientDisplay;
