import { padArray } from "helpers/array.helpers";
import { toTwoDecimals } from "helpers/number.helpers";
import { PlayerGameStats } from "fantasyFootball/models/types";

export const getSnapsPlayedForSeason = (stats: PlayerGameStats[]) => {
    if (!stats || stats.length === 0) return 0;
    const gamesInSeason = 17; // a little fudge

    const isOffense = !!stats.find((s) => s.offensiveSnapsPlayed > 0);

    const percentArray = padArray(
        stats.map((s) =>
            isOffense
                ? s.offensiveSnapsPlayedPercentage
                : s.defensiveSnapsPlayedPercentage
        ),
        gamesInSeason,
        0
    );

    const totalPercent = percentArray.reduce((runningTotal, percent) => {
        return (runningTotal += percent);
    }, 0);

    return toTwoDecimals(totalPercent / gamesInSeason);
};
