import React, { useState } from "react";
import api from "helpers/api.helpers";

import "fantasyFootball/styles/manageNflTeams.scss";

import { useGetApi } from "common/hooks/useGetApi";

import { NflTeam } from "fantasyFootball/models/types";
import TextInput from "foundation/components/forms/TextInput";
import SelectInput from "foundation/components/forms/SelectInput";

const divisionMap = {
    N: "North",
    E: "East",
    S: "South",
    W: "West",
};

const ManageNflTeams: React.FC = () => {
    const [editMode, setEditMode] = useState(false);

    const { data: nflTeams, setData: setNflTeams } =
        useGetApi<NflTeam[]>("nfl_team");

    const { data: teamToAdd, setData: setTeamToAdd } = useGetApi<NflTeam>(
        "fantasy_football/get_nfl_team"
    );

    const resetTeamToAdd = () => {
        const emptyTeam = { ...teamToAdd };
        emptyTeam.name = "";
        emptyTeam.abbreviation = "";
        emptyTeam.location = "";
        emptyTeam.conference = "";
        emptyTeam.division = "";

        setTeamToAdd(emptyTeam);
    };

    const addTeam = () => {
        api.post("nfl_team/", teamToAdd).then(({ data }) => {
            setNflTeams([...nflTeams, data]);
            resetTeamToAdd();
        });
    };

    const deleteTeam = (id: number) => {
        api.delete(`nfl_team/${id}`).then(() => {
            setNflTeams(nflTeams.filter((team) => team.id !== id));
        });
    };

    return (
        <section>
            {nflTeams?.length === 0 && <div>No team data</div>}
            <table className="nfl-teams-table">
                <thead>
                    <tr>
                        <th>Abbr</th>
                        <th>Location</th>
                        <th>Name</th>
                        <th>Conference</th>
                        <th>Division</th>
                    </tr>
                </thead>
                <tbody>
                    {nflTeams?.map((team) => (
                        <tr key={team.id}>
                            <td>{team.abbreviation}</td>
                            <td>{team.location}</td>
                            <td>{team.name}</td>
                            <td>{team.conference}</td>
                            <td>{divisionMap[team.division]}</td>
                            {editMode && (
                                <td>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => deleteTeam(team.id)}
                                    >
                                        -
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                    {teamToAdd && editMode && (
                        <tr>
                            <td>
                                <TextInput
                                    name="abbreviation"
                                    value={teamToAdd.abbreviation}
                                    maxLength={4}
                                    onChange={(abbr) => {
                                        const newTeam = { ...teamToAdd };
                                        newTeam.abbreviation = abbr;
                                        setTeamToAdd(newTeam);
                                    }}
                                />
                            </td>
                            <td>
                                <TextInput
                                    name="location"
                                    value={teamToAdd.location}
                                    onChange={(location) => {
                                        const newTeam = { ...teamToAdd };
                                        newTeam.location = location;
                                        setTeamToAdd(newTeam);
                                    }}
                                />
                            </td>
                            <td>
                                <TextInput
                                    name="name"
                                    value={teamToAdd.name}
                                    onChange={(name) => {
                                        const newTeam = { ...teamToAdd };
                                        newTeam.name = name;
                                        setTeamToAdd(newTeam);
                                    }}
                                />
                            </td>
                            <td>
                                <SelectInput
                                    name="conference"
                                    selectedValue={teamToAdd.conference}
                                    choices={teamToAdd.CONFERENCE_CHOICES}
                                    onChange={(conference: "AFC" | "NFC") => {
                                        const newTeam = { ...teamToAdd };
                                        newTeam.conference = conference;
                                        setTeamToAdd(newTeam);
                                    }}
                                />
                            </td>
                            <td>
                                <SelectInput
                                    name="division"
                                    selectedValue={teamToAdd.division}
                                    choices={teamToAdd.DIVISION_CHOICES}
                                    onChange={(
                                        division: "N" | "E" | "S" | "W"
                                    ) => {
                                        const newTeam = { ...teamToAdd };
                                        newTeam.division = division;
                                        setTeamToAdd(newTeam);
                                    }}
                                />
                            </td>
                            <td>
                                <button
                                    className="btn btn-primary"
                                    onClick={addTeam}
                                >
                                    +
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <button
                className="btn btn-secondary edit-button"
                onClick={() => setEditMode(!editMode)}
            >
                Edit Mode
            </button>
        </section>
    );
};

export default ManageNflTeams;
