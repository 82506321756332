import React from "react";
import { useNavigate } from "react-router-dom";
import "wim/styles/wim.scss";
import useWim from "wim/hooks/useWim";
import devNoteSidebarStore from "devNotes/stores/DevNoteSidebarStore";

const Wim: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const { isWimMode } = useWim({
        h: () => navigate("/"),
        c: () => navigate("/cooking"),
        f: () => navigate("/fantasyFootball"),
        r: () => navigate("/rememberall"),
        s: () => navigate("/settings"),
        m: () => navigate("/money"),
        k: () => navigate("/skills"),
        d: () => devNoteSidebarStore.set.toggleIsOpen(),

        // d: () => navigate("/cooking/recipe/create"), // dev button
    });

    return (
        <>
            {isWimMode && <aside id="wim-mode">WIM Mode</aside>}
            {children}
        </>
    );
};

export default Wim;
