import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type StarProps = {
    className?: string;
    size?: string;
};

const Star: React.FC<StarProps> = ({ className = "", size = "16px" }) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <>
            <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 31.999 31.999"
                xmlSpace="preserve"
                fill={color}
                className={className}
                style={{ height: size, width: size }}
            >
                <g>
                    <path
                        id="star"
                        d="M31.881,12.056c-0.277-0.799-0.988-1.384-1.844-1.511l-8.326-1.238l-3.619-7.514
		C17.711,1.004,16.896,0.499,16,0.499c-0.896,0-1.711,0.505-2.092,1.294l-3.619,7.514l-8.327,1.238
		c-0.855,0.127-1.566,0.712-1.842,1.511c-0.275,0.801-0.067,1.683,0.537,2.285l6.102,6.092l-1.415,8.451
		c-0.144,0.851,0.225,1.705,0.948,2.203c0.397,0.273,0.864,0.412,1.331,0.412c0.384,0,0.769-0.094,1.118-0.281L16,27.31l7.26,3.908
		c0.349,0.187,0.734,0.281,1.117,0.281c0.467,0,0.934-0.139,1.332-0.412c0.723-0.498,1.09-1.352,0.947-2.203l-1.416-8.451
		l6.104-6.092C31.947,13.738,32.154,12.856,31.881,12.056z M23.588,18.862c-0.512,0.51-0.744,1.229-0.627,1.934l1.416,8.451
		l-7.26-3.906c-0.348-0.188-0.732-0.281-1.118-0.281c-0.384,0-0.769,0.094-1.117,0.281l-7.26,3.906l1.416-8.451
		c0.118-0.705-0.114-1.424-0.626-1.934L2.31,12.77l8.326-1.24c0.761-0.113,1.416-0.589,1.743-1.268L16,2.75l3.62,7.513
		c0.328,0.679,0.982,1.154,1.742,1.268l8.328,1.24L23.588,18.862z"
                    />
                </g>
            </svg>
        </>
    );
};

export default Star;
