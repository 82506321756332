import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type AvocadoDetailsIconProps = {
    className?: string;
    size?: string;
    filledVersion?: boolean;
};

const AvocadoDetailsIcon: React.FC<AvocadoDetailsIconProps> = ({
    className = "",
    size,
    filledVersion = true,
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    //filled
    if (filledVersion) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 64 64"
                xmlSpace="preserve"
                height={size}
                width={size}
                className={className}
            >
                <g fill={color}>
                    <path d="M32.1300049,26.4699707c-3.5900269-2.9500122-5.4100342-6.3599854-5.4100342-10.1300049    c0-4.8499756-3.0899658-8.7299805-7.6899414-9.6499634C14.75,5.8399658,9.2600098,7.8099976,6.6199951,14.1300049    C2.0599976,25.0899658,2.0100098,30.6400146,2.0100098,30.8099976C2,30.9199829,1.4500122,42.4899902,12.5700073,47.2799683    c6.8900146,3.0599976,15.3099976,1.7399902,20.539978-3.7199707    C37.8099976,38.9500122,37.3099976,30.5299683,32.1300049,26.4699707z M14.7299805,34.5100098    c0-3.0599976,2.4800415-5.5400391,5.5400391-5.5400391c3.0499878,0,5.539978,2.4800415,5.539978,5.5400391    C25.5499878,41.8399658,14.9899902,41.8399658,14.7299805,34.5100098z" />
                    <path d="M43.9978523,51.4964943H32.4114265L29.703907,48.789463c-0.9245892-0.9197426-2.3372993,0.4848442-1.414011,1.4141045    c-0.0000515-0.000042,3.0004368,2.999958,3.0004368,2.999958c0.1875,0.1875,0.4418945,0.2929688,0.7070313,0.2929688h12.0004883    C45.3064842,53.4754791,45.3169746,51.5195503,43.9978523,51.4964943z" />
                    <path d="M29.9983406,17.4964924c0.2084961,0,0.4189453-0.0649414,0.5991211-0.2001953l3.7333984-2.7998037h11.6669922    c1.3086395-0.0202284,1.3188667-1.9774923-0.0000458-1.9999971c0.0000458-0.0000029-12.0004425-0.0000029-12.0004425-0.0000029    c-0.2163086,0-0.4267578,0.0703125-0.6000977,0.2001953l-4,3C28.6267891,16.2329979,29.074398,17.5323257,29.9983406,17.4964924z" />
                    <path d="M45.9978523,31.4964924H38.997364c-1.3123055,0.0216999-1.3159599,1.977602,0.0000458,1.9999981    c-0.0000458,0.0000038,7.0004425,0.0000038,7.0004425,0.0000038    C47.3101616,33.4747963,47.3138123,31.5188904,45.9978523,31.4964924z" />
                    <path d="M62,9.5v8c0,0.5499878-0.4500122,1-1,1H50c-0.5499878,0-1-0.4500122-1-1v-8c0-0.5599976,0.4500122-1,1-1h11    C61.5499878,8.5,62,8.9400024,62,9.5z" />
                    <path d="M62,27.5v9c0,0.5499878-0.4500122,1-1,1H50c-0.5499878,0-1-0.4500122-1-1v-9c0-0.5599976,0.4500122-1,1-1h11    C61.5499878,26.5,62,26.9400024,62,27.5z" />
                    <path d="M62,47.5v9c0,0.5499878-0.4500122,1-1,1H48c-0.5499878,0-1-0.4500122-1-1v-9c0-0.5599976,0.4500122-1,1-1h13    C61.5499878,46.5,62,46.9400024,62,47.5z" />
                </g>
            </svg>
        );
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            xmlSpace="preserve"
            height={size}
            width={size}
            className={className}
        >
            <g fill={color}>
                <path d="M33.1140633,43.5638771c4.6913643-4.6162987,4.1932945-13.0338421-0.9825974-17.090807    c-3.592598-2.9506989-5.4143753-6.3613434-5.4143753-10.136734c0-4.8481436-3.0913086-8.7231436-7.6918945-9.6430655    c-4.2783194-0.8569336-9.7680655,1.1137695-12.4023428,7.4389648C2.0583999,25.0902424,2.0061538,30.6361408,2.0081069,30.8070393    c-0.0073242,0.1166992-0.5629883,11.6860371,10.5644531,16.4682636    C19.4646931,50.3364563,27.8840771,49.0163002,33.1140633,43.5638771z M13.3616228,45.4374123    C3.5476577,41.2196388,3.9812515,31.3426838,4.0061541,30.869051c0.0004883-0.0532227,0.0791016-5.4423828,4.4633789-15.9682608    c2.4095221-5.4076128,6.2003193-6.8554296,10.1634798-6.2467241c2.8067341,0.5611773,6.0840778,2.9098101,6.0840778,7.6822701    c0,4.3408203,2.125,8.3808594,6.1455078,11.6831055c0.0004883,0,0.0004883,0,0.0004883,0.0004883    c4.2797012,3.3517036,4.6977882,10.3325558,0.819191,14.1474667C26.9909,46.9871788,19.7350674,48.2058945,13.3616228,45.4374123z    " />
                <path d="M20.2668953,28.9662189c-3.0551758,0-5.5405264,2.4853516-5.5405264,5.540041    c0.2601471,7.3320198,10.8214045,7.3306389,11.0805712-0.0000916    C25.8069344,31.4515705,23.3215828,28.9662189,20.2668953,28.9662189z M20.2668953,38.046299    c-4.678997-0.1486511-4.677906-6.932127,0.0000782-7.08008C24.9452858,31.1146793,24.9441948,37.8985329,20.2668953,38.046299z" />
                <path d="M43.9978523,51.4964943H32.4114265L29.703907,48.789463c-0.9245892-0.9197426-2.3372993,0.4848442-1.414011,1.4141045    c-0.0000515-0.000042,3.0004368,2.999958,3.0004368,2.999958c0.1875,0.1875,0.4418945,0.2929688,0.7070313,0.2929688h12.0004883    C45.3064842,53.4754791,45.3169746,51.5195503,43.9978523,51.4964943z" />
                <path d="M60.9983406,46.4964943H47.9978523c-0.5522461,0-1,0.4477539-1,1v9c0,0.5522461,0.4477539,1,1,1h13.0004883    c0.5522461,0,1-0.4477539,1-1v-9C61.9983406,46.9442482,61.5505867,46.4964943,60.9983406,46.4964943z M59.9983406,55.4964943    H48.9978523v-7h11.0004883V55.4964943z" />
                <path d="M29.9983406,17.4964924c0.2084961,0,0.4189453-0.0649414,0.5991211-0.2001953l3.7333984-2.7998037h11.6669922    c1.3086395-0.0202284,1.3188667-1.9774923-0.0000458-1.9999971c0.0000458-0.0000029-12.0004425-0.0000029-12.0004425-0.0000029    c-0.2163086,0-0.4267578,0.0703125-0.6000977,0.2001953l-4,3C28.6267891,16.2329979,29.074398,17.5323257,29.9983406,17.4964924z" />
                <path d="M60.9983406,8.4964933H49.9978523c-0.5522461,0-1,0.4477539-1,1v7.999999c0,0.5522461,0.4477539,1,1,1h11.0004883    c0.5522461,0,1-0.4477539,1-1v-7.999999C61.9983406,8.9442472,61.5505867,8.4964933,60.9983406,8.4964933z M59.9983406,16.4964924    h-9.0004883v-5.999999h9.0004883V16.4964924z" />
                <path d="M45.9978523,31.4964924H38.997364c-1.3123055,0.0216999-1.3159599,1.977602,0.0000458,1.9999981    c-0.0000458,0.0000038,7.0004425,0.0000038,7.0004425,0.0000038    C47.3101616,33.4747963,47.3138123,31.5188904,45.9978523,31.4964924z" />
                <path d="M60.9983406,26.4964924H49.9978523c-0.5522461,0-1,0.4477539-1,1v9.0000019c0,0.5522461,0.4477539,1,1,1h11.0004883    c0.5522461,0,1-0.4477539,1-1v-9.0000019C61.9983406,26.9442463,61.5505867,26.4964924,60.9983406,26.4964924z     M59.9983406,35.4964943h-9.0004883v-7.0000019h9.0004883V35.4964943z" />
            </g>
        </svg>
    );
};

export default AvocadoDetailsIcon;
