import { createStore } from "@udecode/zustood";

type DiscreteStore = {
    discreteModeOn: boolean;
};

const discreteStore = createStore("discreteStore")<DiscreteStore>(
    {
        discreteModeOn: false,
    },
    {
        persist: { name: "discreteMode", enabled: true },
    }
);

export default discreteStore;
