import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type CookingIconProps = {
    className?: string;
    color?: string;
};

const CookingIcon: React.FC<CookingIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 90 112.5"
            enableBackground="new 0 0 90 90"
            fill={color}
            className={className}
        >
            <g>
                <path d="M80.881,60.013l-7.359,3.288l-63.064-11.62l-5.704-5.696c-0.641-0.639-1.679-0.639-2.317,0.003   c-0.64,0.639-0.639,1.677,0.002,2.318l6.766,6.756c5.188,8.409,16.254,15.442,29.924,17.962c13.671,2.518,26.517-0.11,34.361-6.118   l8.73-3.9c0.826-0.369,1.197-1.339,0.826-2.165C82.676,60.014,81.707,59.643,80.881,60.013z" />
                <path d="M59.953,56.658c1.363-1.364,6.338-8.725,6.338-10.564c0-1.841-1.295-4.022,0.068-5.386c1.02-1.02,0.502-1.493,0.938-2.428   c-0.271-0.158-0.553-0.38-0.84-0.667s-0.51-0.57-0.668-0.84c-0.934,0.436-1.408-0.084-2.43,0.936   c-1.361,1.363-3.543,0.068-5.383,0.068c-1.842,0-9.201,4.977-10.564,6.34c-1.365,1.362-0.996,2.087,4.729,7.811   C57.867,57.655,58.588,58.021,59.953,56.658z M61.805,47.648c0.357,0.317,0.391,0.867,0.07,1.225l-4.068,4.567   c-0.318,0.359-0.867,0.391-1.225,0.071c-0.014-0.012-0.025-0.023-0.037-0.035c-0.324-0.322-0.342-0.845-0.035-1.189l4.07-4.567   C60.898,47.361,61.447,47.33,61.805,47.648z M59.113,44.955c0.34,0.339,0.34,0.889,0,1.227l-4.318,4.319   c-0.338,0.34-0.887,0.34-1.227,0c-0.338-0.339-0.338-0.888,0-1.227l4.318-4.32C58.227,44.616,58.775,44.616,59.113,44.955z    M50.592,47.525c-0.012-0.012-0.023-0.024-0.033-0.037c-0.32-0.357-0.289-0.906,0.07-1.225l4.566-4.07   c0.359-0.319,0.904-0.289,1.227,0.07c0.318,0.357,0.285,0.906-0.072,1.225l-4.566,4.071C51.438,47.867,50.914,47.847,50.592,47.525   z" />
                <path d="M87.557,16.512c-0.646-0.648-1.699-0.648-2.346,0l-19.102,19.1c-0.648,0.649,1.699,2.997,2.348,2.348l19.1-19.101   C88.205,18.211,88.205,17.161,87.557,16.512z M86.855,18.44l-1.594,1.593c-0.338,0.338-0.889,0.338-1.227,0   c-0.338-0.339-0.338-0.888,0-1.227l1.594-1.593c0.338-0.339,0.887-0.339,1.227,0C87.193,17.553,87.193,18.102,86.855,18.44z" />
                <path d="M36.576,50.084c2.33,0.221,4.794-1.662,5.79-4.594c0.998-2.933,0.192-5.927-1.79-7.173   c0.808,1.854,0.911,4.243,0.108,6.601C39.883,47.275,38.347,49.107,36.576,50.084z" />
                <path d="M22.036,49.674c2.933,0.996,5.926,0.19,7.173-1.791c-1.855,0.809-4.243,0.91-6.601,0.109   c-2.358-0.802-4.189-2.339-5.168-4.11C17.221,46.214,19.103,48.677,22.036,49.674z" />
                <path d="M32.833,44.208c-1.013-2.319-1.139-5.306-0.137-8.256c1.002-2.948,2.924-5.239,5.14-6.462   c-2.916-0.275-5.996,2.08-7.243,5.748C29.345,38.906,30.353,42.651,32.833,44.208z" />
            </g>
        </svg>
    );
};

export default CookingIcon;
