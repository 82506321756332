import React from "react";

import CookingIcon from "foundation/svgComponents/CookingIcon";
import LibraryIcon from "foundation/svgComponents/LibraryIcon";
import DevNotesIcon from "foundation/svgComponents/DevNotesIcon";
import FantasyFootballIcon from "foundation/svgComponents/FantasyFootballIcon";
import JournalIcon from "foundation/svgComponents/JournalIcon";
import QuoteIcon from "foundation/svgComponents/QuoteIcon";
import RememberallIcon from "foundation/svgComponents/RememberallIcon";
import SettingsIcon from "foundation/svgComponents/SettingsIcon";
import TaskTrackerIcon from "foundation/svgComponents/TaskTrackerIcon";
import WikiIcon from "foundation/svgComponents/WikiIcon";
import WimIcon from "foundation/svgComponents/WimIcon";
import MoneyIcon from "foundation/svgComponents/MoneyIcon";
import SkillsIcon from "foundation/svgComponents/SkillsIcon";

export const Activities = {
    cooking: {
        title: "Cooking",
        subtitle: "Let's get cooking!",
        linkTo: "/cooking",
        svgComponentIcon: <CookingIcon />,
    },
    settings: {
        title: "Settings",
        subtitle: "User settings. Site-wide theme.",
        linkTo: "/settings",
        svgComponentIcon: <SettingsIcon />,
    },
    fantasyFootball: {
        title: "Fantasy Football",
        subtitle:
            "Track FF teams. Track some metrics that I care about. Draft city.",
        linkTo: "/fantasyFootball",
        svgComponentIcon: <FantasyFootballIcon />,
    },
    devNotes: {
        title: "Dev Notes (TODO)",
        subtitle: "Ideas and bugs",
        linkTo: "/",
        svgComponentIcon: <DevNotesIcon />,
    },
    library: {
        title: "Library (TODO)",
        subtitle:
            "Track my books. What I'm reading. What books I physically own. Who I've loanded them to.",
        linkTo: "/",
        svgComponentIcon: <LibraryIcon />,
    },
    journal: {
        title: "Journal",
        subtitle:
            "To reflect, muse. Privately, like a true journal or publicly, like a blog.",
        linkTo: "/",
        svgComponentIcon: <JournalIcon />,
    },
    rememberall: {
        title: "Rememberall",
        subtitle: "Remember stuff for you",
        linkTo: "/rememberall",
        svgComponentIcon: <RememberallIcon />,
    },
    taskTracker: {
        title: "Task Tracker",
        subtitle:
            "Track tasks (chores, stuff you want to do) for you. (maybe in Rememberall?)",
        linkTo: "/",
        svgComponentIcon: <TaskTrackerIcon />,
    },
    quotes: {
        title: "Quotes",
        subtitle: '"You know what it is" - Wiz Khalifa',
        linkTo: "/quotes",
        svgComponentIcon: <QuoteIcon />,
    },
    wiki: {
        title: "Wiki-like thing",
        subtitle: "For coding notes / style / how to / overall design tracker",
        linkTo: "/",
        svgComponentIcon: <WikiIcon />,
    },
    wim: {
        title: "Wim (web VIM)",
        subtitle: "VIM keybind inspired shortcut system",
        linkTo: "/wim",
        svgComponentIcon: <WimIcon />,
    },
    stats: {
        title: "Stats",
        subtitle: "Skilling IRL",
        linkTo: "/",
    },
    money: {
        title: "Money",
        subtitle: "Track cash flow",
        linkTo: "/money",
        svgComponentIcon: <MoneyIcon />,
    },
    skills: {
        title: "Skills",
        subtitle: "Skilling IRL",
        linkTo: "/skills",
        svgComponentIcon: <SkillsIcon />,
    },
};
