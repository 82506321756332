import React from "react";
import { Recipe } from "cooking/models/types";
import RecipeRow from "cooking/components/RecipeRow";

type RecipeRowsProps = {
    recipes: Recipe[];
    onClick?: (recipe: Recipe) => void;
    rowIcon?: React.ReactNode;
};

const RecipeRows: React.FC<RecipeRowsProps> = ({
    recipes,
    onClick,
    rowIcon,
}) => {
    return (
        <ul className="p-0">
            {recipes.map((recipe) => (
                <RecipeRow
                    key={recipe.id}
                    recipe={recipe}
                    onClick={onClick}
                    rowIcon={rowIcon}
                />
            ))}
        </ul>
    );
};

export default RecipeRows;
