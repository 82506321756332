import React from "react";
import api from "helpers/api.helpers";
import { Link } from "react-router-dom";
import { useGetApi } from "common/hooks/useGetApi";

import "fantasyFootball/styles/manageStats.scss";

import { getWeeksArrayForSeason } from "fantasyFootball/helpers/season.helpers";
import { NflTeam } from "fantasyFootball/models/types";

const ManageStats: React.FC = () => {
    const season = 2021;

    const { data: nflTeams } = useGetApi<NflTeam[]>("nfl_team");
    const { data: seasonStatsData } = useGetApi<any | undefined>(
        `fantasy_football/season_stats/${season}`
    );

    let seasonStats: undefined | any[];
    if (seasonStatsData) {
        seasonStats = seasonStatsData.season_stats;
        console.log("seasonStats:", seasonStats);
    }

    const gatherDataForWeek = (weekNumber: number) => {
        api.get(
            `fantasy_football/load_nfl_data_for_week/${season}/${weekNumber}`
        ).then(({ data }) => console.log(data));
    };

    const getNflTeamById = (id: number): NflTeam => {
        return nflTeams?.find((team) => team.id === id);
    };

    // const gatherSeasonData = () => {
    //     setIsGatheringData(true);
    //     api.get("fantasy_football/load_nfl_season_data/2021").then((resp) => {
    //         console.log(resp);
    //         setIsGatheringData(false);
    //     });
    // };

    return (
        <section>
            <Link to="/fantasyFootball/manage/nflTeams">
                <button className="btn btn-primary">Manage NFL Teams</button>
            </Link>
            <br />
            <br />
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Week</th>
                        <th>Game 1</th>
                        <th>Game 2</th>
                        <th>...</th>
                    </tr>
                </thead>
                <tbody>
                    {seasonStats &&
                        getWeeksArrayForSeason(season).map((weekNumber) => {
                            const week = seasonStats[weekNumber];

                            return (
                                <tr key={weekNumber}>
                                    <td>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                                gatherDataForWeek(weekNumber)
                                            }
                                        >
                                            Get
                                        </button>
                                    </td>
                                    <td>{weekNumber}</td>
                                    {week &&
                                        week.map((game, index) => (
                                            <td key={index}>
                                                {getNflTeamById(game.home_team)
                                                    ?.abbreviation ?? "??"}
                                                {" v "}
                                                {getNflTeamById(game.away_team)
                                                    ?.abbreviation ?? "??"}
                                                <br />
                                                {game.home_team_score}-
                                                {game.away_team_score}
                                            </td>
                                        ))}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </section>
    );
};

// <thead>
//     <tr>
//         <th>Team/Week</th>

//         {/* These will be mapped from the week stats objects */}
//         <th>1</th>
//         <th>2</th>
//         <th>3</th>
//         <th>4</th>
//         <th>5</th>
//         <th>6</th>
//     </tr>
// </thead>
// <tbody>
//     {nflTeams &&
//         nflTeams.map((team) => (
//             <tr key={team.id}>
//                 <td>{team.name}</td>
//                 {/* Have stats y/n */}
//                 <td>Y</td>
//                 <td>Y</td>
//                 <td>N</td>
//                 <td>...</td>
//             </tr>
//         ))}
// </tbody>

export default ManageStats;
