import React, { useState } from "react";

type TabsProps = {
    tabs: {
        name: string;
        content?: JSX.Element | string;
        onClick?: () => void;
    }[];
};

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    if (tabs.length === 0) return undefined;

    return (
        <div className="flex flex-col gap-[10px]">
            <div className="flex">
                {tabs.map((tab, index) => {
                    const isActive = index === currentTabIndex;
                    return (
                        <button
                            className={`px-1 text-theme-text text-[22px] leading-[34px] tracking-[0.5px] border-r-[2px] last:border-r-0 border-solid border-theme-text focus:outline-none focus:!shadow-none ${isActive
                                    ? "bg-theme-background-accent"
                                    : "bg-theme-background"
                                }`}
                            onClick={() => {
                                setCurrentTabIndex(index);
                                if (tab.onClick) tab.onClick();
                            }}
                            key={index}
                        >
                            {tab.name}
                        </button>
                    );
                })}
            </div>
            <section>{tabs[currentTabIndex].content}</section>
        </div>
    );
};

export default Tabs;
