import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";
import { Link } from "react-router-dom";

type PencilProps = {
    className?: string;
    size?: string;
    linkTo?: string;
    title?: string;
};

const Pencil: React.FC<PencilProps> = ({
    className = "x-icon",
    size = "20px",
    linkTo,
    title,
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    const pencilSvg = (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="528.899px"
            height="528.899px"
            viewBox="0 0 528.899 528.899"
            // style="enable-background:new 0 0 528.899 528.899;"
            // xml:space="preserve"
            fill={color}
            className={className}
            style={{ height: size, width: size }}
        >
            <g>
                <path
                    d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
		c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
		C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
		L27.473,390.597L0.3,512.69z"
                />
            </g>
        </svg>
    );

    if (linkTo) {
        return (
            <Link to={linkTo} title={title}>
                {pencilSvg}
            </Link>
        );
    }

    return <>{pencilSvg}</>;
};

export default Pencil;
