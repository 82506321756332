import React, { useState, useRef } from "react";
import { cloneDeep } from "lodash";
import DatePicker from "react-datepicker";

import useTransactions from "money/hooks/useTransactions";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";

import EditTags from "foundation/components/forms/EditTags";
import ButtonGroupChoiceToggle from "foundation/components/forms/ButtonGroupChoiceToggle";
import TextInput from "foundation/components/forms/TextInput";
import PlusButton from "foundation/components/PlusButton";

import { Transaction } from "money/models/types";
import EditActiveTags from "foundation/components/forms/EditActiveTags";

const emptyTransaction: Transaction = {
    id: -1,
    creator: undefined,
    dateCreated: new Date().toString(),
    transactionDate: new Date().toString(),
    name: "",
    amount: undefined,
    FREQUENCY_CHOICES: [
        ["once", "one-time"],
        ["month", "monthly"],
    ],
    frequency: "once",
    DIRECTION_CHOICES: [
        ["out", "expense"],
        ["in", "income"],
    ],
    direction: "out",
    category: "",
    people: [
        {
            content: "Kyle",
            isActive: false,
        },
        {
            content: "Sierra",
            isActive: false,
        },
        {
            content: "Both",
            isActive: false,
        },
    ],
};

const AddTransactionBox: React.FC = () => {
    const { currentUser } = useCurrentUser();
    const amountRef = useRef<HTMLInputElement>();

    const { createTransaction } = useTransactions(false);
    const [transaction, setTransaction] = useState(emptyTransaction);
    transaction.creator = currentUser?.id;

    const updateTransaction = (key: string, newValue: any) => {
        let updatedTransaction = cloneDeep(transaction);
        updatedTransaction[key] = newValue;

        setTransaction(updatedTransaction);
    };

    const handleAddTransaction = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!transaction.name || !transaction.amount) {
            console.error("Missing name or amount");
            return;
        }

        setTransaction(emptyTransaction);
        amountRef.current.focus();
        createTransaction(transaction);
    };

    return (
        <div className="border-solid border-theme-border border-[2px] p-[20px] w-fit">
            <h3 className="m-0">Add Transaction</h3>
            <form
                className="flex flex-col p-[10px] gap-[10px]"
                onSubmit={handleAddTransaction}
            >
                <ButtonGroupChoiceToggle
                    name="direction"
                    className="grow-0 w-fit"
                    labelClassName="p-1"
                    selectedValue={transaction.direction}
                    choices={transaction.DIRECTION_CHOICES}
                    handleChange={(newVal) => {
                        updateTransaction("direction", newVal);
                        amountRef.current.focus();
                    }}
                />

                <div className="flex gap-[10px] items-center">
                    <span className="text-[42px]">$</span>
                    <TextInput
                        name="amount"
                        value={transaction.amount}
                        innerRef={amountRef}
                        placeholder=""
                        className="max-w-[200px] w-[120px]"
                        extraInputClasses="!text-[42px] !text-theme-input-background"
                        required
                        onChange={(newVal) =>
                            updateTransaction("amount", newVal)
                        }
                        type="number"
                        inverse
                    />
                    <TextInput
                        name="name"
                        value={transaction.name}
                        placeholder={
                            transaction.direction === "out"
                                ? "on..."
                                : "from..."
                        }
                        extraInputClasses="!text-[32px]"
                        required
                        onChange={(newVal) => updateTransaction("name", newVal)}
                    />
                </div>

                <div className="flex items-center gap-[20px] mt-[10px] mx-auto">
                    <div className="flex items-center text-[22px]">
                        <label className="mr-[10px]">Transaction Date:</label>
                        <DatePicker
                            className="rounded-md px-[5px] w-[125px]"
                            selected={
                                transaction.transactionDate
                                    ? new Date(transaction.transactionDate)
                                    : undefined
                            }
                            onChange={(date) => {
                                updateTransaction("transactionDate", date);
                            }}
                        />
                    </div>
                    <ButtonGroupChoiceToggle
                        name="frequency-name"
                        className=""
                        labelClassName="p-1"
                        selectedValue={transaction.frequency}
                        choices={transaction.FREQUENCY_CHOICES}
                        handleChange={(newVal) =>
                            updateTransaction("frequency", newVal)
                        }
                    />
                    {/*
                    <DatePicker
                        selected={
                            transaction.effectiveDate
                                ? new Date(transaction.effectiveDate)
                                : undefined
                        }
                        onChange={(date) => {
                            updateTransaction("effectiveDate", date);
                        }}
                        className="form-control h-[32px] ml-[2px]"
                    />
                    */}
                </div>

                {/* TODO repeated code with RecipeFields <EditTags /> DRY it up */}
                <EditActiveTags
                    label="People:"
                    tags={transaction.people}
                    setTags={(newTags) =>
                        updateTransaction(
                            "people",
                            newTags
                            // newTags.map((tagString) => {
                            //     if (tagStrings.includes(tagString)) {
                            //         // If we already had this tag, preserve its data (i.e. its id)
                            //         const oldTag = transaction.people.find(
                            //             (tag) => tag.content === tagString
                            //         );
                            //         if (oldTag) {
                            //             return oldTag;
                            //         }
                            //     }
                            //     return { content: tagString };
                            // })
                        )
                    }
                />

                <TextInput
                    name="category"
                    className="mt-[10px]"
                    value={transaction.category}
                    placeholder="Category"
                    extraInputClasses="!text-[22px]"
                    onChange={(newVal) => updateTransaction("category", newVal)}
                />

                <PlusButton
                    size="32px"
                    className="mx-auto mt-[30px]"
                    title="Add"
                    type="submit"
                    text="Add"
                />
            </form>
        </div>
    );
};

export default AddTransactionBox;
