// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  display: flex;
  align-items: center;
  border: var(--button-primary-border) 1px solid;
  border-radius: 10px;
  background-color: var(--button-primary);
  color: var(--button-primary-text);
  margin-left: 3px;
  margin-right: 3px;
  padding: 2px 5px; }
  .tag.inactive {
    opacity: 0.5; }
  .tag .x-button {
    margin-left: 4px; }
  .tag .add-tag-input {
    background-color: transparent !important;
    border: none;
    padding: 0;
    display: flex;
    flex-grow: 0;
    flex-shrink: 1; }

.tag-filter {
  border: var(--button-primary-border) 1px solid;
  border-radius: 10px;
  padding: 2px 5px;
  color: var(--text-color);
  background-color: transparent; }
  .tag-filter:not(:last-child) {
    margin-right: 3px; }
  .tag-filter.active {
    background-color: var(--button-primary);
    color: var(--button-primary-text); }
`, "",{"version":3,"sources":["webpack://./src/foundation/styles/Tags.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EAEnB,8CAA8C;EAC9C,mBAAmB;EACnB,uCAAuC;EACvC,iCAAiC;EAEjC,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB,EAAA;EAXpB;IAcQ,YAAY,EAAA;EAdpB;IAkBQ,gBAAgB,EAAA;EAlBxB;IAsBQ,wCAAwC;IACxC,YAAY;IACZ,UAAU;IAEV,aAAa;IACb,YAAY;IACZ,cAAc,EAAA;;AAItB;EACI,8CAA8C;EAC9C,mBAAmB;EAEnB,gBAAgB;EAMhB,wBAAwB;EACxB,6BAA6B,EAAA;EAXjC;IAOQ,iBAAiB,EAAA;EAPzB;IAcQ,uCAAuC;IACvC,iCAAiC,EAAA","sourcesContent":[".tag {\n    display: flex;\n    align-items: center;\n\n    border: var(--button-primary-border) 1px solid;\n    border-radius: 10px;\n    background-color: var(--button-primary);\n    color: var(--button-primary-text);\n\n    margin-left: 3px;\n    margin-right: 3px;\n    padding: 2px 5px;\n\n    &.inactive {\n        opacity: 0.5;\n    }\n\n    .x-button {\n        margin-left: 4px;\n    }\n\n    .add-tag-input {\n        background-color: transparent !important;\n        border: none;\n        padding: 0;\n\n        display: flex;\n        flex-grow: 0;\n        flex-shrink: 1;\n    }\n}\n\n.tag-filter {\n    border: var(--button-primary-border) 1px solid;\n    border-radius: 10px;\n\n    padding: 2px 5px;\n\n    &:not(:last-child) {\n        margin-right: 3px;\n    }\n\n    color: var(--text-color);\n    background-color: transparent;\n\n    &.active {\n        background-color: var(--button-primary);\n        color: var(--button-primary-text);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
