import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";

import ViewTags from "foundation/components/ViewTags";
import ViewRecipeDetail from "cooking/components/ViewRecipeDetail";

import { RecipeWrapper } from "cooking/models/cooking.model.helpers";
import { Recipe } from "cooking/models/types";

type ViewRecipeDetailsProps = {
    recipe: Recipe;
};

const ViewRecipeDetails: React.FC<ViewRecipeDetailsProps> = ({ recipe }) => {
    const { currentUser } = useCurrentUser();
    const recipeWrapper = new RecipeWrapper(recipe);

    return (
        <div className="flex flex-col gap-[10px] w-fit mx-auto">
            <ViewTags tags={recipe.tags} />

            {recipeWrapper.hasAnyBasicDetails && (
                <div className="flex gap-[10px] justify-center">
                    <ViewRecipeDetail
                        name="Rating"
                        value={recipeWrapper.ratingDisplay}
                        display={recipeWrapper.shouldDisplayRating}
                    />
                    <ViewRecipeDetail
                        name="Difficulty"
                        value={recipeWrapper.difficultyDisplay}
                        display={recipeWrapper.shouldDisplayDifficulty}
                    />
                    <ViewRecipeDetail
                        name="Servings"
                        value={recipe.servings}
                        display={recipeWrapper.shouldDisplayServings}
                    />
                    <ViewRecipeDetail
                        name="Time"
                        value={recipe.time}
                        display={recipeWrapper.shouldDisplayTime}
                    />
                </div>
            )}
            {recipeWrapper.userIsRecipeCreator(currentUser) && (
                <div className="flex gap-[10px] justify-center">
                    <ViewRecipeDetail
                        name="Desire to Make"
                        value={recipeWrapper.desireToMakeDisplay}
                        display={recipeWrapper.shouldDisplayDesireToMake}
                    />
                    {/* <ViewRecipeDetail
                        name="Status"
                        value={recipeWrapper.statusDisplay}
                    /> */}
                    <ViewRecipeDetail
                        name="Source"
                        value={recipe.source}
                        display={recipeWrapper.shouldDisplaySource}
                    />
                </div>
            )}

            {recipe.description && (
                <span className="text-theme-text text-center max-w-[600px] px-[20px] text-[18px]">
                    {recipe.description}
                </span>
            )}
        </div>
    );
};

export default ViewRecipeDetails;
