import React from "react";

import ActivityLink, {
    ActivityLinkProps,
} from "foundation/components/ActivityLink";
import ActivityLinkContainer from "foundation/components/ActivityLinkContainer";

type ActivityLinksProps = {
    sectionTitle?: string;
    activities: ActivityLinkProps[];
};

const ActivityLinks: React.FC<ActivityLinksProps> = ({
    sectionTitle,
    activities,
}) => {
    return (
        <div>
            {sectionTitle && <h2>{sectionTitle}</h2>}
            <ActivityLinkContainer>
                {activities?.map((activity) => (
                    <ActivityLink
                        key={activity.title}
                        {...activity}
                        noRightBorder={activities.length < 3}
                    />
                ))}
            </ActivityLinkContainer>
        </div>
    );
};

export default ActivityLinks;
