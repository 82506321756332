import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type WimIconProps = {
    className?: string;
};

const WimIcon: React.FC<WimIconProps> = ({
    className = "home-activity-icon",
}) => {
    const { currentUser } = useCurrentUser();
    const color = getThemeTextColor(currentUser);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={color}
            className={className}
            viewBox="0 0 24 24"
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                d="M2.5,19.5 L19.5,2.5 L21.5,4.5 L4.5,21.5 L2.5,19.5 Z M3,20 L15,8 L16,9 L4,21 L3,20 Z M5.5,3 L5,3.5 L5.5,4 L6,3.5 L5.5,3 Z M11.5,3 L11,3.5 L11.5,4 L12,3.5 L11.5,3 Z M8.5,6 L8,6.5 L8.5,7 L9,6.5 L8.5,6 Z M20.5,12 L20,12.5 L20.5,13 L21,12.5 L20.5,12 Z M20.5,17 L20,17.5 L20.5,18 L21,17.5 L20.5,17 Z"
            />
        </svg>
    );
};

export default WimIcon;
