import React from "react";
import { Link } from "react-router-dom";
import { useGetApi } from "common/hooks/useGetApi";

import { AppsEnum } from "foundation/apps";
import appStore from "foundation/stores/AppStore";
import Pencil from "foundation/svgComponents/Pencil";

import { ICON_MAP } from "skills/helpers/icons";
import { TrackedSkill } from "skills/models/types";

const SkillsHome: React.FC = () => {
    appStore.set.currentApp(AppsEnum.Skills);

    const { data: trackedSkills } = useGetApi<TrackedSkill[]>("tracked_skill");

    console.log("🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆🎆");
    console.log("trackedSkills:", trackedSkills);

    const trackedSkillsExist = trackedSkills?.length > 0;

    return (
        <div className="flex flex-col gap-[40px]">
            <h1>Skills 📊</h1>

            {trackedSkillsExist && (
                <div>
                    <h2 className="flex gap-[16px] items-center">
                        Tracked Skills
                        <Link to="track" role="button">
                            <Pencil />
                        </Link>
                    </h2>

                    <ul className="flex flex-col gap-10px pl-[5px]">
                        {trackedSkills.map((trackedSkill, index) => (
                            <li key={index}>
                                <Link
                                    className="flex gap-[5px] items-center text-[20px] hover:bg-theme-background-accent w-fit min-w-[222px] no-underline text-theme-text"
                                    to={`${trackedSkill.id}`}
                                >
                                    {trackedSkill.skill.icon && (
                                        <div className="h-[80px] w-[80px] flex items-center">
                                            {ICON_MAP[trackedSkill.skill.icon]}
                                        </div>
                                    )}
                                    {trackedSkill.skill.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {!trackedSkillsExist && (
                <div className="flex flex-col gap-[10px] text-[18px]">
                    You don't have a any tracked skills!
                    <Link
                        to="track"
                        role="button"
                        className="btn btn-primary w-fit"
                    >
                        Select Skills to Track
                    </Link>
                </div>
            )}
        </div>
    );
};

export default SkillsHome;
