import React from "react";

type ActivityLinkContainerProps = { children?: React.ReactNode };

const ActivityLinkContainer: React.FC<ActivityLinkContainerProps> = ({
    children,
}) => {
    return (
        <div className="relative">
            {/* This span covers the outer border of the activities, which is why it's postitioned very particularly */}
            <span className="absolute top-[-4px] bottom-[-4px] left-[-3px] right-[1px] ml-[-2px] z-10 border-solid border-[8px] border-theme-background pointer-events-none"></span>

            {children}
        </div>
    );
};

export default ActivityLinkContainer;
