import { Ingredient, KitchenIngredient } from "cooking/models/types";

export const getEmptyKitchenIngredient = (
    ingredient: Ingredient
): KitchenIngredient => {
    return {
        id: -1,
        ingredient,
        inStock: false,
        tags: [],
    };
};

export const getEmptyIngredient = (): Ingredient => {
    return {
        id: -1,
        name: "",
        description: "",
        tags: [],
    };
};
