import React, { useState } from "react";
import { cloneDeep } from "lodash";
import api from "helpers/api.helpers";

import useModal from "foundation/modals/useModal";
import TextInput from "foundation/components/forms/TextInput";

import { Ingredient } from "cooking/models/types";

type EditIngredientModalProps = {
    ingredient: Ingredient;
    onSubmit?: (ingredient: Ingredient) => void;
};

const EditIngredientModal: React.FC<EditIngredientModalProps> = ({
    ingredient: _ingredient,
    onSubmit,
}) => {
    const { closeModal } = useModal();

    const [ingredient, setIngredient] = useState<Ingredient>(_ingredient);

    const handleSubmit = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();

        api.post(`createEditIngredient/${ingredient.id}`, ingredient)
            .then(() => onSubmit?.(ingredient))
            .catch((err) => console.log(err))
            .finally(closeModal);
    };

    const updateIngredient = (key: string, newValue: any) => {
        let updatedIngredient = cloneDeep(ingredient);
        updatedIngredient[key] = newValue;

        setIngredient(updatedIngredient);
    };

    return (
        <div className="flex flex-col gap-[20px]">
            <h2>Edit Ingredient</h2>

            <div className="flex flex-col gap-[20px]">
                <TextInput
                    name="name"
                    value={ingredient.name}
                    placeholder="Ingredient Name"
                    className="form-group row"
                    extraInputClasses="form-control-lg"
                    required
                    onChange={(newVal) => updateIngredient("name", newVal)}
                />

                <TextInput
                    name="description"
                    displayLabel="Description"
                    value={ingredient.description}
                    placeholder="Ingredient Description"
                    className="form-group row"
                    type="textarea"
                    onChange={(newVal) =>
                        updateIngredient("description", newVal)
                    }
                />
            </div>

            <div className="flex gap-[10px]">
                <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                </button>
                <button
                    type="button"
                    className="btn btn-secondary ml-[10px]"
                    onClick={closeModal}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default EditIngredientModal;
