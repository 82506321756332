import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import api from "helpers/api.helpers";
import { useGetApi } from "common/hooks/useGetApi";
import createRecipeStore from "cooking/stores/CreateRecipeStore";

import { ComplexStep, Recipe, RecipeIngredient } from "cooking/models/types";
import { useApi } from "common/hooks/useApi";

export const useCreateRecipe = (loadRecipe: boolean = true) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { recipeId } = useParams();
    const isEdit = !!recipeId;

    const recipe = createRecipeStore.useTracked.recipe();
    const ingredientBeingLinked =
        createRecipeStore.useTracked.ingredientBeingLinked();
    const isIngredientLinkActive = !!ingredientBeingLinked;

    const updateRecipe = (key: string, newValue: any) => {
        createRecipeStore.set.updateRecipe(key, newValue);
    };

    const { data, isLoading, refetch } = useGetApi<Recipe>(
        `createEditComplexRecipe/${recipeId ?? ""}`,
        {
            skip: !loadRecipe,
        }
    );

    const { createItem: createRecipeCache, updateItem: updateRecipeCache } =
        useApi<Recipe>(`recipe/${recipeId}`, undefined, true);

    useEffect(() => {
        if (!isLoading && !recipe) createRecipeStore.set.recipe(data);
    }, [isLoading, data, recipe]);

    // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const handleSubmit = async (e: any) => {
        e.preventDefault(); // prevent page from reloading

        if (isEdit) {
            await api.put(`createEditComplexRecipe/${recipeId}`, recipe);

            // api.put(`createEditComplexRecipe/${recipeId}`, testRecipe);
            // .then(() => navigate("/cooking"))
            // .catch((err) => console.log(err));

            updateRecipeCache(recipe, true);

            // refetch so that we get correct ids
            refetch();

            // Navigate to view
            navigate(`/cooking/recipe/view/${recipeId}`);
        } else {
            api.post("createEditComplexRecipe/", recipe).then((res) => {
                // api.post("createEditComplexRecipe/", testRecipe).then((res) => {
                if (!res) return; // probably a 500
                if (res.status === 200) {
                    const {
                        data: { recipeId },
                    } = res;

                    // update value in cache
                    queryClient.setQueryData(
                        ["createEditComplexRecipe", recipeId],
                        recipe
                    );

                    // Navigate to view
                    navigate(`/cooking/recipe/view/${recipeId}`);
                } else {
                    console.log("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥");
                    console.error(`Non-200 status returned: ${res.status}`);
                    console.error(res);
                }
            });
        }
    };

    const clearIngredientLink = () => {
        createRecipeStore.set.ingredientBeingLinked(undefined);
    };

    const handleIngredientLinkClick = (ingredient: RecipeIngredient) => {
        if (ingredientBeingLinked) {
            clearIngredientLink();
        } else {
            createRecipeStore.set.ingredientBeingLinked(ingredient);
        }
    };

    const linkIngredientToStep = (step: ComplexStep) => {
        if (step.ingredients.find((i) => i.id === ingredientBeingLinked.id)) {
            console.log("Duplicate ingredient detected. Not adding it again.");
            // this step already has this ingredient. Don't add a duplicate
            clearIngredientLink();
            return;
        }

        step.ingredients = [...step.ingredients, ingredientBeingLinked];
        updateRecipe("steps", [...recipe.steps]);
        clearIngredientLink();
    };

    // Unused for now bc drag n drop is... not smooth at the moment
    /* const handleIngredientDrop = (dropResult: DropResult) => {
        console.log("dropResult:", dropResult);

        if (!dropResult.destination) return;

        const stepId = dropResult.destination.droppableId.split("_")[1];
        const step = recipe.steps.find((s) => s.id.toString() === stepId);

        const ingredientId = dropResult.draggableId.split("_")[1];
        // @ts-ignore
        if (step.ingredients.find((i) => i.id.toString() === ingredientId)) {
            console.log("Duplicate ingredient detected. Not adding it again.");
            // this step already has this ingredient. Don't add a duplicate
            return;
        }

        const ingredient = recipe.ingredients.find(
            (i) => i.id.toString() === ingredientId
        );

        // @ts-ignore
        step.ingredients = [...step.ingredients, ingredient];
        updateRecipe("steps", [...recipe.steps]);
    }; */

    return {
        recipe,
        updateRecipe,
        handleSubmit,

        ingredientBeingLinked,
        handleIngredientLinkClick,
        clearIngredientLink,
        linkIngredientToStep,
        isIngredientLinkActive,
    };
};

export default useCreateRecipe;
