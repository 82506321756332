import React, { useRef } from "react";

import TextInput from "foundation/components/forms/TextInput";
import SelectInput from "foundation/components/forms/SelectInput";
import ButtonGroupChoiceToggle from "foundation/components/forms/ButtonGroupChoiceToggle";
import EditTags from "foundation/components/forms/EditTags";

import { Recipe } from "cooking/models/types";
import {
    RecipeWrapper,
    WRAPPER_MODE,
} from "cooking/models/cooking.model.helpers";

type RecipeFieldsNewProps = {
    recipe: Recipe;

    /** Function to update recipe state */
    updateRecipe: (key: string, newValue: any) => void;
};

const RecipeFieldsNew: React.FC<RecipeFieldsNewProps> = ({
    recipe,
    updateRecipe,
}) => {
    const sourceElement = useRef<HTMLInputElement>(null);
    const recipeWrapper = new RecipeWrapper(recipe, WRAPPER_MODE.EDIT);

    const urlRegex =
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const sourceIsUrl = recipe.source?.match(urlRegex);

    const tagStrings = recipe.tags.map((tag) => tag.content);

    return (
        <section id="recipe_fields" className="flex flex-col gap-3">
            <TextInput
                name="description"
                type="textarea"
                placeholder="Tell us about it..."
                displayLabel="Description"
                value={recipe.description}
                onChange={(newDescription) =>
                    updateRecipe("description", newDescription)
                }
                autoComplete={false}
            />
            <EditTags
                tags={tagStrings}
                setTags={(newTags) =>
                    updateRecipe(
                        "tags",
                        newTags.map((tagString) => {
                            if (tagStrings.includes(tagString)) {
                                // If we already had this tag, preserve its data (i.e. its id)
                                const oldTag = recipe.tags.find(
                                    (tag) => tag.content === tagString
                                );
                                if (oldTag) {
                                    return oldTag;
                                }
                            }
                            return { content: tagString };
                        })
                    )
                }
            />
            {/* <ButtonGroupChoiceToggle
                name="status"
                selectedValue={recipe.status ?? ""}
                choices={recipe.STATUS_CHOICES}
                handleChange={(newStatus) => updateRecipe("status", newStatus)}
            /> */}
            <div id="recipe_detail_fields" className="row">
                <SelectInput
                    name="desire_to_make"
                    displayLabel="Desire to make"
                    selectedValue={recipe.desireToMake ?? ""}
                    choices={recipe.DESIRE_TO_MAKE_CHOICES}
                    onChange={(newVal) => updateRecipe("desireToMake", newVal)}
                    display={recipeWrapper.shouldDisplayDesireToMake}
                />

                <SelectInput
                    name="rating"
                    displayLabel="Rating"
                    selectedValue={recipe.rating ?? ""}
                    choices={recipe.RATING_CHOICES}
                    onChange={(newVal) => updateRecipe("rating", newVal)}
                    display={recipeWrapper.shouldDisplayRating}
                />

                <TextInput
                    name="servings"
                    displayLabel="Servings"
                    value={recipe.servings}
                    onChange={(newVal) => updateRecipe("servings", newVal)}
                    className="form-group col"
                    display={recipeWrapper.shouldDisplayServings}
                />

                <TextInput
                    name="time"
                    displayLabel="Time"
                    value={recipe.time ?? ""}
                    onChange={(newVal) => updateRecipe("time", newVal)}
                    maxLength={50}
                    className="form-group col"
                    display={recipeWrapper.shouldDisplayTime}
                />

                <SelectInput
                    name="difficulty"
                    displayLabel="Difficulty"
                    selectedValue={recipe.difficulty ?? ""}
                    choices={recipe.DIFFICULTY_CHOICES}
                    onChange={(newVal) => updateRecipe("difficulty", newVal)}
                    display={recipeWrapper.shouldDisplayDifficulty}
                />

                <TextInput
                    name="source"
                    displayLabel="Source"
                    value={recipe.source}
                    onChange={(newVal) => updateRecipe("source", newVal)}
                    maxLength={1000}
                    className="form-group col-4"
                    innerRef={sourceElement}
                    display={recipeWrapper.shouldDisplaySource}
                >
                    {/* {sourceIsUrl && (
                        <small className="text-theme-text-accent">
                            <button
                                id="importButton"
                                onClick={handleImportClick}
                            >
                                Import...
                            </button>
                        </small>
                    )} */}
                </TextInput>
            </div>
        </section>
    );
};

export default RecipeFieldsNew;
