import React, { useState } from "react";
import api from "helpers/api.helpers";
import { useGetApi } from "common/hooks/useGetApi";
import Autocomplete from "react-autocomplete";

import {
    getAveragePpg,
    getPointsForGame,
    sortStatsIntoBuckets,
} from "fantasyFootball/helpers/points.helpers";
import { getSnapsPlayedForSeason } from "fantasyFootball/helpers/stats.helpers";

import { Player, PlayerGameStats } from "fantasyFootball/models/types";

const PointsPerGame: React.FC = () => {
    const { data: players } = useGetApi<Player[]>("player");
    const [playerFilter, setPlayerFilter] = useState("");
    const [playerStats, setPlayerStats] =
        useState<PlayerGameStats[]>(undefined);

    // console.log("players:", players);

    const renderStatsForPlayer = (player: Player) => {
        setPlayerStats(undefined);
        api.get(`fantasy_football/player_stats/${player.id}`).then((res) =>
            setPlayerStats(res.data.gameStats)
        );
    };

    // console.log("playerStats:", playerStats);

    const currentPlayer = players?.find((p) => p.name === playerFilter);
    const statBuckets =
        playerStats && currentPlayer
            ? sortStatsIntoBuckets(playerStats, currentPlayer?.position)
            : undefined;

    return (
        <section>
            <h1>Points per game</h1>
            {players && (
                <Autocomplete
                    items={players}
                    getItemValue={(p: Player) => p.name}
                    renderItem={(p: Player, isHighlighted) => (
                        <div
                            style={isHighlighted ? { background: "gray" } : {}}
                        >
                            {p.name}
                        </div>
                    )}
                    shouldItemRender={(p: Player) =>
                        p.name
                            .toLowerCase()
                            .indexOf(playerFilter.toLowerCase()) > -1
                    }
                    value={playerFilter}
                    onChange={(e) => setPlayerFilter(e.target.value)}
                    autoHighlight
                    onSelect={(val) => {
                        setPlayerFilter(val);
                        renderStatsForPlayer(
                            players.find((p) => p.name === val)
                        );
                    }}
                />
            )}
            {playerStats && (
                <section>
                    <div>Avg PPG: {getAveragePpg(playerStats)}</div>
                    <div>Games missed: {17 - playerStats.length}</div>
                    <div>
                        Snaps played: {getSnapsPlayedForSeason(playerStats)}%
                    </div>
                    <br />
                    {statBuckets && (
                        <table>
                            <thead>
                                <tr>
                                    <th>Bucket</th>
                                    <th>#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(statBuckets).map(
                                    (bucket, index) => (
                                        <tr key={index}>
                                            <td>{bucket}</td>
                                            <td>{statBuckets[bucket]}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    )}
                    <br />
                    {playerStats.map((stats) => (
                        <div>Points for game: {getPointsForGame(stats)}</div>
                    ))}{" "}
                </section>
            )}
        </section>
    );
};

const SeveralPPG: React.FC = () => {
    return (
        <section className="flex gap-[20px]">
            <PointsPerGame />
            <PointsPerGame />
            <PointsPerGame />
        </section>
    );
};

// export default PointsPerGame;
export default SeveralPPG;
