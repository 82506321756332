import React, { useCallback, useState } from "react";
import _ from "lodash";
import useGetApi from "common/hooks/useGetApi";
import useGetApiPaginated from "common/hooks/useGetApiPaginated";

import RecipeRows from "cooking/components/RecipeRows";
import TextInput from "foundation/components/forms/TextInput";
import TagFilter from "foundation/components/TagFilter";

import { Recipe, Tag } from "cooking/models/types";

type SearchableRecipesProps = {
    recipesToExclude?: Recipe[];
    onRecipeClick?: (recipe: Recipe) => void;
    rowIcon?: React.ReactNode; // Override for the right icon. Defaults to RightArrow
    titleOverride?: string;
};

const SearchableRecipes: React.FC<SearchableRecipesProps> = ({
    recipesToExclude,
    onRecipeClick,
    rowIcon,
    titleOverride,
}) => {
    // const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>([]);
    const [filterText, setFilterText] = useState("");
    const [debouncedFilterText, setDebouncedFilterText] = useState("");

    // Tag filtering /api/recipes/?tag_type=ingredient&tag_content=chicken
    //   How to multiple tag filter?
    const [tagFilters, setTagFilters] = useState<Tag[]>([]);

    const {
        data: recipes,
        isLoading,
        infiniteScrollRef,
        isFetchingNextPage,
    } = useGetApiPaginated<Recipe>("recipe", {
        query: { search: debouncedFilterText },
    });

    const { data: tags } = useGetApi<Tag[]>("tag?type=recipe");

    const filteredRecipes = recipes;

    // useEffect(() => {
    //     setFilteredRecipes(
    //         recipes?.filter((recipe) => {
    //             const recipeTagStrings = recipe.tags.map((t) => t.content);
    //             return (
    //                 recipe.name
    //                     .toLowerCase()
    //                     .includes(filterText.toLowerCase()) &&
    //                 (tagFilters.length === 0 ||
    //                     tagFilters.every((t) =>
    //                         recipeTagStrings.includes(t.content)
    //                     )) &&
    //                 !recipesToExclude?.find(
    //                     (excluded) => excluded.id === recipe.id
    //                 )
    //             );
    //         })
    //     );
    // }, [recipes, filterText, tagFilters, recipesToExclude]);

    const setFilterTextDebounced = useCallback(
        _.debounce((x) => setDebouncedFilterText(x), 300),
        []
    );
    const filterRecipes = (filterString: string) => {
        setFilterText(filterString);
        setFilterTextDebounced(filterString);
    };

    return (
        <section className="flex flex-col gap-[12px]">
            <h2>{titleOverride ?? "All Recipes"}</h2>

            {isLoading && <div>Loading...</div>}
            {!isLoading && (
                <>
                    <TextInput
                        name="Filter"
                        placeholder="Filter..."
                        value={filterText}
                        onChange={filterRecipes}
                    />

                    <TagFilter
                        tags={tags}
                        handleTagFilter={setTagFilters}
                        className="flex mb-3"
                    />

                    {filteredRecipes?.length > 0 && (
                        <RecipeRows
                            recipes={filteredRecipes}
                            onClick={onRecipeClick}
                            rowIcon={rowIcon}
                            infiniteScrollRef={infiniteScrollRef}
                        />
                    )}
                    {isFetchingNextPage && <div>Loading more...</div>}
                    {filteredRecipes?.length === 0 && (
                        <div>No recipes match the filter.</div>
                    )}
                </>
            )}
        </section>
    );
};

export default SearchableRecipes;
