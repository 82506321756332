import { useState } from "react";
import useEventListener from "common/hooks/useEventListener";

// To use: create a <section id='wim'></section> around the area you wish to be Wim'd
//   warning: nothing preventing two useWim hooks that will collide and cause problems
const useWim = (keybinds: { [char: string]: () => void }) => {
    const [isWimMode, setIsWimMode] = useState(false);

    useEventListener("keydown", (event) => {
        if (event.key === "Escape") {
            setIsWimMode(!isWimMode);
        }

        if (!isWimMode) return;

        const func = keybinds[event.key];
        if (func) {
            // call the function then exit WIM mode
            func();
            setIsWimMode(false);
        }
    });

    return { isWimMode };
};

export default useWim;
