import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { useGetApi } from "common/hooks/useGetApi";
import { useApi } from "common/hooks/useApi";
import useConfirmation from "foundation/hooks/useConfirmation";

import Pencil from "foundation/svgComponents/Pencil";
import TrashIcon from "foundation/svgComponents/Trash";
import RecipeRow from "./RecipeRow";
import { Cookbook } from "cooking/models/types";

type ViewCookbookProps = {};

const ViewCookbook: React.FC<ViewCookbookProps> = () => {
    const navigate = useNavigate();
    const confirm = useConfirmation();
    const { currentUser } = useCurrentUser();

    const { cookbookId } = useParams();
    const { data: cookbook } = useGetApi<Cookbook>(`cookbooks/${cookbookId}`);
    const { deleteItem } = useApi<Cookbook>("cookbooks", undefined, true);
    const { deleteData: deleteFromMyCookbooksCache } = useGetApi<Cookbook>(
        "cookbooks/my_cookbooks",
        { skip: true }
    );

    const { name, description, recipes } = cookbook ?? {};

    const recipesExist = recipes?.length > 0;

    const userOwnsRecipe = currentUser?.username === cookbook?.owner;

    const handleDelete = async () => {
        if (await confirm("Are you sure you want to delete this cookbook?")) {
            deleteItem(cookbook, () => {
                deleteFromMyCookbooksCache(cookbook);
                navigate("/cooking");
            });
        }
    };

    return (
        <section className="w-full space-y-4">
            <div>
                <h1 className="text-shadow-sm flex gap-[20px] items-center">
                    {name}
                    {userOwnsRecipe && (
                        <div className="flex gap-[10px] items-center">
                            <button
                                onClick={() =>
                                    navigate(
                                        `/cooking/cookbook/edit/${cookbook?.id}`
                                    )
                                }
                                title="Edit"
                            >
                                <Pencil size="16px" />
                            </button>

                            <button onClick={handleDelete} title="Delete">
                                <TrashIcon size="16px" />
                            </button>
                        </div>
                    )}
                </h1>
                {description && <div>{description}</div>}
            </div>

            {recipesExist && (
                <ul className="pl-0">
                    {recipes.map((r) => (
                        <RecipeRow key={r.id} recipe={r} />
                    ))}

                    {!recipesExist && <div>No Recipes</div>}
                </ul>
            )}
        </section>
    );
};

export default ViewCookbook;
