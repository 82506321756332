// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-league-select select {
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 22px; }
`, "",{"version":3,"sources":["webpack://./src/fantasyFootball/styles/manageLeagues.scss"],"names":[],"mappings":"AAAA;EAEQ,uBAAkB;EAAlB,kBAAkB;EAClB,mBAAmB,EAAA","sourcesContent":[".manage-league-select {\n    select {\n        width: fit-content;\n        padding-right: 22px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
